import React from 'react';
import _, { create } from 'lodash';
import { connect } from 'react-redux';
import BlocklyJS from 'blockly/javascript';

import GameBuilder from './GameBuilder';
import * as actions from '../../store/actions/index';
import blocks from './blocks';
import RunButton from '../RunButton';
import Header from '../../components/UI/Header/Header';
import { Title } from '../../components/Title/Title';
import BlocklyContainer from '../BlocklyContainer';

class GameLabContainer extends React.Component {
  constructor(props) {
    super(props);

    this.projectId = this.props.match.params.projectId;
    this.mazeCanvas = React.createRef();
    this.BlocklyContainer = BlocklyContainer;
  }

  state = {
    name: null,
    data: this.data,
  };

  componentDidMount() {
    this.props.fetchProject(this.projectId);

    // var canvas = this.mazeCanvas.current;
    // var ctx = canvas.getContext('2d');

    // this.setState({ ctx: ctx, canvas: canvas });
  }

  componentDidUpdate(prevProps, prevState) {
    if (_.isEqual(prevProps.userProject, this.props.userProject) === false) {
      this.setState({
        name: this.props.userProject.name ? this.props.userProject.name : '...',
      });
    }
  }

  reset = () => {
    var gameBuilder = new GameBuilder(this.state.ctx);
    var intervalId = setInterval(gameBuilder.gameLoop, 10);
  };

  runButtonHandler = () => {
    var self = this;
    var code = BlocklyJS.workspaceToCode(
      this.BlocklyContainer.primaryWorkspace
    );

    var createCode, preloadCode, gVar, updateCode;
    var game = null;

    try {
      // var gameBuilder = new GameBuilder(this.state.ctx, this.state.canvas);
      // var intervalId = setInterval(gameBuilder.gameLoop, 10);
      // this.mazeCanvas.current.addEventListener('click', (e) =>
      //   gameBuilder.onCanvasClick(e)
      // );
      // alert(code);

      // eval(code);
      function randomIntFromInterval(min, max) {
        // min and max included
        return Math.floor(Math.random() * (max - min + 1) + min);
      }
      document.getElementById('gameCanvas').innerHTML = '';
      // eslint-disable-next-line no-undef

      createCode = '';
      preloadCode = '';
      gVar = '';
      updateCode = '';

      // alert(code);
      eval(code);
      eval(
        gVar +
          'var config = {type: window.Phaser.AUTO,width: 350,height: 550, physics: { default: "arcade"},parent: "gameCanvas",scene: { create: function() {' +
          createCode +
          '}, preload: function(){' +
          preloadCode +
          '}, update: function(){' +
          updateCode +
          '} }}; game = new window.Phaser.Game(config);'
      );
    } catch (e) {
      console.log(e);
    }
  };

  onCanvasHover = (e) => {
    var rect = this.mazeCanvas.current.getBoundingClientRect();
    var x = parseInt(e.clientX - rect.left);
    var y = parseInt(e.clientY - rect.top);

    var xCod = document.getElementById('xCod');
    var yCod = document.getElementById('yCod');

    xCod.innerText = x;
    yCod.innerText = y;
  };

  onCanvasClick = (e) => {
    let rect = this.state.canvas.getBoundingClientRect();
    let x = e.clientX - rect.left;
    let y = e.clientY - rect.top;

    return [x, y];
  };

  render() {
    return (
      <React.Fragment>
        <Title title={'ITK - '}></Title>
        <Header color='#F2F2F2' Backtitle='game' />

        <div className='container'>
          <div className='grid-maze'>
            <div className='preview'>
              <h2>{this.state.name !== null ? this.state.name : '...'}</h2>
              <br />
              {/* <canvas
                // onClick={(e) => this.onCanvasClick(e)}
                onMouseMove={(e) => this.onCanvasHover(e)}
                // onKeyDown={(e) => this.keyDownHandler(e)}
                ref={this.mazeCanvas}
                id='gameCanvas'
                width={360}
                height={550}
                style={{ border: '1px solid black' }}
              /> */}
              <div
                id='gameCanvas'
                style={{
                  border: '1px solid black',
                  width: '350px',
                  height: '550px',
                }}
              ></div>
              <p style={{ marginTop: '10px' }}>
                X: <span id='xCod'>0</span> &nbsp; Y: <span id='yCod'>0</span>
              </p>
              <div className='btnSection'>
                <div>
                  <RunButton
                    runClickHandler={this.runButtonHandler.bind(this)}
                  />
                  <button onClick={this.reset} className='btn'>
                    Reset
                  </button>
                </div>
              </div>
            </div>
            <BlocklyContainer
              initialXml={
                this.props.userProject ? this.props.userProject.xml : ''
              }
              blocks={blocks}
              projectId={this.props.match.params.projectId}
            />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchProject: (id) => dispatch(actions.fetchProject(id)),
    saveProjectXml: (id, xml, data) =>
      dispatch(actions.saveProjectXml(id, xml, data)),
  };
};

const mapStateToProps = (state) => {
  return {
    userProject: state.projects.userProject,
    xml: state.projects.xml,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GameLabContainer);
