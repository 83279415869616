var assets = {
    1: {
        "background": [['Background', 'space_story_background.png']],
        "assets": [['Astronaut_0', 'astrostory_0png.png'], ['Astronaut_90', 'astrostory_90.png'], ['Astronaut_180', 'astrostory_180.png'], ['Astronaut_270', 'astrostory_270.png'], ['Pink_Gem', 'pinkgem_story.png']]
    },
    2: {
        "background": [['Background', 'space_story_background2.png']],
        "assets": [['Astronaut_0', 'astrostory_0png.png'], ['Astronaut_90', 'astrostory_90.png'], ['Astronaut_180', 'astrostory_180.png'], ['Astronaut_270', 'astrostory_270.png'], ['Blue_Gem', 'bluegem_story.png'], ['Grey_Gem', 'graygem_story.png'], ["Treasure", "treasure_chest-01"]]
    },
    3: {
        "background": [['Background', 'space_story_background3.png']],
        "assets": [['Astronaut_0', 'astrostory_0png.png'], ['Astronaut_90', 'astrostory_90.png'], ['Astronaut_180', 'astrostory_180.png'], ['Astronaut_270', 'astrostory_270.png'], ['Purple_Gem', 'purplegem_story.png'], ['Asteroid', 'asteroid_story.png']]
    },
    4: {
        "background": [['Background', 'space_story_background4.png']],
        "assets": [['Astronaut_0', 'astrostory_0png.png'], ['Astronaut_90', 'astrostory_90.png'], ['Astronaut_180', 'astrostory_180.png'], ['Astronaut_270', 'astrostory_270.png'], ['Purple_Gem', 'purplegem_story.png'], ['Asteroid', 'asteroid_story.png'], ['Blue_Gem', 'bluegem_story.png'], ['Grey_Gem', 'graygem_story.png'], ['Pink_Gem', 'pinkgem_story.png'], ['Purple_Alien_0', 'purplealien_story_0.png'], ['Purple_Alien 90', 'purplealien_story_90.png'], ['Purple_Alien_180', 'purplealien_story_180.png'], ['Purple_Alien_270', 'purplealien_story_270.png'], ['Rocket', 'rocket_story.png']]
    }
}

export default assets;