import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../helpers/utility';

const initialState = {
    token: null,
    userId: null,
    error: null,
    loading: false,
    userDat: null,
};

const fetchUserInfoSuccess = (state, action) => {
    return updateObject(state, {
        error: null,
        loading: false,
        userData: action.userData
    })
}

const fetchUserInfoFailed = (state, action) => {
    return updateObject(state, { error: action.error, loading: false });
}

const authStart = (state, action) => {
    return updateObject(state, { error: null, loading: true })
}

const authSuccess = (state, action) => {
    return updateObject(state, {
        token: action.token,
        userId: action.userId,
        error: null,
        loading: false,
        success: null,
        userData: null
    })
}

const authFailed = (state, action) => {
    return updateObject(state, { error: action.error, loading: false });
}

const authLogout = (state, action) => {
    return updateObject(state, { token: null, userId: null });
}

const registerSuccess = (state, action) => {
    console.log(action.userId);
    return updateObject(state, {
        success: action.success,
        error: null,
        loading: false
    })
}

const passwordChangedSuccess = (state, action) => {
    return updateObject(state, {
        success: action.userData,
        error: null,
        loading: false
    })
}

const passwordResetLinkSuccess = (state, action) => {
    return updateObject(state, {
        userData: action.userData,
        error: null,
        loading: false
    })
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.AUTH_START: return authStart(state, action);
        case actionTypes.AUTH_SUCCESS: return authSuccess(state, action);
        case actionTypes.AUTH_FAILED: return authFailed(state, action);
        case actionTypes.AUTH_LOGOUT: return authLogout(state, action);
        case actionTypes.REGISTER_SUCCESS: return registerSuccess(state, action);
        case actionTypes.PASSWORD_CHANGE_SUCCESS: return passwordChangedSuccess(state, action);
        case actionTypes.RESET_PASSWORD_LINK_SUCCESS: return passwordResetLinkSuccess(state, action);
        case actionTypes.FETCH_USER_INFO_SUCCESS: return fetchUserInfoSuccess(state, action);
        case actionTypes.FETCH_USER_INFO_FAILED: return fetchUserInfoFailed(state, action);
        default: return state;
    }
}

export default reducer;