import * as actionTypes from './actionTypes';
import api from '../../apis/config';
import Cookie from 'js-cookie';

export const projectsFetchStart = () => {
    return {
        type: actionTypes.FETCH_PROJECTS_START
    }
}

export const projectsFetchSuccess = (projects) => {
    return {
        type: actionTypes.FETCH_PROJECTS_SUCCESS,
        projects
    }
}

export const projectsFetchFailed = (error) => {
    return {
        type: actionTypes.FETCH_PROJECTS_FAILED,
        error
    }
}


export const projectAddStart = () => {
    return {
        type: actionTypes.ADD_PROJECT_START
    }
}

export const projectAddSuccess = (project) => {
    return {
        type: actionTypes.ADD_PROJECT_SUCCESS,
        project: project
    }
}

export const projectAddError = (err) => {
    return {
        type: actionTypes.ADD_PROJECT_FAILED,
        error: err
    }
}


export const projectEditStart = () => {
    return {
        type: actionTypes.EDIT_PROJECT_START
    }
}

export const projectEditSuccess = (project) => {
    return {
        type: actionTypes.EDIT_PROJECT_SUCCESS,
        project: project
    }
}

export const projectEditError = (err) => {
    return {
        type: actionTypes.EDIT_PROJECT_ERROR,
        error: err
    }
}

export const projectDeleteStart = () => {
    return {
        type: actionTypes.DELETE_PROJECT_START
    }
}

export const projectDeleteSuccess = (id) => {
    return {
        type: actionTypes.DELETE_PROJECT_SUCCESS,
        id: id
    }
}

export const projectDeleteFailed = (err) => {
    return {
        type: actionTypes.DELETE_PROJECT_FAILED,
        error: err
    }
}

export const projectFetchStart = () => {
    return {
        type: actionTypes.PROJECT_FETCH_START
    }
}

export const projectFetchSuccess = (project) => {
    return {
        type: actionTypes.PROJECT_FETCH_SUCCESS,
        project: project
    }
}

export const projectFetchFailed = (error) => {
    return {
        type: actionTypes.PROJECT_FETCH_FAILED,
        error: error
    }
}

export const saveXmlStart = () => {
    return {
        type: actionTypes.PROJECT_XML_START
    }
}

export const saveXmlSuccess = (xml) => {
    return {
        type: actionTypes.PROJECT_XML_SUCCESS,
        xml
    }
}

export const saveXmlFailed = (error) => {
    return {
        type: actionTypes.PROJECT_XML_FAILED,
        error: error
    }
}

export const updateStatusSuccess = (status) => {
    return{
        type: actionTypes.UPDATE_STATUS_SUCCESS,
        status: status
    }
}

export const updateStatusFailed = (error) => {
    return {
        type: actionTypes.UPDATE_STATUS_FAILED,
        error: error
    }
}

export const updateStatus = (projectId, status) => {
    console.log(projectId);
    return dispatch => {
        api.put('/userProject/status/', 
        {
            status: status, 
            projectId: projectId  
        }).then(response => {
            console.log(response)
            if(response.status === 200){
                dispatch(updateStatusSuccess(status));
            }
        }).catch(err => {
            console.log(err);
            dispatch(updateStatusFailed(err))
        })
    }
}

export const fetchProjects = (lab) => {
    const userId = Cookie.get('userId');
    return dispatch => {
        dispatch(projectsFetchStart());
        //to do: add userid as a parameter
        api.get('/userProject/user/' + userId + '/' + lab)
            .then(response => {
                if (response.status === 200) {
                    //trim the data to get only the bits we want to use in the home page
                    //ie. name, image, id, labname and progress of the exercise
                    dispatch(projectsFetchSuccess(response.data.data));
                } else {
                    dispatch(projectsFetchFailed(response.data.error))
                }
            })
            .catch(err => {
                console.log(err);
                dispatch(projectsFetchFailed(err));
            });
    }
}

export const fetchAllProjects = (id) => {
    return dispatch => {
        dispatch(projectsFetchStart());
        //to do: add userid as a parameter
        api.get('/userProject/user/' + id)
            .then(response => {
                if (response.status === 200) {

                    //trim the data to get only the bits we want to use in the home page
                    //ie. name, image, id, labname and progress of the exercise
                    dispatch(projectsFetchSuccess(response.data.data));
                } else {
                    dispatch(projectsFetchFailed(response.data.error))
                }
            })
            .catch(err => {
                console.log(err);
                dispatch(projectsFetchFailed(err));
            });
    }
}

export const addProject = (name, lab) => {
    const userId = Cookie.get('userId');
    return dispatch => {
        dispatch(projectAddStart());

        api.post('/userProject', {
            userId: userId,
            name: name,
            lab: lab
        }).then(response => {
            console.log(response.data.data);
            dispatch(projectAddSuccess(response.data.data));
        }).catch(err => {
            console.log(err);
            dispatch(projectAddError(err));
        })
    }

}

export const editProject = (name, id) => {
    return dispatch => {
        dispatch(projectEditStart());
        api.post('/userProject/project/' + id, {
            name: name
        }).then(response => {
            console.log(response.data.data);
            dispatch(projectEditSuccess(response.data.data));
        }).catch(err => {
            console.log(err);
            dispatch(projectEditError(err));
        })
    }

}

export const deleteProject = (id) => {
    return dispatch => {
        dispatch(projectDeleteStart());

        api.delete('/userProject/' + id)
            .then(response => {
                if (response.status === 200) {
                    dispatch(projectDeleteSuccess(id));
                }
            })
            .catch(err => {
                dispatch(projectDeleteFailed(err));
            })
    }
}

export const fetchProject = (id) => {
    return dispatch => {
        dispatch(projectFetchStart());

        api.get('/userProject/' + id)
            .then(response => {
                if (response.status === 200) {
                    dispatch(projectFetchSuccess(response.data.data[0]));
                } else {
                    dispatch(projectsFetchFailed(response.data))
                }
                // console.log(response.data.data[0]);
            })
            .catch(error => {
                dispatch(projectFetchFailed(error));
                console.log(error);
            })
    }
}

export const saveProjectXml = (id, xml, data = null) => {
    return dispatch => {
        dispatch(saveXmlStart());

        api.put('/userProject/data/', { id: id, xml: xml, data: data })
            .then(response => {
                if (response.status === 200) {
                    dispatch(saveXmlSuccess(response.data.data.xml));

                } else {
                    dispatch(saveXmlFailed(response.data));
                }
            })
            .catch(error => {
                dispatch(saveXmlFailed(error));
            })
    }
}