import React from 'react';
import classes from './AddProjectPopup.module.css';
import closeIcon from '../../../assets/close.svg';

const AddProjectPopup = (props) => {
    return (
        <div className={classes.addProjectModal}>
            <label htmlFor="fname" style={{ color: '#06A199', fontWeight: 'bold' }}>Project Name</label>
            <input type="text" value={props.projectName} className={classes.input} onChange={(e) => props.projectNameChangeHandler(e)} />
            <img src={closeIcon} style={{ height: '25px' }} className={classes.closeIcon} onClick={props.Closed} />
            <button onClick={props.addProjectHandler} className={classes.btnAdd}>Add</button>
        </div>
    )
}

export default AddProjectPopup;