import React, { Fragment } from 'react';

import Header from '../../components/UI/Header/Header';
import styles from './Redirect404.module.css';
import image404 from '../../assets/404.png';
import { Link } from 'react-router-dom';


class Redirect404 extends React.Component{
    render(){
        return(
            <Fragment>
                 <Header color="#F2F2F2" />
                <div className = {styles.r_container}>
                    <div>
                        <img className = {styles.image404} src = {image404} />
                        <h2 className = {styles.title}>Page Not Found</h2>
                        <Link to = "/" className = {styles.returnButton} >Return to Dashboard</Link>
                    </div>
                </div>
            </Fragment>
           
        )
    }
}

export default Redirect404;