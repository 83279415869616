import React from 'react';

import ProjectCard from './ProjectCard/ProjectCard';

const ProjectList = (props) => {
    if (props.projects) {
        return props.projects.map(project => (
            <ProjectCard delete={props.delete} id={project._id} key={project._id} name={project.name} lab={project.lab} editPopUp={props.editPopUp} />
        ));
    } else {
        return "Network Error. Please Refresh the Page"
    }

}

export default ProjectList;