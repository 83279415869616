var levels = {

    1: {

        "blocks": {
            'World': ['set_background'],
            'Sprites': ['create_sprite_lab1', 'change_sprite_lab1', 'hide_sprite', 'show_sprite', 'sl_create_text'],
            'Sprite Movements': ['sprite_begins', 'sprite_stops', 'move_randomly_with_speed', 'move_left_right', 'move_up_down', 'move_down', 'grow_sprite', 'shrink_sprite', 'move_left', 'stops_every_movement'],
            'Events': ['when_run', 'when_clicked', 'when_button_pressed',
                'when_sprite_touches', 'when_sprite_touches_surface'],
            'Text': ['create_text_input', 'set_text', 'set_text_color', 'set_text_size', 'text_value'],
            'Buttons': ['create_button', 'set_button_colour', 'set_button_text', 'set_button_text_colour', 'set_button_text_size'],
            'Math': ['math_number', 'default_x', 'default_y', 'color_select']

        },
        "initial_background": "space_story_background.png",
        "initial_code": 'ctx.beginPath();ctx.rect(0, 350, 120, 120);ctx.rect(250, 300, 100, 100);ctx.stroke();ctx.fillStyle = "#000000";',
        "validations": ["error_background", "error_sprite_xy", "error_sprite_events", "error_sprite_dest", "error_sprite_speed"],
        "test_cases": {
            "x": 0,
            "y": 350,
            "sprite": "astrostory",
            "dest_x": 230,
            "dest_y": 300,
            "width": 100,
            "height": 100,
            "events": [{ "astrostory": "isTouching" }],
            "background": "space_story_background.png"
        },
    },
    2: {
        "blocks": {
            'World': ['set_background'],
            'Sprites': ['create_sprite_lab1', 'change_sprite_lab1', 'hide_sprite', 'show_sprite'],
            'Sprite Movements': ['sprite_begins', 'sprite_stops', 'move_randomly_with_speed', 'move_left_right', 'move_up_down', 'move_down', 'grow_sprite', 'shrink_sprite', 'move_left', 'stops_every_movement'],
            'Events': ['when_run', 'when_clicked', 'when_button_pressed',
                'when_sprite_touches', 'when_sprite_touches_surface'],
            'Text': ['create_text_input', 'set_text', 'set_text_color', 'set_text_size', 'text_value'],
            'Buttons': ['create_button', 'set_button_colour', 'set_button_text', 'set_button_text_colour', 'set_button_text_size'],
            'Math': ['math_number', 'default_x', 'default_y', 'color_select']
        },
        "initial_background": "space_story_background2.png",
        "initial_code": 'this.state.ctx.beginPath();this.state.ctx.rect(0, 350, 120, 120);this.state.ctx.rect(250, 200, 100, 100);this.state.ctx.rect(250, 400, 100, 100);this.state.ctx.stroke();this.state.ctx.fillStyle = "#000000";',
        "validations": ["error_background", "error_sprite_xy", "error_sprite_events", "error_sprite_dest", "error_sprite_speed"],
        "test_cases": {
            "x": 0,
            "y": 350,
            "sprite": "astrostory",
            "dest_x": 250,
            "dest_y": 400,
            "width": 100,
            "height": 100,
            "events": [{ "astrostory": "isTouching" }, { "graygem": "hide" }, { "bluegem": "hide" }],
            "background": "space_story_background2.png"
        },
    },
    3: {
        "blocks": {
            'World': ['set_background', 'create_text_input'],
            'Sprites': ['create_sprite_lab1', 'change_sprite_lab1', 'hide_sprite', 'show_sprite'],
            'Sprite Movements': ['sprite_begins', 'sprite_stops', 'move_randomly_with_speed', 'move_left_right', 'move_up_down', 'move_down', 'grow_sprite', 'shrink_sprite', 'move_left', 'stops_every_movement'],
            'Events': ['when_run', 'when_clicked', 'when_button_pressed',
                'when_sprite_touches', 'when_sprite_touches_surface'],
            'Text': ['create_text_input', 'set_text', 'set_text_color', 'set_text_size', 'text_value'],
            'Buttons': ['create_button', 'set_button_colour', 'set_button_text', 'set_button_text_colour', 'set_button_text_size'],
            'Math': ['math_number', 'default_x', 'default_y', 'color_select']
        },
        "initial_background": "space_story_background3.png",
        "initial_code": 'this.state.ctx.beginPath();this.state.ctx.rect(0, 350, 120, 120);this.state.ctx.rect(250, 400, 100, 100);this.state.ctx.stroke();this.state.ctx.fillStyle = "#000000";',
        "validations": ["error_background", "error_sprite_xy", "error_sprite_events", "error_sprite_dest", "error_sprite_speed"],
        "test_cases": {
            "x": 0,
            "y": 350,
            "sprite": "astrostory",
            "dest_x": 250,
            "dest_y": 400,
            "width": 100,
            "height": 100,
            "events": [{ "astrostory": "isTouching" }, { "gem": "hide" }, { "asteroid_story": "move_south_edge" }],
            "background": "space_story_background3.png"
        },
    },
    4: {
        "blocks": {
            'World': ['set_background'],
            'Sprites': ['create_sprite_lab1', 'change_sprite_lab1', 'hide_sprite', 'show_sprite'],
            'Sprite Movements': ['sprite_begins', 'sprite_stops', 'move_randomly_with_speed', 'move_left_right', 'move_up_down', 'move_down', 'move_up', 'grow_sprite', 'shrink_sprite', 'move_left', 'stops_every_movement'],
            'Events': ['when_run', 'when_clicked', 'when_button_pressed',
                'when_sprite_touches', 'when_sprite_touches_surface'],
            'Text': ['create_text_input', 'set_text', 'set_text_color', 'set_text_size', 'text_value'],
            'Buttons': ['create_button', 'set_button_colour', 'set_button_text', 'set_button_text_colour', 'set_button_text_size'],
            'Math': ['math_number', 'default_x', 'default_y', 'color_select']
        },
        "initial_background": "space_story_background4.png",
        "initial_code": 'this.state.ctx.beginPath();this.state.ctx.rect(0, 350, 120, 120);this.state.ctx.rect(250, 300, 100, 100);this.state.ctx.stroke();this.state.ctx.fillStyle = "#000000";',
        "validations": ["error_background", "error_sprite_xy", "error_sprite_events", "error_sprite_dest", "error_sprite_speed"],
        "test_cases": {
            "x": 0,
            "y": 350,
            "sprite": "astrostory",
            "dest_x": 230,
            "dest_y": 300,
            "width": 100,
            "height": 100,
            "events": [{ "astrostory": "isTouching" }, { "rocket_story": "move_north_edge" }],
            "background": "space_story_background4.png"
        },
    }

}

export default levels;