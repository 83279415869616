import React from 'react';
import './Button.css';

class RunButton extends React.Component {
    render() {
        return (
            <button className="btn" onClick={this.props.runClickHandler}>Run Code</button>
        );
    }
}

export default RunButton;