var assets = {
    1: {
        "path": "rocket.png",
        "type": "destination",
        "below": "dark_pink_tile.png"
    },
    2: {
        "angle": 90,
        "path": "astro_half_char",
        "type": "character",
        "below": "light_pink_tile.png"
    },
    3: {
        "path": "maze_obst_whitestar.png",
        "type": "obstacle"
    },
    4: {
        "path": "maze_obst_yellowstar.png",
        "type": "obstacle"
    },

    5: {
        "path": "purple_gem.png",
        "type": "obstacle"
    },
    6: {
        "path": "dark_pink_tile.png",
        "type": "path"
    },
    7: {
        "path": "light_pink_tile.png",
        "type": "path"
    },
    8: {
        "angle": 90,
        "path": "red_alien_char",
        "type": "character",
        "below": "dark_pink_tile.png"
    },
    9: {
        "path": "ufo.png",
        "type": "destination",
        "below": "light_pink_tile.png"
    },
    10: {
        "angle": 180,
        "path": "red_alien_char",
        "type": "character",
        "below": "light_pink_tile.png"
    },
    11: {
        "path": "rocket.png",
        "type": "destination",
        "below": "light_pink_tile.png"
    },
    12: {
        "angle": 90,
        "path": "astro_half_char",
        "type": "character",
        "below": "dark_pink_tile.png"
    },
    13: {
        "angle": 270,
        "path": "red_alien_char",
        "type": "character",
        "below": "dark_pink_tile.png"
    },
    14: {
        "path": "ufo.png",
        "type": "destination",
        "below": "dark_pink_tile.png"
    }
}

export default assets;