var assets = {
    1: {
        "angle": 90,
        "path": "monkey_char",
        "type": "character",
        "below": "dark_cream_tile.png"
    },
    2: {
        "path": "maze_obst_yellowflower.png",
        "type": "obstacle"
    },
    3: {
        "path": "apple_basket.png",
        "type": "score",
        "name": "apple"
    },
    4: {
        "path": "maze_func_apple.png",
        "type": "collect",
        "name": "apple",
        "below": "dark_cream_tile.png"
    },
    5: {
        "path": "maze_func_apple.png",
        "type": "collect",
        "name": "apple",
        "below": "light_cream_tile.png"
    },
    6: {
        "path": "light_cream_tile.png",
        "type": "path"
    },
    7: {
        "path": "dark_cream_tile.png",
        "type": "path"
    },
    8: {
        "path": "orange_basket.png",
        "type": "score",
        "name": "orange"
    },
    9: {
        "path": "maze_func_orange.png",
        "type": "collect",
        "name": "orange",
        "below": "dark_cream_tile.png"
    },
    10: {
        "path": "maze_func_orange.png",
        "type": "collect",
        "name": "orange",
        "below": "light_cream_tile.png"
    },
    11: {
        "angle": 180,
        "path": "monkey_char",
        "type": "character",
        "below": "dark_cream_tile.png"
    },
    12: {
        "angle": 0,
        "path": "monkey_char",
        "type": "character",
        "below": "dark_cream_tile.png"
    },
    13: {
        "angle": 270,
        "path": "monkey_char",
        "type": "character",
        "below": "dark_cream_tile.png"
    },
    14: {
        "angle": 270,
        "path": "monkey_char",
        "type": "character",
        "below": "light_cream_tile.png"
    },
    15: {
        "angle": 0,
        "path": "rabbit_char_0",
        "type": "character",
        "below": "light_cream_tile.png"
    },
    16: {
        "angle": 90,
        "path": "rabbit_char_90",
        "type": "character",
        "below": "light_cream_tile.png"
    },
    17: {
        "angle": 180,
        "path": "rabbit_char_270",
        "type": "character",
        "below": "light_cream_tile.png"
    },
    18: {
        "angle": 270,
        "path": "rabbit_char_180",
        "type": "character",
        "below": "light_cream_tile.png"
    },
    19: {
        "angle": 0,
        "path": "rabbit_char_0",
        "type": "character",
        "below": "dark_cream_tile.png"
    },
    20: {
        "angle": 90,
        "path": "rabbit_char_90",
        "type": "character",
        "below": "dark_cream_tile.png"
    },
    21: {
        "angle": 180,
        "path": "rabbit_char_270",
        "type": "character",
        "below": "dark_cream_tile.png"
    },
    22: {
        "angle": 270,
        "path": "rabbit_char_180",
        "type": "character",
        "below": "dark_cream_tile.png"
    },
    23: {
        "angle": 0,
        "path": "bee_0",
        "type": "character",
        "below": "light_cream_tile.png"
    },
    24: {
        "angle": 90,
        "path": "bee_90",
        "type": "character",
        "below": "light_cream_tile.png"
    },
    25: {
        "angle": 180,
        "path": "bee_270",
        "type": "character",
        "below": "light_cream_tile.png"
    },
    26: {
        "angle": 270,
        "path": "bee_180",
        "type": "character",
        "below": "light_cream_tile.png"
    },
    27: {
        "angle": 0,
        "path": "bee_0",
        "type": "character",
        "below": "dark_cream_tile.png"
    },
    28: {
        "angle": 90,
        "path": "bee_90",
        "type": "character",
        "below": "dark_cream_tile.png"
    },
    29: {
        "angle": 180,
        "path": "bee_270",
        "type": "character",
        "below": "dark_cream_tile.png"
    },
    30: {
        "angle": 270,
        "path": "bee_180",
        "type": "character",
        "below": "dark_cream_tile.png"
    },
    31: {
        "angle": 90,
        "path": "bee_90.png",
        "type": "condition_end",
        "connect": "honey",
        "below": "dark_cream_tile.png"
    },
    32: {
        "angle": 90,
        "path": "bee_90.png",
        "type": "condition_end",
        "connect": "honey",
        "below": "light_cream_tile.png"
    },
    33: {
        "angle": 90,
        "path": "rabbit_char_90.png",
        "type": "condition_end",
        "connect": "carrot",
        "below": "dark_cream_tile.png"
    },
    34: {
        "path": "rabbit_char_90.png",
        "type": "condition_end",
        "connect": "carrot",
        "below": "light_cream_tile.png"
    },
    35: {
        "path": "maze_cond_blue_question.png",
        "type": "condition",
        "below": "light_cream_tile.png",
        "random": [
            {
                "path": "honeycomb.png",
                "name": "honey"
            },
            {
                "name": "carrot",
                "path": "maze_cond_carrot.png"
            }
        ]
    },
    36: {
        "path": "maze_cond_blue_question.png",
        "type": "condition",
        "below": "dark_cream_tile.png",
        "random": [
            {
                "path": "honeycomb.png",
                "name": "honey"
            },
            {
                "name": "carrot",
                "path": "maze_cond_carrot.png"
            }
        ]
    },
    37: {
        "angle": 0,
        "path": "monkey_char",
        "type": "character",
        "below": "light_cream_tile.png"
        
    },
    38: {
        "angle": 90,
        "path": "monkey_char",
        "type": "character",
        "below": "light_cream_tile.png"
    },
    39: {
        "angle": 180,
        "path": "monkey_char",
        "type": "character",
        "below": "light_cream_tile.png"
    },
    40: {
        "angle": 90,
        "path": "rabbit_char_90.png",
        "type": "condition_end",
    },
    41: {
        "angle": 90,
        "path": "bee_90.png",
        "type": "condition_end",
    },
}

export default assets;