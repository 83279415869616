var blocks = {
  World: ['set_background', 'set_background_color'],
  Sprites: [
    'create_sprite',
    'set_sprite_properties',
    'get_sprite_properties',
    'hide_sprite',
  ],
  'Sprite Groups': ['create_group', 'add_sprite_to_group', 'hide_group'],
  Drawings: [
    'create_rectangle',
    'create_circle',
    'set_shape_propeties',
    'get_shape_property',
  ],
  'Sprite Movements': [
    'sprite_begins',
    'sprite_stops',
    'stops_every_movement',
    'sprite_group_begins',
    'group_stops',

    'bounce_from_walls',
    'group_bounce',
    'move_randomly',
    'move_left',
    'grow_sprite',
    'shrink_sprite',
  ],
  'Events and Timers': [
    'when_run_game',
    'when_clicked',
    'when_button_pressed',

    'when_sprite_touches',
    'when_sprite_touches_group',
    'when_group_touches_group',
    'when_timer_starts',
    'create_time_interval',
    'start_timer',
    'pause_timer',
  ],
  Text: [
    'text',
    'create_text_input',
    'set_text_property',
    'get_text_property',
    'get_text_as_number',
  ],
  Buttons: [
    'create_button',
    'set_button_colour',
    'set_button_text',
    'set_button_text_colour',
    'set_button_text_size',
    'button_visibility',
  ],
  Logic: ['if_state', 'logic_compare', 'logic_operation', 'logic_boolean'],
  Variables: [],
  Color: ['color_select', 'transparent'],
  Math: ['number', 'boolean', 'random_int', 'math_arithmetic'],
  Functions: [],
};

export default blocks;
