var level = {
    1: {
        "blocks": {
            "Creator": [
                'when_run', 
                'set_maze_row_input',
                'maze_input_type',
            ],
            "Elements": [
            'set_maze_background',
            'set_maze_character',
            'set_maze_obstacles',
            'set_maze_destination',
            'set_maze_path',
            'set_maze_collect',
            'set_maze_score',
            'set_maze_condition',
            'set_maze_condition_answers'
            ]
       
    }
}
}

export default level;