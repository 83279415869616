import React from 'react';
import classes from './ChangePasswordPopUp.module.css';
import closeIcon from '../../../assets/close.svg';


const ChangePasswordPopUp = (props) => {
    return (
        <div className={classes.containerPopUp}>
            <p style={{ fontSize: '12px', marginBottom: '5px', color: 'red' }}>{props.error_message}</p>
            <h3>CHANGE PASSWORD</h3>
            <label htmlFor="old password" className={classes.title}>OLD PASSWORD</label>
            <input type="password" name="oldPassword" value={props.oldPassword} className={classes.input} onChange={(e) => props.passwordHandler(e)} />
            <label htmlFor="old password" className="title">NEW PASSWORD</label>
            <input type="password" name="newPassword" value={props.newPassword} className={classes.input} onChange={(e) => props.passwordHandler(e)} />
            <label htmlFor="old password" className="title">CONFIRM NEW PASSWORD</label>
            <input type="password" name="confirmNewPassword" value={props.confirmNewPassword} className={classes.input} onChange={(e) => props.passwordHandler(e)} />
            <img src={closeIcon} style={{ height: '25px' }} className={classes.img} onClick={props.closePopUp} alt="close Icon" />
            <button className={classes.changePasswordBtn} onClick={props.changePasswordHandler}>Change Password</button>
        </div>
    )
}

export default ChangePasswordPopUp;