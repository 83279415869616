import React from "react";
import { connect } from "react-redux";
import classes from "./Register.module.css";
import Input from "../../../components/UI/Input/Input";
import Button from '../../../components/UI/Button/Button';
import registerForm from './registerData';
import AuthLayout from '../../../components/AuthLayout/AuthLayout';
import * as actions from '../../../store/actions/index';
import { Link } from 'react-router-dom';

class Register extends React.Component {


    constructor(props) {
        super(props);
        this.state = {
            registerForm: registerForm
        };
    }

    componentDidUpdate() {

    }

    inputChangedHandler = (event, inputIdentifier) => {
        const updatedFormData = {
            ...this.state.registerForm,
            [inputIdentifier]: {
                ...this.state.registerForm[inputIdentifier],
                value: event.target.value
            }
        };

        this.setState({
            registerForm: updatedFormData
        });
    };

    formSubmitHandler = (event) => {
        event.preventDefault();
        event.stopPropagation();
        this.setState({ loading: true });
        const formData = {};
        for (let formElementIdentifier in this.state.registerForm) {
            formData[formElementIdentifier] = this.state.registerForm[formElementIdentifier].value;
        }

        //send this formdata variable to axios API 
        this.props.onRegister(formData.firstname, formData.lastname, formData.email, formData.phone, formData.username, formData.password);
    }

    render() {
        // if (this.props.success) {
        //     this.props.history.push('/');
        // }

        const formElementsArray = [];
        for (let key in this.state.registerForm) {
            formElementsArray.push({
                id: key,
                config: this.state.registerForm[key],
            });
        }

        return (
            <AuthLayout height="400px">
                <h3 className={classes.title}>Sign Up</h3>
                <form onSubmit={this.formSubmitHandler}>
                    {formElementsArray.map((formElement) => {
                        return (
                            <Input
                                className={classes.input}
                                key={formElement.id}
                                text={formElement.config.text}
                                elementType={formElement.config.elementType}
                                elementConfig={formElement.config.elementConfig}
                                value={formElement.config.value}
                                changed={(event) => this.inputChangedHandler(event, formElement.id)}
                            />
                        );
                    })}
                    <div className={classes.buttonDiv}>
                        <Button>SIGN IN</Button>
                        <Link to="/" style={{ textDecoration: 'none', color: 'black' }}>
                            <p className={classes.spanSize}>Already member ? Login here.</p></Link>
                    </div>
                </form>
            </AuthLayout>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onRegister: (firstname, lastname, email, phone, username, password) => dispatch(actions.register(firstname, lastname, email, phone, username, password))
    }
}

const mapStateToProps = state => {
    return {
        success: state.auth.success !== null
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(Register);
