/* eslint no-eval: 0 */
/*eslint no-useless-concat: 0*/
/*eslint no-unused-vars: 0*/
/*eslint no-redeclare: 0*/
/*eslint array-callback-return: 0*/
import React, { Component } from 'react';
import Cookie from 'js-cookie';

import SideNav from '../../components/SideNavbar/SideNavbar';
import Header from '../../components/UI/Header/Header';
import classes from './Profile.module.css';
import avatar from '../../assets/background.png';
import { NavLink, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/index';
import editImage from '../../assets/blue_edit_icon.png';
import ChangePasswordPopup from '../../components/UI/ChangePasswordPopUp/ChangePasswordPopUp';
import Modal from '../../components/UI/Modal/Modal';

import axios from '../../apis/config';
import Spinner from '../../components/UI/Spinner/Spinner';
import ProgressBar from '../../components/UI/ProgressBar/ProgressBar';
import _ from 'lodash';




class Profile extends Component {
    sumOfBlocks = 0;
    state = {
        lab: "",
        openExercise: false,
        exerciseName: null,
        exerciseId: null,
        changePasswordPopup: false,
        oldPassword: '',
        newPassword: '',
        confirmNewPassword: '',
        error_message: '',
        blockCount: 0
    }


    passwordPopUpHandler = () => {
        this.setState({
            changePasswordPopup: !this.state.changePasswordPopup,
            oldPassword: '',
            newPassword: '',
            confirmNewPassword: ''
        })
    }


    componentDidMount() {
        const userId = Cookie.get('userId');
        this.props.fetchCompletedExercises(userId);
        this.props.fetchProjects(userId);
        this.props.fetchUserInfo(userId);

    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.match.params.lab !== prevState.lab) {
            const userId = Cookie.get('userId');
            nextProps.fetchCompletedExercises(userId);
            nextProps.fetchProjects(userId);
            nextProps.fetchUserInfo(userId);
            return ({ lab: nextProps.match.params.lab });
        }
        return null;
    }

    passwordHandler = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    changePasswordHandler = () => {
        if (this.state.newPassword !== this.state.confirmNewPassword) {
            this.setState({ error_message: 'Password do not match!' });
        } else {
            axios.post('/auth/change-password', { password: this.state.oldPassword, newpassword: this.state.newPassword, id: Cookie.get('userId') })
                .then(result => {
                    if (result.status === 200) {
                        this.setState({ error_message: "", oldPassword: '', newPassword: '', confirmNewPassword: '', changePasswordPopup: false })
                    } else {
                        this.setState({ error_message: result.data.data })
                    }
                }).catch(err => {
                    this.setState({ error_message: err })
                })
        }
    }

    openExercise = (name, id) => {
        this.setState({ openExercise: true, exerciseName: name, exerciseId: id });
        localStorage.setItem("lab", this.props.match.params.lab);
    }

    render() {

        if (this.props.exercises) {
            var sumOfBlocks = 0;

            this.props.exercises.map((exercise, index) => {
                exercise.userExercise.map(user => {
                    user.levels.map(level => {
                        var temp = level.xml;
                        var count = (temp.match(/block/g) || []).length;
                        sumOfBlocks += count;
                    });
                })
            })
            if (document.getElementById("block_count"))
                document.getElementById("block_count").textContent = parseInt(sumOfBlocks / 2);
        }

        if (this.state.openExercise) {
            return <Redirect to={{
                pathname: '/exercise/' + this.state.exerciseName.replace(/ /g, "") + '/' + this.state.exerciseId + '/1',
                state: { title: this.state.exerciseName, lab: this.props.match.params.lab }
            }} />
        }

        if (this.props.loading || this.props.projectLoading || this.userDataLoading) {
            return <Spinner />;
        }

        return (
            <div className={classes.container}>
                <div>
                    <Header color="#F2F2F2" img />
                    <SideNav />
                </div>

                {this.state.changePasswordPopup ? <Modal show={true}><ChangePasswordPopup error_message={this.state.error_message} confirmNewPassword={this.state.confirmNewPassword} newPassword={this.state.newPassword} oldPassword={this.state.oldPassword} changePasswordHandler={this.changePasswordHandler} passwordHandler={this.passwordHandler} closePopUp={this.passwordPopUpHandler} /></Modal> : null}

                <div className={classes.section}>
                    <div className={classes.overallProgress}>
                        <div className={classes.rectExercise}>
                            <span className={classes.numberText}>{this.props.exercises.filter(ex => ex.progress > 0).length}</span>
                            <span className={classes.titleText}>Exercises</span>
                        </div>
                        <div className={classes.rectExercise}>
                            <span className={classes.numberText} id="block_count">0</span>
                            <span className={classes.titleText}>Blocks</span>
                        </div>

                        <div className={classes.circleExercise}>
                            <ProgressBar
                                progress={this.props.exercises.filter(exercise => exercise.progress > 0).length / this.props.exercises.length * 100}
                                size={90}
                                strokeWidth={3}
                                circleOneStroke='#06A199'
                                circleTwoStroke='#FCB52F'
                            />
                        </div>
                    </div>

                    <div className={classes.progressSection}>
                        <div className={classes.exerciseSection}>
                            <div className={classes.headerDiv}>
                                <span className={classes.headerTitle}>Exercises Done</span>
                                <span className={classes.headerValue}>{this.props.exercises.filter(ex => ex.progress > 0).length}</span>
                            </div>
                            {this.props.exercises.map((exercise, index) => {
                                // if (exercise.progress > 0) {
                                if (index === 0 || index % 4 === 0) {
                                    return (
                                        <div style={{ marginTop: '30px' }} key={exercise.name}>
                                            <span className={classes.labTitle}>{exercise.lab} Lab</span>
                                            <div className={classes.exProgressDiv}>
                                                <span className={classes.exTitle} onClick={() => {
                                                    this.openExercise(exercise.name, exercise._id)
                                                }}>{exercise.name}</span>
                                                <div className={classes.exProgressBarDiv}>
                                                    <span className={classes.exProgressBar} style={{ width: exercise.progress + "%" }}></span>
                                                </div>
                                                <span className={classes.exValue}>{exercise.progress ? parseInt(exercise.progress) : 0}%</span>
                                            </div>
                                        </div>
                                    )
                                }
                                return (
                                    <div className={classes.exProgressDiv} key={exercise.name}>
                                        <span className={classes.exTitle}>{exercise.name}</span>
                                        <div className={classes.exProgressBarDiv}>
                                            <span className={classes.exProgressBar} style={{ width: exercise.progress + "%" }}></span>
                                        </div>
                                        <span className={classes.exValue}>{exercise.progress ? parseInt(exercise.progress) : 0}%</span>
                                    </div>
                                )
                                // }
                            })}

                        </div>


                        <div className={classes.projectSection}>
                            <div className={classes.headerDiv}>
                                <span className={classes.headerTitle}>Project Done</span>
                                <span className={classes.headerValue}>{this.props.projects.length}</span>
                            </div>



                            <span className={classes.labTitle}>Maze Lab</span>
                            <div className={classes.projectDiv}>
                                {this.props.projects.length === 0 ?
                                    <div className={classes.projectProgressDiv} key="No Project">
                                        <span className={classes.projectTitle}>No Projects</span>
                                    </div> :

                                    this.props.projects.map((project, index) => {
                                        if (project.lab === "maze") {
                                            if (index === 0) {
                                                return (
                                                    <div className={classes.projectProgressDiv} key={project.name}>
                                                        <span className={classes.projectTitle}>{project.name}</span>
                                                    </div>
                                                )
                                            }
                                            return (
                                                <div className={classes.projectProgressDiv} key={project.name}>
                                                    <span className={classes.projectTitle}>{project.name}</span>
                                                </div>

                                            )
                                        }
                                    })}
                            </div>


                            <span className={classes.labTitle}>Story Lab</span>
                            <div className={classes.projectDiv}>
                                {this.props.projects.length === 0 ?
                                    <div className={classes.projectProgressDiv} key="No Project">
                                        <span className={classes.projectTitle}>No Projects</span>
                                    </div> :
                                    this.props.projects.map((project, index) => {
                                        if (project.lab === "story") {
                                            if (index === 0) {
                                                return (
                                                    <div className={classes.projectProgressDiv}>
                                                        <span className={classes.projectTitle}>{project.name}</span>
                                                    </div>
                                                )
                                            }
                                            return (
                                                <div className={classes.projectProgressDiv} key={project.name}>
                                                    <span className={classes.projectTitle}>{project.name}</span>
                                                </div>
                                            )
                                        }
                                    })}
                            </div>

                            <span className={classes.labTitle}>Game Lab</span>
                            <div className={classes.projectDiv}>
                                {this.props.projects.length === 0 ?
                                    <div className={classes.projectProgressDiv} key="No Project">
                                        <span className={classes.projectTitle}>No Projects</span>
                                    </div> :
                                    this.props.projects.map((project, index) => {
                                        if (project.lab === "game") {
                                            if (index === 0) {
                                                return (
                                                    <div className={classes.projectProgressDiv}>
                                                        <span className={classes.projectTitle}>{project.name}</span>
                                                    </div>
                                                )
                                            }
                                            return (
                                                <div className={classes.projectProgressDiv} key={project.name}>
                                                    <span className={classes.projectTitle}>{project.name}</span>
                                                </div>
                                            )
                                        }
                                    })}
                            </div>

                        </div>
                    </div>

                </div>
                <div className={classes.profileDiv}>
                    {/* <div className={classes.avatar}>
                        <img className={classes.avatarImg} src={avatar} alt="avatar" />
                        <div className={classes.editDiv}>
                            <span>Edit</span>
                            <span><img className="" src={editImage} alt="avatar" /></span>
                        </div>
                    </div> */}
                    <h3 style={{ marginTop: '100px', textTransform: 'capitalize' }}>{this.props.userData && this.props.userData.data ? this.props.userData.data.firstname + ' ' + this.props.userData.data.lastname : ''}</h3>
                    {/* <h4 className={classes.classText}>Class C</h4> */}
                    {/* <div className={classes.manageProfile}>
                        <p>Manage Portfolio</p>
                    </div> */}
                    <h4 style={{ marginTop: '50px' }}>User Name: {this.props.userData && this.props.userData.data ? this.props.userData.data.username : ''}</h4>
                    <div className={classes.changePassword} onClick={this.passwordPopUpHandler.bind(this)}>
                        <p>Change Password</p>
                    </div>
                </div>
            </div >
        )
    }
}

const mapStateToProps = state => {
    return {
        exercises: state.exercises.exercises,
        loading: state.exercises.loading,
        error: state.exercises.error,

        projects: state.projects.projects,
        projectsLoading: state.projects.loading,
        projectsError: state.projects.error,

        userData: state.auth.userData,
        userDataLoading: state.auth.loading,
        userDataError: state.auth.error
    }
}

const mapDispatchToProps = dispatch => {
    return {
        fetchCompletedExercises: (userId) => dispatch(actions.fetchCompletedExercises(userId)),
        fetchProjects: (userId) => dispatch(actions.fetchAllProjects(userId)),
        fetchUserInfo: (userId) => dispatch(actions.fetchUserInfo(userId))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Profile);

