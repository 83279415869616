import {combineReducers} from 'redux';

import authReducer from './auth';
import exercisesReducer from './exercises';
import projectsReducer from './projects';
import userExerciseReducer from './userExercise';

export default combineReducers({
    auth: authReducer,
    exercises: exercisesReducer,
    projects: projectsReducer,
    userExercise: userExerciseReducer
});