/* eslint no-eval: 0 */
/*eslint no-useless-concat: 0*/
/*eslint no-unused-vars: 0*/
/*eslint no-redeclare: 0*/
/*eslint array-callback-return: 0*/
class Maze {
  constructor(ctx, matrix, assets, character_info, random, level) {
    this.character_info = character_info;
    this.ctx = ctx;
    this.matrix = matrix;
    this.assets = assets;
    this.random = random;
    this.images_folder = "../../../../images/";
    this.level = level;

    this.angle = character_info.angle;
    this.initial_x = character_info.x;
    this.initial_y = character_info.y;
    this.x = character_info.x;
    this.y = character_info.y;
    this.asset_number = character_info.asset_number;

    this.movements_list = [];
    this.debug_list = [];
    this.movement_x = 0;
    this.movement_y = 0;
    this.final_x = this.x;
    this.final_y = this.y;
    this.change_angle = this.angle;

    this.positions = [];

    this.run = false;

    this.random_numbers = {};
    this.random_indexes = {};

    this.collections = {};
    this.deposit = [];
    this.basket = {};
  }

  draw = () => {
    var cx = 0,
      cy = 0;
    for (var i = 0; i < this.matrix.length; i++) {
      for (var j = 0; j < this.matrix[0].length; j++) {
        if (this.assets[this.matrix[i][j]] !== undefined) {

          if (this.assets[this.matrix[i][j]].type === "obstacle") {
            var temp = new Image();
            temp.src = this.images_folder + this.assets[this.matrix[i][j]].path;
            this.ctx.drawImage(temp, j * 50 + 5, i * 50 + 5, 40, 40);
          }
          if (this.assets[this.matrix[i][j]].type === "path") {
            var path = new Image();
            path.src = this.images_folder + this.assets[this.matrix[i][j]].path;
            this.ctx.drawImage(path, j * 50, i * 50, 50, 50);
          }

          if (this.assets[this.matrix[i][j]].type === "destination") {
            var dest = new Image();
            dest.src = this.images_folder + this.assets[this.matrix[i][j]].path;
            var dest_below = new Image();
            dest_below.src = this.images_folder + this.assets[this.matrix[i][j]].below;
            this.ctx.drawImage(dest_below, j * 50, i * 50, 50, 50);
            this.ctx.drawImage(dest, j * 50, i * 50, 50, 50);
          }

          if (this.assets[this.matrix[i][j]].type === "character") {
            cx = i;
            cy = j;
          }

          if (this.assets[this.matrix[i][j]].type === "score") {
            var name = this.assets[this.matrix[i][j]].name;
            var score = new Image();
            score.src = this.images_folder + this.assets[this.matrix[i][j]].path;
            this.ctx.drawImage(score, j * 50 + 5, i * 50 + 5, 40, 40);
            var score_count = 0;
            if (this.basket[name]) {
              score_count = this.basket[name];
            }

            this.ctx.font = "20px Arial";
            this.ctx.textAlign = "start";
            this.ctx.fillText(score_count, j * 50 + 20, i * 50 + 42);
          }

          if (this.assets[this.matrix[i][j]].type === "collect") {
            var collect_name = this.assets[this.matrix[i][j]].name;
            var collect = new Image();
            collect.src = this.images_folder + this.assets[this.matrix[i][j]].path;
            var collect_below = new Image();
            if (this.assets[this.matrix[i][j]].below) {
              collect_below.src = this.images_folder + this.assets[this.matrix[i][j]].below;
            }

            this.ctx.drawImage(collect_below, j * 50, i * 50, 50, 50);

            if (this.checkCollectHide(i, j, collect_name) === true) {
              this.ctx.drawImage(collect, j * 50 + 5, i * 50 + 5, 40, 40);
            }
          }


          if (this.assets[this.matrix[i][j]].type === "condition") {
            var condition = new Image();
            var condition_below = new Image();
            condition.src =
              this.images_folder + this.assets[this.matrix[i][j]].path;

            if (this.assets[this.matrix[i][j]].below) {
              condition_below.src = this.images_folder + this.assets[this.matrix[i][j]].below;

              this.ctx.drawImage(condition_below, j * 50, i * 50, 50, 50)
            }



            if (this.run === true) {
              var random_object = this.assets[this.matrix[i][j]].random;

              var random_image = new Image();

              var collect_name = random_object[this.random_indexes[i + "" + j]].name;


              random_image.src =
                this.images_folder +
                random_object[this.random_indexes[i + "" + j]].path;
              if (this.checkCollectHide(i, j, collect_name) === true) {
                this.ctx.drawImage(random_image, j * 50 + 5, i * 50 + 5, 40, 40);
              }
            } else {
              this.ctx.drawImage(condition, j * 50 + 5, i * 50 + 5, 40, 40);
            }



          }

          if (this.assets[this.matrix[i][j]].type === "condition_end") {
            var condition_end = new Image();
            var condition_end_below = new Image();

            if (this.assets[this.matrix[i][j]].below) {
              condition_end_below.src =
                this.images_folder + this.assets[this.matrix[i][j]].below;
            }
            condition_end.src =
              this.images_folder + this.assets[this.matrix[i][j]].path;

            this.ctx.drawImage(condition_end_below, j * 50, i * 50, 50, 50);
            this.ctx.drawImage(condition_end, j * 50 + 5, i * 50 + 5, 40, 40);
          }
        }
      }
    }

    var c = new Image();
    c.src =
      this.images_folder +
      this.assets[this.asset_number].path +
      "_" +
      this.angle +
      ".png";

    var char_below = new Image();
    char_below.src =
      this.images_folder + this.assets[this.matrix[cx][cy]].below;

    this.ctx.drawImage(char_below, cy * 50, cx * 50, 50, 50);
    this.ctx.drawImage(c, this.x, this.y, 50, 50);
  };

  checkCollectHide(i, j, name) {
    let x = 0;

    if (this.collections[name]) {
      this.collections[name].forEach((collect) => {
        if (i === collect[0] && j === collect[1]) {
          x += 1;
        }
      });
    }

    if (x === 0) {
      return true;
    }

    return false;
  }

  check_if_collect = (name) => {

    var row = parseInt(this.final_y / 50);
    var column = parseInt(this.final_x / 50);

    var position_info = this.position_info(row, column);

    if (position_info.type === "collect" && position_info.name === name) {

      return true;
    } else {
      return false;
    }
  }

  check_question = (name) => {
    var row = parseInt(this.final_y / 50);
    var column = parseInt(this.final_x / 50);

    var position_string = row + "" + column;

    if (this.random_numbers[position_string]) {

      var conditional_end = this.random_numbers[position_string];
      var conditional_end_row = parseInt(conditional_end[0]);
      var conditional_end_column = parseInt(conditional_end[1]);

      var conditional_end_info = this.position_info(conditional_end_row, conditional_end_column);

      if (name === conditional_end_info.connect) {

        return true;
      }
    }

    return false;
  }

  add_to_basket = (number, type) => {

    this.movements_list.push({
      'type': 'add_to_basket',
      'number': number,
      'type_of_object': type
    })

  }

  collect = (name) => {
    this.movements_list.push({
      type: 'collect',
      name: name
    })

  };


  xs = (name) => {
    this.movements_list.push({
      type: 'deposit',
      name: name
    })
  };

  reset = () => {
    this.x = this.initial_x;
    this.y = this.initial_y;
    this.movements_list = [];
    this.final_x = this.initial_x;
    this.final_y = this.initial_y;
    this.movement_x = 0;
    this.movement_y = 0;
    this.positions = [];
    this.angle = this.character_info.angle;
    this.change_angle = this.angle;
    this.collections = [];
    this.basket = {};
    this.run = false;
  };

  update = () => {
    if (this.movements_list.length !== 0) {
      if (this.movements_list[0].type === 'add_to_basket') {
        var number = this.movements_list[0].number;
        var type = this.movements_list[0].type_of_object;

        if (this.basket[type]) {
          this.basket[type] += number;
        } else {
          this.basket[type] = number;
        }

        this.movements_list.shift();
      }
    }

    if (this.movements_list.length !== 0) {

      if (this.movements_list[0].type === 'collect') {
        var row = parseInt(this.y / 50);
        var column = parseInt(this.x / 50);
        var name = this.movements_list[0].name;

        var position_info = this.position_info(row, column);

        if ((position_info.type === "collect" || position_info.type === 'condition') && position_info.name === name) {

          var name = position_info.name;

          if (this.collections[name]) {
            this.collections[name].push([row, column]);
          } else {
            this.collections[name] = [[row, column]];
          }
        }

        this.movements_list.shift();
      }
    }

    if (this.movements_list.length !== 0) {
      if (this.movements_list[0].type === 'deposit') {
        var row = parseInt(this.y / 50);
        var column = parseInt(this.x / 50);
        var name = this.movements_list[0].name;

        var position_info = this.position_info(row, column);

        if (position_info.connect) {

          if (position_info.connect === name) {

            if (name in this.collections) {

              this.deposit.push({
                name: name,
                row: row,
                column: column
              })
            
            }

          }
        }
        this.movements_list.shift();
      }
    }


    if (this.movements_list.length !== 0) {

      if (this.movements_list[0].type === "turn_left") {
        if (this.angle - 90 < 0) {
          this.angle = 270;

        } else {
          this.angle = this.angle - 90;

        }

        this.movements_list.shift();
      }
    }


    if (this.movements_list.length !== 0) {
      if (this.movements_list[0].type === "turn_right") {
        if (this.angle + 90 > 270) {
          this.angle = 0;
        } else {
          this.angle = this.angle + 90;
        }

        this.movements_list.shift();
      }
    }
    if (this.movements_list.length !== 0) {
      if (this.movements_list[0].type === "move") {
        var fx = this.movements_list[0].final_x;
        var fy = this.movements_list[0].final_y;
        var angle = this.movements_list[0].angle;

        //up
        if (angle === 0 || angle === 360) {
          if (this.y - this.movement_y < fy || this.y === fy) {
            this.y = fy;
          } else {
            this.movement_y = this.movement_y + 0.1;
            this.y = this.y - this.movement_y;
          }
        }

        if (angle === 90) {
          if (this.x + this.movement_x > fx || this.x === fx) {
            this.x = fx;
          } else {
            this.movement_x = this.movement_x + 0.1;
            this.x = this.x + this.movement_x;
          }
        }

        //down
        if (angle === 180) {
          if (this.y + this.movement_y > fy || this.y === fy) {
            this.y = fy;
          } else {
            this.movement_y = this.movement_y + 0.1;
            this.y = this.y + this.movement_y;
          }
        }

        if (angle === 270) {
          if (this.x - this.movement_x < fx || this.x === fx) {
            this.x = fx;
          } else {
            this.movement_x = this.movement_x + 0.1;
            this.x = this.x - this.movement_x;
          }
        }

        if (this.x === fx && this.y === fy) {
          this.movements_list.shift();
          this.movement_x = 0;
          this.movement_y = 0;
        }
      }
    }
  };

  move = () => {

    if (this.change_angle === 90) {
      this.final_x = this.final_x + 50;
    } else if (this.change_angle === 270) {
      this.final_x = this.final_x - 50;
    } else if (this.change_angle === 180) {
      this.final_y = this.final_y + 50;
    } else if (this.change_angle === 0 || this.change_angle === 360) {
      this.final_y = this.final_y - 50;

    }

    this.movements_list.push({
      type: "move",
      angle: this.change_angle,

      final_x: this.final_x,
      final_y: this.final_y,
    });

    this.debug_list.push({
      type: "move",
      angle: this.change_angle,

      final_x: this.final_x,
      final_y: this.final_y,
    })

    this.positions.push({
      row: parseInt(this.final_y / 50),
      column: parseInt(this.final_x / 50),
    });
  };

  turn_left = () => {
    if (this.change_angle - 90 < 0) {
      this.change_angle = 270;
    } else {
      this.change_angle = this.change_angle - 90;
    }

    this.movements_list.push({
      type: "turn_left",
      angle: this.change_angle,
    });
  };

  turn_right = () => {

    if (this.change_angle + 90 >= 360) {
      this.change_angle = 0;
    } else {
      this.change_angle = this.change_angle + 90;
    }

    this.movements_list.push({
      type: "turn_right",
      angle: this.change_angle,
    });
  };

  objects_count_check = () => {
    var basket_initial = {};
    var error = true;

    for (var i = 0; i < this.matrix.length; i++) {
      for (var j = 0; j < this.matrix[0].length; j++) {
        if (this.assets[this.matrix[i][j]] !== undefined) {
          if (this.assets[this.matrix[i][j]].type === 'collect') {
            var current_element = this.assets[this.matrix[i][j]].name;

            if (basket_initial[current_element]) {
              basket_initial[current_element] += 1;
            } else {
              basket_initial[current_element] = 1;
            }

          }
        }
      }
    }
    Object.keys(this.basket).map(b => {
      console.log(this.basket[b], basket_initial[b]);
      if (basket_initial[b]) {
        if (this.basket[b] === basket_initial[b]) {
          error = false;

        }
      }
    })

    if (error === false) {
      return true;
    }
    return false;
  }

  /*
Get which [row, column] the character is in
what exists in that particular position(row, column)
*/

  position_info = (row, column) => {
    var info = {};

    info.row = row;
    info.column = column;

    if (this.matrix[info.row]) {
      if (this.assets[this.matrix[info.row][info.column]]) {
        info.type = this.assets[this.matrix[info.row][info.column]].type;

        if (this.assets[this.matrix[info.row][info.column]].name) {
          info.name = this.assets[this.matrix[info.row][info.column]].name;
        }

        if (info.type === 'condition') {
          var random = this.assets[this.matrix[info.row][info.column]].random;
          info.name = random[this.random_indexes[row + "" + column]].name;
        }

        if (this.assets[this.matrix[info.row][info.column]].connect) {
          info.connect = this.assets[this.matrix[info.row][info.column]].connect;
        }
      } else {
        info.type = "space";
        info.name = "blank space";
      }
    } else {
      info.type = 'space';
      info.name = 'blank space';
    }


    return info;
  };

  check_free_path_conditionals = () => {

  }

  /*

    check the current path that the character reached till the submit button was clicked
    Conditions:
    1. Did the character reach the final destination?
    2. Did the character go through any obstacles on its way?
    3. Did it collect all that it was supposed to collect?
    4. Did it pass through all the "question marks" or "conditionals"
  
  */

  submit = () => {
    var errors = [];

    //getting the current column and row values for the character
    var row = parseInt(this.y / 50);
    var column = parseInt(this.x / 50);

    var current_position_info = this.position_info(row, column);

    if (this.level.validations) {
      if (this.level.validations.length > 0) {
        this.level.validations.forEach((validation) => {
          if (validation.type === 'free_path_conditionals') {
            this.positions.forEach((element, index) => {
              var type = this.position_info(element.row, element.column).type;
              if (type !== "path" && type !== "destination" && type !== 'collect' && type !== 'condition' && type !== 'condition_end') {
                errors.push({
                  type: true,
                  message: "Your character is going in the wrong direction. Please try again using correct turn left or right blocks.",
                });
              }
            });
          }

          if (validation.type === 'deposit_conditional') {

            if (this.deposit.length !== this.count_conditionals()) {
              errors.push({
                type: true,
                message: "You have not deposited all the objects."
              })
            }

            var random_cond_count = Object.keys(this.random).length;
            var collect_count = 0;
            Object.keys(this.collections).forEach((e) => {
              collect_count += this.collections[e].length;
            })

            if (random_cond_count !== collect_count) {
              errors.push({
                type: true,
                message: "You have not collected all the objects."
              })
            }



          }
          if (validation.type === 'collect_check') {
            if (this.objects_count_check() === false) {
              errors.push({
                type: true,
                message: 'You may have missed using the appropriate "collect blocks" to collect apples and oranges'
              })
            }
          }

          if (validation.type === "destination_reached") {
            if (current_position_info.type !== "destination") {
              errors.push({
                type: true,
                message: "The character didnt reach the destination",
              });
            }
          }

          if (validation.type === "only_path_exists") {
            this.positions.forEach((element, index) => {
              var type = this.position_info(element.row, element.column).type;

              if (type !== "path" && type !== "destination" && type !== 'collect' && type !== 'condition' && type !== 'condition_end') {
                errors.push({
                  type: true,
                  message: "Your character is going in the wrong direction. Please try again using correct turn left or right blocks.",
                });
              }
            });
          }

          if (validation.type === "condtional_check") {
            var err = 0;
            var path_conditionals = 0;
            this.positions.forEach((element, index) => {
              var type = this.position_info(element.row, element.column).type;

              if (type === 'condition') {
                path_conditionals += 1;
                var cnt = 0;
                this.positions.forEach((element2, index) => {
                  if (element2.row + '' + element2.column === this.random_numbers[element.row + '' + element.column]) {
                    cnt++

                  }
                });
                if (cnt === 0) {

                  err++;
                }

              }

            });

            console.log(this.count_conditionals(), path_conditionals);
            if (err > 0 || path_conditionals < this.count_conditionals()) {
              errors.push({
                type: "error",
                message: " All paths must be coded or the check question mark condition hasn’t been fulfilled.",
              });
            }
          }
         
        });
      }
    }
    return errors;
  };

  random_number = (min, max) => {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
  };

  /*
   1. get all the types called conditionals
 */
  generate_random_number = () => {
    if (this.level.random) {
      Object.entries(this.level.random).map((point) => {
        var random_object_count = Object.keys(point[1]).length;
        var rand = this.random_number(0, random_object_count - 1);
        this.random_numbers[point[0]] =
          point[1][rand];

        this.random_indexes[point[0]] = rand;

        return false;
      });
    }

  };

  count_conditionals = () => {
    return Object.keys(this.level.random).length;
  };

}

export default Maze;
