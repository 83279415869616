let Components = {};
let Assets = {};

//======================================================================================================//
//MAZELAB COMPONENTS
//=====================================================================================================//


Components['FunwithBasics'] = require('../Apps/Maze/FunwithBasics/levels');
Components['LoopyLoops'] = require('../Apps/Maze/LoopyLoops/levels');
Components['Conditionalcrops'] = require('../Apps/Maze/Conditionalcrops/levels');
Components['BackyardFunctions'] = require('../Apps/Maze/BackyardFunctions/levels');


Assets['FunwithBasics'] = require('../Apps/Maze/FunwithBasics/assets');
Assets['LoopyLoops'] = require('../Apps/Maze/LoopyLoops/assets');
Assets['Conditionalcrops'] = require('../Apps/Maze/Conditionalcrops/assets');
Assets['BackyardFunctions'] = require('../Apps/Maze/BackyardFunctions/assets');

//======================================================================================================//
//STORYLAB COMPONENTS
//=====================================================================================================//
Components['SpaceAdventure'] = require('../Apps/Story/SpaceAdventure/levels');
Components['TheSadUnicorn'] = require('../Apps/Story/TheSadUnicorn/levels');
Components['GeniusAtWork'] = require('../Apps/Story/GeniusAtWork/levels');
Components['ReadyForSchool'] = require('../Apps/Story/ReadyForSchool/levels');


Assets['SpaceAdventure'] = require('../Apps/Story/SpaceAdventure/assets');
Assets['TheSadUnicorn'] = require('../Apps/Story/TheSadUnicorn/assets');
Assets['GeniusAtWork'] = require('../Apps/Story/GeniusAtWork/assets');
Assets['ReadyForSchool'] = require('../Apps/Story/ReadyForSchool/assets');


export { Components, Assets };