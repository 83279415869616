import Sprite from './Sprite';

class Group extends Sprite {
  constructor(ctx, name) {
    super();
    this.ctx = ctx;
    this.name = name;
    this.g_sprites = {};
    this.visible = true;
    this.movements = [];
  }

  draw = () => {
    Object.keys(this.g_sprites).forEach((s) => {
      this.g_sprites[s].movements = this.movements;
      this.g_sprites[s].draw();
      this.g_sprites[s].update();
    });
  };

  group_touches_edge = (edge, func) => {
    Object.keys(this.g_sprites).forEach((s) => {
      this.g_sprites[s].movements.push({
        type: 'sprite_touch_edge',
        name: s.name,
        edge: edge,
        func: func,
      });
    });
  };

  addToGroup = (src, x, y) => {
    var sprite_name = this.name + '_' + this.g_sprites.length;
    // this.g_sprites.push(sprite_name);
    this.g_sprites[sprite_name] = new Sprite(
      this.ctx,
      sprite_name,
      src,
      x,
      y,
      this.name
    );
  };

  changeVisibility = (visible) => {
    this.visible = visible;
  };
}

export default Group;
