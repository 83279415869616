import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import Cookie from 'js-cookie';

export const ProtectedRoute = ({ component: Component, ...rest }) => {
    return (
        <Route
            {...rest}
            render={(props) => {


                if (!!Cookie.get('userId')) {
                    return (
                        <Component {...props} />
                    );
                } else {
                    window.location.replace(process.env.REACT_APP_AUTH_BASE + '?redirectURL=' + window.location.href)
                    return null
                }


            }}
        />
    )
}