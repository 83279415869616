import React from 'react';

import classes from './ConfirmationBox.module.css';

const ComfirmationBox = (props) => {
    console.log(props);

    return (
        <div className={classes.confirmationBox}>
            <h5>{props.text}</h5>
            <button className={classes.btn} onClick={() => props.confirm()}>Yes</button>
            <button className={classes.btn} onClick={props.deny}>No</button>
        </div>
    )
}

export default ComfirmationBox;