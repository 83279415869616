import React from 'react';
import classes from './Header.module.css';
import logo from '../../../assets/logo.png';

const header = (props) => {
  return (
    <header
      className={classes.ToolBar}
      style={{ backgroundColor: props.color }}
    >
      <div className={classes.header}>
        {props.img ? null : (
          <a href='/'>
            <img
              src={logo}
              style={{ width: '80px', height: '30px' }}
              alt='Logo'
            />
          </a>
        )}
      </div>
      {props.Backtitle ? (
        <div className={classes.arrowLeft}>
          <a href={'/#/' + props.Backtitle + ''}>
            {' '}
            <p
              style={{
                marginRight: '30px',
                fontSize: '14px',
                width: '100px',
                maxWidth: '120px',
              }}
            >
              {props.Backtitle.toUpperCase()} LAB
            </p>
          </a>
        </div>
      ) : null}
    </header>
  );
};

export default header;
