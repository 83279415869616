const registerData = {
    firstname: {
        elementType: "text",
        text: "Firstname",
        elementConfig: {
            type: "text",
            placeholder: "Your Firstname",
        },
        value: "",
        validation: {
            required: true
        }
    },
    lastname: {
        elementType: "text",
        text: "Lastname",
        elementConfig: {
            type: "text",
            placeholder: "Your Lastname",
        },
        value: "",
        validation: {
            required: true
        }
    },
    email: {
        elementType: "text",
        text: "Email",
        elementConfig: {
            type: "email",
            placeholder: "Your Email",
        },
        value: "",
        validation: {
            required: true
        }
    },
    phone: {
        elementType: "text",
        text: "Phone",
        elementConfig: {
            type: "text",
            placeholder: "Your Phone",
        },
        value: "",
        validation: {
            required: true
        }
    },
    username: {
        elementType: "text",
        text: "Username",
        elementConfig: {
            type: "text",
            placeholder: "Your Username",
        },
        value: "",
        validation: {
            required: true
        }
    },
    password: {
        elementType: "password",
        text: "Password",
        elementConfig: {
            type: "password",
            placeholder: "Your Password",
        },
        value: "",
        validation: {
            required: true
        }
    },
};

export default registerData;
