class Text {

    constructor(ctx, text, name, x, y) {
        this.ctx = ctx;
        this.text = text;
        this.size = 30;
        this.name = name;
        this.x = x;
        this.y = y;
        this.font = "Arial";
        this.color = 'black';
    }

    draw() {
        this.ctx.fillStyle = this.color;
        this.ctx.textAlign = "center";
        this.ctx.font = this.size + "px " + this.font;
        this.ctx.fillText(this.text, this.x, this.y);
    }

    getText = () => {
        return this.text;
    }

    setText = (newText) => {
        this.text = newText;
    }

    setColor = (newColor) => {
        this.color = newColor;
    }

}

export default Text;