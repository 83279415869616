import React from 'react';
import logo from '../../assets/logo.png';
import classes from './SideNavbar.module.css';
import { NavLink } from 'react-router-dom';
import mazeIcon from '../../assets/mazeLabBlue.svg';
import artIcon from '../../assets/appLabBlue.svg';
import storyIcon from '../../assets/storyLabBlue.svg';
import gameIcon from '../../assets/gameLabBlue.svg';
import profileIcon from '../../assets/profileBlue.svg';

const SideNavBar = (props) => {
  const changeWebsite = () => {
    window.location.replace('https://applab.itkkids.xyz');
  };

  return (
    <div className={classes.sidenav}>
      <div className={classes.logoDiv}>
        <img style={{ height: '30px', with: '80px' }} src={logo} alt='logo' />
      </div>

      <div className={classes.profileDiv}>
        <img src={profileIcon} className={classes.icon} alt='logo' />
        <NavLink to='/profile' activeClassName={classes.active}>
          Profile
        </NavLink>
      </div>

      <div className={classes.eachLinkDiv}>
        <img src={mazeIcon} className={classes.icon} alt='logo' />
        <NavLink to='/maze' exact activeStyle={{ color: '#06A199' }}>
          Maze Lab
        </NavLink>
      </div>

      <div className={classes.eachLinkDiv}>
        <img src={storyIcon} className={classes.icon} alt='logo' />
        <NavLink to='/story' exact activeStyle={{ color: '#06A199' }}>
          Story Lab
        </NavLink>
      </div>

      <div className={classes.eachLinkDiv}>
        <img src={gameIcon} className={classes.icon} alt='logo' />
        <NavLink to='/game' exact activeStyle={{ color: '#06A199' }}>
          Game Lab
        </NavLink>
      </div>

      <div className={classes.eachLinkDiv}>
        <img src={artIcon} className={classes.icon} alt='logo' />
        <a href='https://applab.itkkids.xyz' onClick={changeWebsite}>
          App Lab
        </a>
      </div>

      <div className={classes.eachLinkDiv}>
        <img src={profileIcon} className={classes.icon} alt='logo' />
        <NavLink
          to='/'
          exact
          activeStyle={{ color: '#06A199' }}
          onClick={props.logout}
        >
          Logout
        </NavLink>
      </div>
    </div>
  );
};

export default SideNavBar;
