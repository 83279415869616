import React from "react";
import classes from './Button.module.css'

const button = (props) => {
    console.log(props);
    return (
        <button style={{ backgroundColor: props.background }} className={classes.button} > {props.children}</button>
    )
}

export default button;