var assets = {
    1: {
        "angle": 90,
        "path": "rabbit_char",
        "type": "character"
    },
    2: {
        "path": "maze_obst_stone1.png",
        "type": "obstacle"
    },
    3: {
        "path": "maze_obst_stone4.png",
        "type": "obstacle"
    },
    4: {
        "path": "maze_obst_stone3.png",
        "type": "obstacle"
    },
    5: {
        "path": "maze_obst_stone2.png",
        "type": "obstacle"
    },
    6: {
        "path": "maze_cond_blue_question.png",
        "type": "condition",
        "random": [
            {
                "path": "maze_obst_yellowflower.png"
            },
            {
                "path": "maze_cond_carrot.png"
            }
        ]
    },
    7: {
        "path": "maze_obst_yellowflower.png",
        "type": "condition_end",
        "connect": "yellow_flower"
    },
    8: {
        "path": "maze_cond_carrot.png",
        "type": "condition_end",
        "connect": "carrot"
    },
    9: {
        "path": "maze_cond_pink_flower.png",
        "type": "condition_end",
        "connect": "pink_flower"
    },
    10: {
        "path": "maze_cond_blue_question.png",
        "type": "condition",
        "random": [
            {
                "path": "maze_obst_yellowflower.png"
            },
            {
                "path": "maze_cond_carrot.png"
            },
            {
                "path": "maze_cond_pink_flower.png"
            }
        ]
    },
    11: {
        "path": "maze_cond_blue_question.png",
        "type": "condition",
        "random": [
            {
                "path": "maze_obst_yellowflower.png"
            },
            {
                "path": "maze_cond_carrot.png"
            },
            {
                "path": "maze_cond_pink_flower.png"
            },
            {
                "path": "maze_cond_beetroot.png"
            }
        ]
    },
    12: {
        "path": "maze_cond_beetroot.png",
        "type": "condition_end",
        "connect": "beetroot"
    },
    13: {
        "path": "maze_cond_half_question.png",
        "type": "condition",
        "connect": "orange_question",
        "random": [
            {
                "path": "maze_cond_orange_question.png"
            },
            {
                "path": "maze_cond_yellow_question.png"
            }
        ]
    },
    14: {
        "path": "maze_cond_orange_question.png",
        "type": "condition",
        "connect": "orange_question",
        "random": [
            {
                "path": "maze_cond_carrot.png"
            },
            {
                "path": "maze_cond_beetroot.png"
            }
        ]
    },
    15: {
        "path": "maze_cond_yellow_question.png",
        "type": "condition",
        "connect": "yellow_question",
        "random": [
            {
                "path": "maze_obst_yellowflower.png"
            },
            {
                "path": "maze_cond_pink_flower.png"
            }
        ]
    },
    16: {
        "path": "maze_cond_blue_question.png",
        "type": "condition",
        "random": [
            {
                "path": "maze_cond_carrot.png"
            },
            {
                "path": "maze_cond_beetroot.png"
            }
        ]
    },
    17: {
        "path": "maze_cond_yellow_question.png",
        "type": "condition",
        "connect": "yellow_question",
        "random": [
            {
                "path": "maze_cond_carrot.png"
            },
            {
                "path": "maze_cond_beetroot.png"
            }
        ]
    },
    18: {
        "path": "maze_cond_orange_question.png",
        "type": "condition",
        "connect": "orange_question",
        "random": [
            {
                "path": "maze_obst_yellowflower.png"
            },
            {
                "path": "maze_cond_pink_flower.png"
            }
        ]
    },
    19: {
        "path": "free_path",
        "type": "path"
    },
    20: {
        "path": "maze_cond_blue_question.png",
        "type": "condition",
        "random": [
            {
                "path": "maze_obst_yellowflower.png"
            },
            {
                "path": "maze_cond_pink_flower.png"
            }
        ]
    },
    21: {
        "angle": 270,
        "path": "rabbit_char",
        "type": "character"
    },
    22: {
        "angle": 0,
        "path": "rabbit_char",
        "type": "character"
    }
}

export default assets;
