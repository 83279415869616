/* AUTHENTICATION */
export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAILED = 'AUTH_FAILED';
export const AUTH_LOGOUT = "AUTH_LOGOUT";
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_LINK_SUCCESS = 'RESET_PASSWORD_LINK_SUCCESS';
export const PASSWORD_CHANGE_SUCCESS = 'PASSWORD_CHANGE_SUCCESS';


export const FETCH_USER_INFO_START = 'FETCH_USER_INFO_START';
export const FETCH_USER_INFO_SUCCESS = 'FETCH_USER_INFO_SUCCESS';
export const FETCH_USER_INFO_FAILED = 'FETCH_USER_INFO_FAILED';

// REGISTER
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';


/* EXERCISES */
export const FETCH_EXERCISES_START = 'FETCH_EXERCISES_START';
export const FETCH_EXERCISES_SUCCESS = 'FETCH_EXERCISES_SUCCESS';
export const FETCH_EXERCISES_FAILED = 'FETCH_EXERCISES_FAILED';


/* USER EXERCISES */
export const FETCH_LEVEL_SUCCESS = 'FETCH_LEVEL_SUCCESS';
export const FETCH_LEVEL_FAILED = 'FETCH_LEVEL_FAILED';
export const FETCH_LEVEL_START = 'FETCH_LEVEL_START';

export const SAVE_XML_SUCCESS = 'SAVE_XML_SUCCESS';
export const SAVE_XML_FAILED = 'SAVE_XML_FAILED';

export const SUBMIT_EXERCISE_SUCCESS = 'SUBMIT_EXERCISE_SUCCESS';
export const SUBMIT_EXERCISE_FAILED = 'SUBMIT_EXERCISE_FAILED';
export const SUBMIT_EXERCISE_RESET = 'SUBMIT_EXERCISE_RESET';
export const SUBMIT_EXERCISE_START = 'SUBMIT_EXERCISE_START';


export const FETCH_COMPLETED_EXERCISE_START = 'FETCH_COMPLETED_EXERCISE_START';
export const FETCH_COMPLETED_EXERCISE_SUCCESS = 'FETCH_COMPLETED_EXERCISE_SUCCESS';
export const FETCH_COMPLETED_EXERCISE_FAILED = 'FETCH_COMPLETED_EXERCISE_FAILED';


/* PROJECTS */
export const FETCH_PROJECTS_START = 'FETCH_PROJECT_START';
export const FETCH_PROJECTS_SUCCESS = 'FETCH_PROJECT_SUCCESS';
export const FETCH_PROJECTS_FAILED = 'FETCH_PROJECT_FAILED';

export const ADD_PROJECT_START = 'ADD_PROJECT_START';
export const ADD_PROJECT_SUCCESS = 'ADD_PROJECT_SUCCESS';
export const ADD_PROJECT_FAILED = 'ADD_PROJECT_FAILED';

export const EDIT_PROJECT_START = 'EDIT_PROJECT_START';
export const EDIT_PROJECT_SUCCESS = 'EDIT_PROJECT_SUCCESS';
export const EDIT_PROJECT_ERROR = 'EDIT_PROJECT_ERROR';

export const DELETE_PROJECT_START = 'DELETE_PROJECT_START';
export const DELETE_PROJECT_SUCCESS = 'DELETE_PROJECT_SUCCESS';
export const DELETE_PROJECT_FAILED = 'DELETE_PROJECT_FAILED';

export const PROJECT_FETCH_START = 'PROJECT_FETCH_START';
export const PROJECT_FETCH_SUCCESS = 'PROJECT_FETCH_SUCCESS';
export const PROJECT_FETCH_FAILED = 'PROJECT_FETCH_FAILED';

export const PROJECT_XML_START = 'PROJECT_XML_START';
export const PROJECT_XML_SUCCESS = 'PROJECT_XML_SUCCESS';
export const PROJECT_XML_FAILED = 'PROJECT_XML_FAILED';

export const UPDATE_STATUS_FAILED = "UPDATE_STATUS_FAILED";
export const UPDATE_STATUS_SUCCESS = "UPDATE_STATUS_SUCCESS";

/* UNPLUGGED */
export const FETCH_UNPLUGGED_START = 'FETCH_UNPLUGGED_START';
export const FETCH_UNPLUGGED_SUCCESS = 'FETCH_UNPLUGGED_SUCCESS';
export const FETCH_UNPLUGGED_FAILED = 'FETCH_UNPLUGGED_FAILED';





