/* eslint no-eval: 0 */
/*eslint array-callback-return: 0*/

class MazeCreator {
  constructor(ctx, matrix, assets) {
    this.ctx = ctx;
    this.matrix = matrix;
    this.assets = assets;
    this.images_folder = "../../images/";

  }
  draw = () => {

    for (var i = 0; i < this.matrix.length; i++) {
      for (var j = 0; j < this.matrix[0].length; j++) {
        var number = this.matrix[i][j] - 1;

        var object = this.assets[number];
        if (object !== undefined) {
          if (object.type === 'character') {
            var character = new Image();
            character.src = this.images_folder + object.path + '_' + object.angle + '.png';
            this.ctx.drawImage(character, j * 50 + 5, i * 50 + 5, 40, 40)
          }

          if(object.type === 'obstacle'){
            var obstacle = new Image();
            obstacle.src = this.images_folder + object.path + '.png';
            this.ctx.drawImage(obstacle, j * 50 + 5, i * 50 + 5, 40, 40);
          }

          if(object.type === 'path'){
            var path = new Image();
            path.src = this.images_folder + object.path + '.png';
            this.ctx.drawImage(path, j * 50, i * 50, 50, 50);
          }

          if(object.type === 'condition'){
            var condition = new Image();
            condition.src = this.images_folder + object.path + '.png';
            this.ctx.drawImage(condition, j * 50 + 5, i * 50 + 5, 40, 40);
          }

          if(object.type === 'destination'){
           
            var destination = new Image();
            destination.src = this.images_folder + object.path + '.png';
            this.ctx.drawImage(destination, j * 50 + 5, i * 50 + 5, 40, 40);
          }

          if(object.type === 'collect'){
            var collect = new Image();
            collect.src = this.images_folder + object.path + '.png';
            this.ctx.drawImage(collect, j * 50 + 5, i * 50 + 5, 40, 40);
          }

          if(object.type === 'score'){
            var score = new Image();
            score.src = this.images_folder + object.path + '.png';
            this.ctx.drawImage(score, j * 50 + 5, i * 50 + 5, 40, 40);
            this.ctx.font = "20px Arial";
            this.ctx.textAlign = "start";
            this.ctx.fillText(0, j * 50 + 20, i * 50 + 42);
          }

          if(object.type === 'conditional_end'){
            var conditional_end = new Image();
            conditional_end.src = this.images_folder + object.path + '.png';
            this.ctx.drawImage(conditional_end, j * 50 + 5, i * 50 + 5, 40, 40);

          }

        }
      }
    }



  };




}

export default MazeCreator;