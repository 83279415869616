var levels = {
    1: {
        "blocks": {
            'World': ['set_background'],
            'Sprites': ['create_sprite_lab3', 'change_sprite_lab3', 'hide_sprite', 'show_sprite'],
            'Sprite Movements': ['sprite_begins', 'sprite_stops', 'move_randomly_with_speed', 'move_left_right', 'move_up_down', 'move_up', 'move_down', 'grow_sprite', 'shrink_sprite', 'if_right_edge', 'move_left', 'stops_every_movement'],
            'Events': ['when_run', 'when_clicked', 'when_button_pressed',
                'when_sprite_touches', 'when_sprite_touches_surface'],
            'Text': ['create_text_input', 'set_text', 'set_text_color', 'set_text_size', 'text_value'],
            'Buttons': ['create_button', 'set_button_colour', 'set_button_text', 'set_button_text_colour', 'set_button_text_size'],
            'Math': ['math_number', 'default_x', 'default_y', 'color_select']

        },
        "initial_background": "laboratorystory_background1.png",
        "initial_code": 'this.state.ctx.beginPath();this.state.ctx.rect(0, 400, 150, 160);this.state.ctx.stroke();this.state.ctx.fillStyle = "#000000";',
        "validations": ["error_all_background", "error_sprite_events_general"],
        "test_cases": {
            "x": 0,
            "y": 400,
            "sprite": "boygenius",
            "dest_x": 300,
            "dest_y": 300,
            "width": 100,
            "height": 100,
            "events": [],
            "events_general": ["isTouching"],
            "background": ["laboratorystory_background1.png", "laboratorystory_background2.png", "laboratorystory_background3.png", "laboratorystory_background4.png"]
        },

    }
}
export default levels;