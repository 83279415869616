/* eslint no-eval: 0 */

import React from 'react';
import _ from 'lodash';

import classes from './MazeCreator.module.css';
import Header from '../../components/UI/Header/Header';
import BlocklyContainer from '../BlocklyContainer';
import BlocklyJS from 'blockly/javascript';
import RunButton from '../RunButton';
import MazeCreator from './MazeCreator';
import '../../blocks/MazeCreator/BlockFunctions';
import levels from './MazeCreator/level';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/index';
import { Title } from '../../components/Title/Title';



class MazeCreatorContainer extends React.Component {
    constructor(props) {
        super(props);
        this.projectId = this.props.match.params.projectId;
        this.mazeCanvas = React.createRef();
        this.BlocklyContainer = BlocklyContainer;
        this.background_source = 'maze_back_1';
        this.assets = [];
        this.matrix = [
            [0, 0, 0, 0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0, 0, 0, 0],
        ];
    }

    state = {
        userProject: null,
        name: '...',
        assets: [],
        matrix: [
            [0, 0, 0, 0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0, 0, 0, 0],
        ],
        status: false
    }

    componentDidMount() {
        this.props.fetchProject(this.projectId);

        var canvas = this.mazeCanvas.current;
        var ctx = canvas.getContext('2d');
        var maze = new MazeCreator(ctx, this.state.matrix, this.state.assets);

        var intervalId = setInterval(this.gameLoop, 10);
        this.setState({ intervalId: intervalId, ctx: ctx, maze: maze });
    }

    componentDidUpdate(prevProps, prevState) {
        if (_.isEqual(prevProps.userProject, this.props.userProject) === false) {
            this.background_source = (this.props.userProject.data.background) ? this.props.userProject.data.background : 'maze_back_1';

            this.setState({
                name: (this.props.userProject.name) ? this.props.userProject.name : '...',
                assets: (this.props.userProject.data.assets) ? this.props.userProject.data.assets : [],
                matrix: (this.props.userProject.data.matrix) ? this.props.userProject.data.matrix : this.state.matrix,
                status: (this.props.userProject.status) ? this.props.userProject.status : false
            })
        }

    

        if (prevProps.xml !== this.props.xml) {
            this.setState({
                xml: this.props.xml
            })
        }

        if (_.isEqual(prevState.matrix, this.state.matrix) === false || _.isEqual(prevState.assets, this.state.assets !== false)) {

            var maze = new MazeCreator(this.state.ctx, this.state.matrix, this.state.assets);
            this.setState({
                maze: maze
            })
        }


    }

    getAssetNumber = (variable) => {
        var x = 0;
        // console.log(this.assets);
        this.assets.map((asset, index) => {

            if (asset.variable === variable) {

                x = index + 1;
            }
        })

        return x;
    }

    createAsset = (type, path, variable, angle = null, name = null) => {

        var assets_new = [...this.assets];
        assets_new.push({
            type: type,
            path: path,
            variable: variable,
            angle: angle,
            name: name
        })

        this.assets = assets_new;
    }

    changeMatrix = (row, column, value) => {
        var matrix = this.matrix;
        matrix[row][column] = value;
        this.matrix = matrix;
    }


    gameLoop = () => {
        var background = new Image();
        background.src = `${process.env.PUBLIC_URL}/images/` + this.background_source + '.png';
        this.state.ctx.drawImage(background, 0, 0, 400, 400);

        this.state.maze.draw();
    }




    changeBackground = (background_source) => {
        this.background_source = background_source;
    }

    onRunClickHandler = function () {
       
        var code = BlocklyJS.workspaceToCode(this.BlocklyContainer.primaryWorkspace);
        this.assets = [];
        this.matrix = [
            [0, 0, 0, 0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0, 0, 0, 0],
        ];
        this.setState({
            matrix: [
                [0, 0, 0, 0, 0, 0, 0, 0],
                [0, 0, 0, 0, 0, 0, 0, 0],
                [0, 0, 0, 0, 0, 0, 0, 0],
                [0, 0, 0, 0, 0, 0, 0, 0],
                [0, 0, 0, 0, 0, 0, 0, 0],
                [0, 0, 0, 0, 0, 0, 0, 0],
                [0, 0, 0, 0, 0, 0, 0, 0],
                [0, 0, 0, 0, 0, 0, 0, 0],
            ],
            assets: []
        })
        try {
            
            eval(code);

        } catch (e) {
            console.log(e);
        }


        this.setState({
            matrix: this.matrix,
            assets: this.assets
        }, () => {
            var data = {
                'background': this.background_source,
                'assets': this.state.assets,
                'matrix': this.state.matrix
            }

            this.props.saveProjectXml(this.projectId, this.state.xml, data);

        })

    }

    onPublishChange = (e) => {
        this.props.updateStatus(this.projectId, e.target.checked);
    }

    render() {

        var data = {
            'background': this.background_source,
            'assets': this.state.assets,
            'matrix': this.state.matrix
        }

        return (
            <React.Fragment>
                <Title title = {"ITK - " + this.state.name} />
                <Header color="#F2F2F2" />
                <div className="container">
                    <div class = {classes.playsTabs}>
                        <a href = {"/project/maze/" + this.projectId} class = {[classes.tabButton, classes.active].join(' ')}>Builder</a>
                        <a href = {"/project/maze/play/" + this.projectId} class = {classes.tabButton}>Play</a>
                        <a href = {"/project/maze/blocks/" + this.projectId} class = {classes.tabButton}>Play Blocks</a>
                    </div>
                    
                    <div className="grid-maze">
                        <div className="preview">
                            <h2>{(this.state.name !== null) ? this.state.name : '...'}</h2><br />
                            <canvas ref={this.mazeCanvas} width={400} height={400} style={{ 'border': "1px solid black" }} />
                            <br />
                            <div className="btnSection">
                                <div>
                                    <RunButton runClickHandler={this.onRunClickHandler.bind(this)} />
                                    <button onClick={this.reset} className="btn">Reset</button>
                                </div>
                                <div class = {classes.switchContainer}>
                                    {/* <button onClick={this.submit} className="btn" style={{ backgroundColor: 'red' }}>Submit</button> */}
                                    <p class = {classes.switchText}>Publish </p> <label class = {classes.switch}>
                                        <input type="checkbox" checked = {this.state.status} onChange = {(e) => this.onPublishChange(e)} />
                                        <span class={[classes.slider, classes.round].join(' ')}></span>
                                    </label>
                               </div>
                            </div>
                        </div>
                       
                        <BlocklyContainer data={data} initialXml={(this.props.userProject) ? this.props.userProject.xml : ''} blocks={levels[1].blocks} projectId={this.props.match.params.projectId} />
                        
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

const mapDispatchToProps = dispatch => {
    return {
        fetchProject: (id) => dispatch(actions.fetchProject(id)),
        saveProjectXml: (id, xml, data) => dispatch(actions.saveProjectXml(id, xml, data)),
        updateStatus: (id, status) => dispatch(actions.updateStatus(id, status))
    }
}

const mapStateToProps = state => {
    return {
        userProject: state.projects.userProject,
        xml: state.projects.xml
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MazeCreatorContainer);