var levels = {

    1: {

        "blocks": {
            'World': ['set_background'],
            'Sprites': ['create_sprite', 'hide_sprite'],
            'Sprite Movements': ['sprite_begins', 'sprite_stops', 'move_randomly_with_speed', 'move_left_right', 'move_up_down', 'move_down', 'grow_sprite', 'shrink_sprite', 'move_left', 'stops_every_movement'],
            'Events': ['when_run', 'when_clicked', 'when_button_pressed',
                'when_sprite_touches', 'when_sprite_touches_surface'],
            'Text': ['create_text_input', 'set_text', 'set_text_color', 'set_text_size', 'text_value'],
            'Screens': ['create_screen', 'change_screens'],
            'Buttons': ['create_button', 'set_button_colour', 'set_button_text', 'set_button_text_colour', 'set_button_text_size'],
            'Variables': ['numerical_input_value', 'text_value', 'color_select']
        },
        "initial_background": "space_story_background.png",
        "initial_code": 'this.state.ctx.beginPath();this.state.ctx.rect(0, 250, 150, 160);this.state.ctx.rect(250, 200, 150, 150);this.state.ctx.stroke();this.state.ctx.fillStyle = "#000000";',
        "validations": ["error_background", "error_sprite_xy", "error_sprite_events", "error_sprite_dest", "error_sprite_speed"],
        "test_cases": {
            "x": 0,
            "y": 250,
            "sprite": "astrostory",
            "dest_x": 250,
            "dest_y": 200,
            "width": 100,
            "height": 100,
            "events": [{ "astrostory": "isTouching" }],
            "background": "space_story_background.png"
        },
    }

}

export default levels;