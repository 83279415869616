// import _ from 'lodash';
/*eslint array-callback-return: 0*/
import _ from 'lodash';
import Text from './Text';
import Shape from './Shape';
import Sprite from './Sprite';
import Button from './Button';
import Group from './Group';
import Timer from './Timer';

class GameBuilder {
  constructor(ctx, canvas) {
    this.ctx = ctx;
    this.canvas = canvas;
    this.images_folder = `${process.env.PUBLIC_URL}/images/GameLab/`;

    this.width = 360;
    this.height = 550;
    this.backgroundColor = '#fff';
    this.backgroundImage = '';
    this.backgroundPriority = '';

    this.texts = {};
    this.shapes = {};
    this.sprites = {};
    this.buttons = {};
    this.groups = {};
    this.groupMovements = {};
    this.group_sprites = {};
    this.group_edge = {};
    this.group_edge_sprites = {};
    this.events = {};
    this.timers = {};
  }

  onCanvasClick = (e) => {
    let rect = this.canvas.getBoundingClientRect();
    let x = e.clientX - rect.left;
    let y = e.clientY - rect.top;

    Object.keys(this.buttons).forEach((b) => {
      if (this.buttons[b].setOnClick === true) {
        this.buttons[b].click(x, y);
      }
    });

    return [x, y];
  };

  gameLoop = () => {
    this.ctx.clearRect(0, 0, this.width, this.height);

    if (this.backgroundPriority === 'color') {
      this.ctx.fillStyle = this.backgroundColor;
      this.ctx.fillRect(0, 0, this.width, this.height);
    } else if (this.backgroundPriority === 'image') {
      var img = new Image();
      img.src = this.images_folder + this.backgroundImage + '.png';
      this.ctx.drawImage(img, 0, 0);
    }

    Object.keys(this.sprites).forEach((key) => {
      this.sprites[key].draw();
      this.sprites[key].update();
    });

    Object.keys(this.groups).forEach((key) => {
      this.groups[key].draw();

      let x = 0;

      const group_sprites_count = _.filter(this.sprites, { group: key }).length;

      this.groupMovements[key].forEach((m) => {
        for (var i = 0; i < group_sprites_count; i++) {
          if (!this.group_sprites[key + '_' + i][x]) {
            this.group_sprites[key + '_' + i].push(m);
            var self = this;
            eval('self.sprites[key + "_" + (i)].' + m);
          }
        }

        x = x + 1;
      });

      if (this.group_edge[key]) {
        const group_edge_count = _.filter(this.sprites, { group: key }).length;

        for (var i = 0; i < group_edge_count; i++) {
          // eslint-disable-next-line no-loop-func
          this.group_edge[key].forEach((ge) => {
            if (this.group_edge_sprites[key + '_' + i][ge.edge] !== true) {
              this.sprites[key + '_' + i].movements.push(ge);
              this.group_edge_sprites[key + '_' + i][ge.edge] = true;
            }
          });
        }
      }
    });

    Object.keys(this.texts).forEach((key) => {
      this.texts[key].draw();
    });

    Object.keys(this.shapes).forEach((key) => {
      this.shapes[key].draw();
    });

    Object.keys(this.buttons).forEach((key) => {
      this.buttons[key].draw();
    });
  };

  /* ----- WORLD ----- */
  changeBackgroundColor = (backgroundColor) => {
    this.backgroundColor = backgroundColor;
    this.backgroundPriority = 'color';
  };

  changeBackgroundImage = (backgroundImage) => {
    this.backgroundImage = backgroundImage;
    this.backgroundPriority = 'image';
  };

  /* ------ SPRITES ----- */
  drawSprite = (name, x, y, src) => {
    this.sprites[name] = new Sprite(this.ctx, name, src, x, y);
  };

  getSpriteProperty = (name, prop) => {
    //todo: check if the sprite exists
    return this.sprites[name][prop];
  };

  setSpriteProperty = (name, prop, val) => {
    this.sprites[name][prop] = val;
  };

  spriteStopAllMovements = (name) => {
    this.sprites[name].movements = [];
    this.sprites[name].dx = 0;
    this.sprites[name].dy = 0;
    this.sprites[name].jx = 0;
    this.sprites[name].jy = 0;
  };

  /* ------- EVENTS ---------- */

  isObjectClick = (name, clicked_x, clicked_y, func) => {
    if (this.sprites[name]) {
      var sprite = this.sprites[name];
      if (
        clicked_x >= sprite.x &&
        clicked_x <= sprite.x + sprite.width &&
        clicked_y >= sprite.y &&
        clicked_y <= sprite.height + sprite.y
      ) {
        func();
      }
    }

    if (this.buttons[name]) {
      var button = this.buttons[name];
      if (
        clicked_x >= button.x &&
        clicked_x <= button.x + button.width &&
        clicked_y >= button.y &&
        clicked_y <= button.height + sprite.y
      ) {
        func();
      }
    }
  };

  sprite_touches_sprite = (name, sprite2, func) => {
    this.sprites[name].movements.push({
      type: 'sprite_touches_sprite',
      name: name,
      sprite: this.sprites[sprite2],
      func: func,
    });
  };

  sprite_touches_group = (name, group, func) => {
    this.sprites[name].movements.push({
      type: 'sprite_touches_group',
      name: name,
      group: _.filter(this.sprites, { group: group }),
      func: func,
    });
  };

  sprite_touches_edge = (name, edge, func) => {
    this.sprites[name].movements.push({
      type: 'sprite_touch_edge',
      name: name,
      edge: edge,
      func: func,
    });
  };

  spriteStops = (name, type) => {
    if (type === 'random_move' || type === 'move') {
      this.sprites[name].dx = 0;
      this.sprites[name].dy = 0;
    }

    if (type === 'jittering') {
      this.sprites[name].jx = 0;
      this.sprites[name].jy = 0;
    }

    if (type === 'grow' || type === 'shrink') {
      this.sprites[name].dw = 0;
      this.sprites[name].dh = 0;
    }

    _.remove(this.sprites[name].movements, { type: type });
  };

  /* ------ GROUP ------- */
  createGroup = (name) => {
    this.groups[name] = new Group(this.ctx, name);
    this.groupMovements[name] = [];
    this.group_edge[name] = [];
  };

  addToGroup = (name, src, x, y) => {
    var group_length = _.filter(this.sprites, { group: name }).length;

    var group = name + '_' + group_length;
    this.group_sprites[group] = [];
    this.group_edge_sprites[group] = [];
    this.sprites[group] = new Sprite(this.ctx, group, src, x, y, name);

    // console.log(this.sprites);
  };

  group_touches_edge = (name, edge, func) => {
    // var sprites = _.filter(this.sprites, { group: name });
    // sprites.forEach((sprite) => {
    //   this.sprites[sprite.name].movements.push({
    //     type: 'sprite_touch_edge',
    //     name: sprite.name,
    //     edge: edge,
    //     func: func,
    //   });
    // });

    this.group_edge[name].push({
      type: 'sprite_touch_edge',
      name: name,
      edge: edge,
      func: func,
    });
  };

  group_touches_group = (group1, group2, func) => {
    var group1_sprites = _.filter(this.sprites, { group: group1 });
    var group2_sprites = _.filter(this.sprites, { group: group2 });

    group1_sprites.forEach((gs1) => {
      this.sprites[gs1.name].movements.push({
        type: 'sprite_touches_group',
        name: gs1.name,
        group: group2_sprites,
        func: func,
      });
    });
  };

  groupStops = (name, type) => {
    var sprites = _.filter(this.sprites, { group: name });
    sprites.forEach((sprite) => {
      if (type === 'random_move') {
        this.sprites[sprite.name].dx = 0;
        this.sprites[sprite.name].dy = 0;
      }
      _.remove(this.sprites[sprite.name].movements, { type: type });
    });
  };

  hideGroup = (name) => {
    this.groups[name].changeVisibility(false);
  };

  /* ----- SHAPES ------ */
  drawRectangle = (name, width, height, x, y, color) => {
    this.shapes[name] = new Shape(
      this.ctx,
      name,
      'rectangle',
      x,
      y,
      width,
      height,
      null,
      color
    );
  };

  drawCircle = (name, radius, x, y, color) => {
    this.shapes[name] = new Shape(
      this.ctx,
      name,
      'circle',
      x,
      y,
      null,
      null,
      radius,
      color
    );
  };

  setShapeProperty = (name, prop, value) => {
    this.shapes[name][prop] = value;
  };

  getShapeProperty = (name, prop) => {
    return this.shapes[name][prop];
  };

  /* ----- TEXT ------ */

  drawText = (name, text, x, y) => {
    this.texts[name] = new Text(this.ctx, text, name, x, y);
  };

  getText = (name) => {
    return this.texts[name].getText();
  };

  setTextProperty = (name, prop, value) => {
    this.texts[name][prop] = value;
  };

  getTextProperty = (name, prop) => {
    return this.texts[name][prop];
  };

  setText = (name, newText) => {
    this.texts[name].setText(newText);
  };

  setColor = (name, newColor) => {
    if (this.texts[name]) {
      this.texts[name].setColor(newColor);
    }
  };

  /* ------ BUTTON --------- */
  drawButton = (name, width, height, x, y) => {
    this.buttons[name] = new Button(this.ctx, name, width, height, x, y);
  };

  setButtonBgColor = (name, newColor) => {
    this.buttons[name].setBackgroundColor(newColor);
  };

  setButtonTextColor = (name, newColor) => {
    this.buttons[name].setTextColor(newColor);
  };

  setButtonText = (name, newText) => {
    this.buttons[name].setText(newText);
  };

  setButtonTextSize = (name, newSize) => {
    this.buttons[name].setTextSize(newSize);
  };

  setButtonVisibility = (name, val) => {
    this.buttons[name].visible = val;
  };

  /* Timer */

  createTimer = (name, interval) => {
    this.timers[name] = new Timer(name, interval);
  };

  getInterval = (name) => {
    return this.timers[name].get_interval();
  };

  isTimerStart = (name) => {
    return this.timers[name].started;
  };

  startTimer = (name) => {
    this.timers[name].start();
  };

  pauseTimer = (name) => {
    this.timers[name].pause();
  };

  setTimerProperty = (name, prop, value) => {
    this.timers[name][prop] = value;
  };

  sleep(milliseconds) {
    var start = new Date().getTime();
    for (var i = 0; i < 1e7; i++) {
      if (new Date().getTime() - start > milliseconds) {
        break;
      }
    }
  }
}

export default GameBuilder;
