import * as Blockly from 'blockly/core';

/* World */
Blockly.Blocks['set_background'] = {
  init: function () {
    this.appendDummyInput()
      .appendField('set background to')
      .appendField(
        new Blockly.FieldDropdown([
          [
            {
              src: `${process.env.PUBLIC_URL}/images/GameLab/underwater_story_01.png`,
              width: 15,
              height: 15,
              alt: '*',
            },
            'underwater_story_01',
          ],
          [
            {
              src: `${process.env.PUBLIC_URL}/images/GameLab/underwater_story_02.png`,
              width: 15,
              height: 15,
              alt: '*',
            },
            'underwater_story_02',
          ],
          [
            {
              src: `${process.env.PUBLIC_URL}/images/GameLab/underwater_story_03.png`,
              width: 15,
              height: 15,
              alt: '*',
            },
            'underwater_story_03',
          ],
          [
            {
              src: `${process.env.PUBLIC_URL}/images/GameLab/underwater_story_04.png`,
              width: 15,
              height: 15,
              alt: '*',
            },
            'underwater_story_04',
          ],
        ]),
        'set background'
      );
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(230);
    this.setTooltip('Set background to represent chosen image');
    this.setHelpUrl('');
  },
};

Blockly.Blocks['set_background_color'] = {
  init: function () {
    this.appendValueInput('set background colour')
      .setCheck(null)
      .appendField(
        new Blockly.FieldLabelSerializable('set background colour'),
        'set bg colour'
      );
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(230);
    this.setTooltip('');
    this.setHelpUrl('');
  },
};

/* Sprites */
Blockly.Blocks['create_sprite'] = {
  init: function () {
    this.appendDummyInput()
      .appendField('draw sprite')
      .appendField(
        new Blockly.FieldDropdown([
          [
            {
              src: `${process.env.PUBLIC_URL}/images/GameLab/clownfish_90.png`,
              width: 15,
              height: 15,
              alt: '*',
            },
            'clownfish_90',
          ],
          [
            {
              src: `${process.env.PUBLIC_URL}/images/GameLab/clownfish_270.png`,
              width: 15,
              height: 15,
              alt: '*',
            },
            'clownfish_270',
          ],
          [
            {
              src: `${process.env.PUBLIC_URL}/images/GameLab/crab.png`,
              width: 15,
              height: 15,
              alt: '*',
            },
            'crab',
          ],
          [
            {
              src: `${process.env.PUBLIC_URL}/images/GameLab/jellyfish_90.png`,
              width: 15,
              height: 15,
              alt: '*',
            },
            'jellyfish_90',
          ],
          [
            {
              src: `${process.env.PUBLIC_URL}/images/GameLab/jellyfish_270.png`,
              width: 15,
              height: 15,
              alt: '*',
            },
            'jellyfish_270',
          ],
          [
            {
              src: `${process.env.PUBLIC_URL}/images/GameLab/porpoise_90.png`,
              width: 15,
              height: 15,
              alt: '*',
            },
            'porpoise_90',
          ],
          [
            {
              src: `${process.env.PUBLIC_URL}/images/GameLab/porpoise_270.png`,
              width: 15,
              height: 15,
              alt: '*',
            },
            'porpoise_270',
          ],
          [
            {
              src: `${process.env.PUBLIC_URL}/images/GameLab/pufferfish.png`,
              width: 15,
              height: 15,
              alt: '*',
            },
            'pufferfish',
          ],
          [
            {
              src: `${process.env.PUBLIC_URL}/images/GameLab/purple_fish_90.png`,
              width: 15,
              height: 15,
              alt: '*',
            },
            'purple_fish_90',
          ],
          [
            {
              src: `${process.env.PUBLIC_URL}/images/GameLab/purple_fish_270.png`,
              width: 15,
              height: 15,
              alt: '*',
            },
            'purple_fish_270',
          ],
          [
            {
              src: `${process.env.PUBLIC_URL}/images/GameLab/red_fish_90.png`,
              width: 15,
              height: 15,
              alt: '*',
            },
            'red_fish_90',
          ],
          [
            {
              src: `${process.env.PUBLIC_URL}/images/GameLab/red_fish_270.png`,
              width: 15,
              height: 15,
              alt: '*',
            },
            'red_fish_270',
          ],
          [
            {
              src: `${process.env.PUBLIC_URL}/images/GameLab/redsquid_90.png`,
              width: 15,
              height: 15,
              alt: '*',
            },
            'redsquid_90',
          ],
          [
            {
              src: `${process.env.PUBLIC_URL}/images/GameLab/redsquid_270.png`,
              width: 15,
              height: 15,
              alt: '*',
            },
            'redsquid_270',
          ],
          [
            {
              src: `${process.env.PUBLIC_URL}/images/GameLab/seahorse_90.png`,
              width: 15,
              height: 15,
              alt: '*',
            },
            'seahorse_90',
          ],
          [
            {
              src: `${process.env.PUBLIC_URL}/images/GameLab/seahorse_270.png`,
              width: 15,
              height: 15,
              alt: '*',
            },
            'seahorse_270',
          ],
          [
            {
              src: `${process.env.PUBLIC_URL}/images/GameLab/stripefish_90.png`,
              width: 15,
              height: 15,
              alt: '*',
            },
            'stripefish_90',
          ],
          [
            {
              src: `${process.env.PUBLIC_URL}/images/GameLab/stripefish_270.png`,
              width: 15,
              height: 15,
              alt: '*',
            },
            'stripefish_270',
          ],
          [
            {
              src: `${process.env.PUBLIC_URL}/images/GameLab/white_fish_90.png`,
              width: 15,
              height: 15,
              alt: '*',
            },
            'white_fish_90',
          ],
          [
            {
              src: `${process.env.PUBLIC_URL}/images/GameLab/white_fish_270.png`,
              width: 15,
              height: 15,
              alt: '*',
            },
            'white_fish_270',
          ],
        ]),
        'sprite options'
      );
    this.appendDummyInput()
      .appendField('with name')
      .appendField(new Blockly.FieldTextInput('sprite name'), 'sprite name');
    this.appendValueInput('X location')
      .setCheck(null)
      .appendField('at X position');
    this.appendValueInput('Y location')
      .setCheck(null)
      .appendField('at Y position');
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(0);
    this.setTooltip('');
    this.setHelpUrl('');
  },
};

Blockly.Blocks['set_sprite_properties'] = {
  init: function () {
    // this.appendDummyInput()
    //     .appendField(new Blockly.FieldLabelSerializable("set sprite"), "set sprite")
    //     .appendField(new Blockly.FieldTextInput("sprite name"), "sprite name");
    this.appendValueInput('sprite name')
      .setCheck(null)
      .appendField('set sprite');
    this.appendDummyInput()
      .appendField(
        new Blockly.FieldDropdown([
          ['x', 'x'],
          ['y', 'y'],
          ['width', 'width'],
          ['height', 'height'],
        ]),
        'properties'
      )
      .appendField(new Blockly.FieldLabelSerializable('to'), 'to');
    this.appendValueInput('NAME').setCheck(null);
    this.setInputsInline(true);
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(0);
    this.setTooltip('');
    this.setHelpUrl('');
  },
};

Blockly.Blocks['get_sprite_properties'] = {
  init: function () {
    this.appendValueInput('sprite name')
      .setCheck(null)
      .appendField('get')
      .appendField(
        new Blockly.FieldDropdown([
          ['x', 'x'],
          ['y', 'y'],
          ['width', 'width'],
          ['height', 'height'],
        ]),
        'property'
      )
      .appendField('of');
    this.setOutput(true, null);
    this.setColour(0);
    this.setTooltip('');
    this.setHelpUrl('');
  },
};

Blockly.Blocks['hide_sprite'] = {
  init: function () {
    this.appendDummyInput()
      .appendField('hide')
      .appendField(new Blockly.FieldTextInput('sprite name'), 'NAME');
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(0);
    this.setTooltip('');
    this.setHelpUrl('');
  },
};

/* Sprite Groups */
Blockly.Blocks['create_group'] = {
  init: function () {
    this.appendDummyInput()
      .appendField('create group')
      .appendField(new Blockly.FieldTextInput('name'), 'group name');
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(0);
    this.setTooltip('');
    this.setHelpUrl('');
  },
};

Blockly.Blocks['add_sprite_to_group'] = {
  init: function () {
    this.appendDummyInput()
      .appendField(
        new Blockly.FieldLabelSerializable('add sprite'),
        'add sprite'
      )
      .appendField(
        new Blockly.FieldDropdown([
          [
            {
              src: `${process.env.PUBLIC_URL}/images/GameLab/clownfish_90.png`,
              width: 15,
              height: 15,
              alt: '*',
            },
            'clownfish_90',
          ],
          [
            {
              src: `${process.env.PUBLIC_URL}/images/GameLab/clownfish_270.png`,
              width: 15,
              height: 15,
              alt: '*',
            },
            'clownfish_270',
          ],
          [
            {
              src: `${process.env.PUBLIC_URL}/images/GameLab/crab.png`,
              width: 15,
              height: 15,
              alt: '*',
            },
            'crab',
          ],
          [
            {
              src: `${process.env.PUBLIC_URL}/images/GameLab/jellyfish_90.png`,
              width: 15,
              height: 15,
              alt: '*',
            },
            'jellyfish_90',
          ],
          [
            {
              src: `${process.env.PUBLIC_URL}/images/GameLab/jellyfish_270.png`,
              width: 15,
              height: 15,
              alt: '*',
            },
            'jellyfish_270',
          ],
          [
            {
              src: `${process.env.PUBLIC_URL}/images/GameLab/porpoise_90.png`,
              width: 15,
              height: 15,
              alt: '*',
            },
            'porpoise_90',
          ],
          [
            {
              src: `${process.env.PUBLIC_URL}/images/GameLab/porpoise_270.png`,
              width: 15,
              height: 15,
              alt: '*',
            },
            'porpoise_270',
          ],
          [
            {
              src: `${process.env.PUBLIC_URL}/images/GameLab/pufferfish.png`,
              width: 15,
              height: 15,
              alt: '*',
            },
            'pufferfish',
          ],
          [
            {
              src: `${process.env.PUBLIC_URL}/images/GameLab/purple_fish_90.png`,
              width: 15,
              height: 15,
              alt: '*',
            },
            'purple_fish_90',
          ],
          [
            {
              src: `${process.env.PUBLIC_URL}/images/GameLab/purple_fish_270.png`,
              width: 15,
              height: 15,
              alt: '*',
            },
            'purple_fish_270',
          ],
          [
            {
              src: `${process.env.PUBLIC_URL}/images/GameLab/red_fish_90.png`,
              width: 15,
              height: 15,
              alt: '*',
            },
            'red_fish_90',
          ],
          [
            {
              src: `${process.env.PUBLIC_URL}/images/GameLab/red_fish_270.png`,
              width: 15,
              height: 15,
              alt: '*',
            },
            'red_fish_270',
          ],
          [
            {
              src: `${process.env.PUBLIC_URL}/images/GameLab/redsquid_90.png`,
              width: 15,
              height: 15,
              alt: '*',
            },
            'redsquid_90',
          ],
          [
            {
              src: `${process.env.PUBLIC_URL}/images/GameLab/redsquid_270.png`,
              width: 15,
              height: 15,
              alt: '*',
            },
            'redsquid_270',
          ],
          [
            {
              src: `${process.env.PUBLIC_URL}/images/GameLab/seahorse_90.png`,
              width: 15,
              height: 15,
              alt: '*',
            },
            'seahorse_90',
          ],
          [
            {
              src: `${process.env.PUBLIC_URL}/images/GameLab/seahorse_270.png`,
              width: 15,
              height: 15,
              alt: '*',
            },
            'seahorse_270',
          ],
          [
            {
              src: `${process.env.PUBLIC_URL}/images/GameLab/stripefish_90.png`,
              width: 15,
              height: 15,
              alt: '*',
            },
            'stripefish_90',
          ],
          [
            {
              src: `${process.env.PUBLIC_URL}/images/GameLab/stripefish_270.png`,
              width: 15,
              height: 15,
              alt: '*',
            },
            'stripefish_270',
          ],
          [
            {
              src: `${process.env.PUBLIC_URL}/images/GameLab/white_fish_90.png`,
              width: 15,
              height: 15,
              alt: '*',
            },
            'white_fish_90',
          ],
          [
            {
              src: `${process.env.PUBLIC_URL}/images/GameLab/white_fish_270.png`,
              width: 15,
              height: 15,
              alt: '*',
            },
            'white_fish_270',
          ],
        ]),
        'sprite name'
      );
    this.appendDummyInput()
      .appendField(
        new Blockly.FieldLabelSerializable('to sprite group'),
        'to sprite group'
      )
      .appendField(new Blockly.FieldTextInput('name'), 'group name');
    this.appendValueInput('X position')
      .setCheck(null)
      .appendField(
        new Blockly.FieldLabelSerializable('at x position'),
        'X position'
      );
    this.appendValueInput('Y position')
      .setCheck(null)
      .appendField(
        new Blockly.FieldLabelSerializable('at y position'),
        'Y position'
      );
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(0);
    this.setTooltip('Add sprite to the group');
    this.setHelpUrl('');
  },
};

Blockly.Blocks['hide_group'] = {
  init: function () {
    this.appendDummyInput()
      .appendField(new Blockly.FieldLabelSerializable('hide'), 'hide')
      .appendField(new Blockly.FieldTextInput('group name'), 'group name');
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(0);
    this.setTooltip('Hide the sprite group');
    this.setHelpUrl('');
  },
};

/* Drawings */
Blockly.Blocks['create_rectangle'] = {
  init: function () {
    this.appendDummyInput()
      .appendField(
        new Blockly.FieldLabelSerializable('draw rectangle'),
        'create rect'
      )
      .appendField(new Blockly.FieldTextInput('name'), 'rectangle name');
    this.appendDummyInput()
      .appendField(new Blockly.FieldLabelSerializable('width '), 'with width')
      .appendField(new Blockly.FieldNumber(0, 0), 'width')
      .appendField(new Blockly.FieldLabelSerializable('height '), 'and height')
      .appendField(new Blockly.FieldNumber(0, 0), 'height');
    this.appendValueInput('x location')
      .setCheck(null)
      .appendField(
        new Blockly.FieldLabelSerializable('at x location'),
        'x location'
      );
    this.appendValueInput('y location')
      .setCheck(null)
      .appendField(
        new Blockly.FieldLabelSerializable('at y location'),
        'y location'
      );
    this.appendValueInput('sprite color')
      .setCheck(null)
      .appendField(new Blockly.FieldLabelSerializable('with color'), 'NAME');
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(0);
    this.setTooltip('Create the rectangle sprites using this');
    this.setHelpUrl('');
  },
};

Blockly.Blocks['create_circle'] = {
  init: function () {
    this.appendDummyInput()
      .appendField(
        new Blockly.FieldLabelSerializable('draw circle'),
        'create circle'
      )
      .appendField(new Blockly.FieldTextInput('name'), 'sprite name');
    this.appendDummyInput()
      .appendField(
        new Blockly.FieldLabelSerializable('with radius'),
        'with radius'
      )
      .appendField(new Blockly.FieldNumber(0), 'radius');
    this.appendValueInput('x location')
      .setCheck(null)
      .appendField(
        new Blockly.FieldLabelSerializable('at x location'),
        'x location'
      );
    this.appendValueInput('y location')
      .setCheck(null)
      .appendField(
        new Blockly.FieldLabelSerializable('at y location'),
        'y location'
      );
    this.appendValueInput('sprite color')
      .setCheck(null)
      .appendField(
        new Blockly.FieldLabelSerializable('with color'),
        'sprite color'
      );
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(0);
    this.setTooltip('Create the ball that bounces off walls');
    this.setHelpUrl('');
  },
};
Blockly.Blocks['group_bounce'] = {
  init: function () {
    this.appendDummyInput()
      .appendField(new Blockly.FieldTextInput('group name'), 'NAME')
      .appendField('bounce from walls');
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(135);
    this.setTooltip('');
    this.setHelpUrl('');
  },
};

Blockly.Blocks['random_int'] = {
  init: function () {
    this.appendDummyInput()
      .appendField('random number from')
      .appendField(new Blockly.FieldNumber(0), 'min')
      .appendField('to')
      .appendField(new Blockly.FieldNumber(0), 'max');
    this.setOutput(true, null);
    this.setColour(230);
    this.setTooltip('');
    this.setHelpUrl('');
  },
};

Blockly.Blocks['if_state'] = {
  init: function () {
    this.appendValueInput('NAME').setCheck(null).appendField('If');
    this.appendStatementInput('state').setCheck(null).appendField('do');
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(230);
    this.setTooltip('');
    this.setHelpUrl('');
  },
};

Blockly.Blocks['bounce_from_walls'] = {
  init: function () {
    this.appendDummyInput()
      .appendField(new Blockly.FieldTextInput('sprite name'), 'NAME')
      .appendField('bounce from walls');
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(135);
    this.setTooltip('');
    this.setHelpUrl('');
  },
};

Blockly.Blocks['set_shape_propeties'] = {
  init: function () {
    this.appendDummyInput().appendField('set shape');
    this.appendValueInput('sprite name').setCheck(null);
    this.appendDummyInput()
      .appendField('property')
      .appendField(
        new Blockly.FieldDropdown([
          ['x', 'x'],
          ['y', 'y'],
          ['width', 'width'],
          ['height', 'height'],
          ['radius', 'radius'],
          ['color', 'fillColor'],
        ]),
        'property'
      )
      .appendField('to');
    this.appendValueInput('prop_value').setCheck(null);
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(0);
    this.setTooltip('');
    this.setHelpUrl('');
  },
};

Blockly.Blocks['get_shape_property'] = {
  init: function () {
    this.appendDummyInput().appendField('get shape');
    this.appendValueInput('sprite name').setCheck(null);
    this.appendDummyInput()
      .appendField('property')
      .appendField(
        new Blockly.FieldDropdown([
          ['x', 'x'],
          ['y', 'y'],
          ['width', 'width'],
          ['height', 'height'],
          ['radius', 'radius'],
          ['color', 'color'],
        ]),
        'prop'
      );
    this.setOutput(true, null);
    this.setColour(0);
    this.setTooltip('');
    this.setHelpUrl('');
  },
};

/* Sprite Movements */

Blockly.Blocks['sprite_begins'] = {
  init: function () {
    this.appendValueInput('spritename')
      .setCheck(null)
      .appendField('sprite name');
    this.appendValueInput('begins').setCheck(null).appendField('begins');
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(180);
    this.setTooltip('');
    this.setHelpUrl('');
  },
};

Blockly.Blocks['stops_every_movement'] = {
  init: function () {
    this.appendDummyInput().appendField(
      new Blockly.FieldLabelSerializable('sprite'),
      'sprite'
    );
    this.appendValueInput('sprite name').setCheck(null).appendField('');
    this.appendDummyInput().appendField(
      new Blockly.FieldLabelSerializable('stops all movement'),
      'stops all movement'
    );
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(180);
    this.setTooltip('');
    this.setHelpUrl('');
  },
};

Blockly.Blocks['sprite_stops'] = {
  init: function () {
    this.appendValueInput('sprite name').setCheck(null).appendField('sprite');
    this.appendValueInput('stops movements')
      .setCheck(null)
      .appendField(new Blockly.FieldLabelSerializable('stops'), 'stops');
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(180);
    this.setTooltip('Makes sprites stop on going movements');
    this.setHelpUrl('');
  },
};

Blockly.Blocks['sprite_group_begins'] = {
  init: function () {
    this.appendDummyInput()
      .appendField(new Blockly.FieldLabelSerializable('group'), 'group')
      .appendField(new Blockly.FieldTextInput('group name'), 'group name');
    this.appendValueInput('NAME')
      .setCheck(null)
      .appendField(new Blockly.FieldLabelSerializable('begins'), 'NAME');
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(180);
    this.setTooltip('Makes the sprite group start performing chosen movements');
    this.setHelpUrl('');
  },
};

Blockly.Blocks['jittering'] = {
  init: function () {
    this.appendValueInput('NAME')
      .setCheck(null)
      .appendField(
        new Blockly.FieldLabelSerializable('jittering by'),
        'jittering'
      );
    this.setInputsInline(true);
    this.setOutput(true, null);
    this.setColour(135);
    this.setTooltip('Makes the sprite start jittering');
    this.setHelpUrl('');
  },
};

Blockly.Blocks['bounce_action'] = {
  init: function () {
    this.appendDummyInput().appendField(
      new Blockly.FieldLabelSerializable('bounce'),
      'bounce'
    );
    this.setOutput(true, null);
    this.setColour(230);
    this.setTooltip('Bounce from the surface');
    this.setHelpUrl('');
  },
};

Blockly.Blocks['group_stops'] = {
  init: function () {
    this.appendDummyInput()
      .appendField(new Blockly.FieldLabelSerializable('group'), 'group')
      .appendField(new Blockly.FieldTextInput('group name'), 'group name');
    this.appendValueInput('stops')
      .setCheck(null)
      .appendField(new Blockly.FieldLabelSerializable('stops'), 'stops');
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(180);
    this.setTooltip('Makes groups of sprites stop on going movement');
    this.setHelpUrl('');
  },
};

Blockly.Blocks['move_randomly'] = {
  init: function () {
    this.appendValueInput('NAME')
      .setCheck(null)
      .appendField(
        new Blockly.FieldLabelSerializable('move randomly with speed'),
        'move randomly'
      );
    this.setInputsInline(true);
    this.setOutput(true, null);
    this.setColour(135);
    this.setTooltip('Move sprite randomly with input speed');
    this.setHelpUrl('');
  },
};

Blockly.Blocks['move_left'] = {
  init: function () {
    this.appendValueInput('left distance')
      .setCheck(null)
      .appendField(new Blockly.FieldLabelSerializable('moving'), 'moving by')
      .appendField(
        new Blockly.FieldDropdown([
          ['left', '180'],
          ['right', '0'],
          ['up', '270'],
          ['down', '90'],
        ]),
        'direction'
      )
      .appendField(new Blockly.FieldLabelSerializable('by'), 'NAME');
    this.appendValueInput('speed')
      .setCheck(null)
      .appendField(
        new Blockly.FieldLabelSerializable('with speed'),
        'with speed'
      );
    this.setInputsInline(true);
    this.setOutput(true, null);
    this.setColour(135);
    this.setTooltip('Move sprite to the left');
    this.setHelpUrl('');
  },
};

Blockly.Blocks['stop_all_movement'] = {
  init: function () {
    this.appendDummyInput().appendField('stop all movement');
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(180);
    this.setTooltip('');
    this.setHelpUrl(null);
  },
};

Blockly.Blocks['grow_sprite'] = {
  init: function () {
    this.appendDummyInput().appendField(
      new Blockly.FieldLabelSerializable('growing by'),
      'grow sprite value'
    );
    this.appendValueInput('NAME').setCheck(null);
    this.setInputsInline(true);
    this.setOutput(true, null);
    this.setColour(135);
    this.setTooltip('Grow sprite by a factor ');
    this.setHelpUrl('');
  },
};

Blockly.Blocks['shrink_sprite'] = {
  init: function () {
    this.appendDummyInput().appendField(
      new Blockly.FieldLabelSerializable('shrinking by'),
      'shrink sprite value'
    );
    this.appendValueInput('NAME').setCheck(null);
    this.setInputsInline(true);
    this.setOutput(true, null);
    this.setColour(135);
    this.setTooltip('Shrink sprite by a factor');
    this.setHelpUrl('');
  },
};

/* Event and Timers */

Blockly.Blocks['when_run_game'] = {
  init: function () {
    this.appendDummyInput().appendField('When run');
    this.appendStatementInput('NAME').setCheck(null);
    this.setColour(270);
    this.setTooltip('');
    this.setHelpUrl('');
  },
};

Blockly.Blocks['when_clicked'] = {
  init: function () {
    this.appendDummyInput()
      .appendField(new Blockly.FieldLabelSerializable('when'), 'when')
      .appendField(new Blockly.FieldTextInput('name'), 'entity name')
      .appendField(new Blockly.FieldLabelSerializable('is clicked'), 'NAME');
    this.appendStatementInput('NAME').setCheck(null);
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(270);
    this.setTooltip('when entity is clicked do what');
    this.setHelpUrl('');
  },
};

Blockly.Blocks['when_button_pressed'] = {
  init: function () {
    this.appendDummyInput()
      .appendField(new Blockly.FieldLabelSerializable('when'), 'when')
      .appendField(
        new Blockly.FieldDropdown([
          ['left', '37'],
          ['right', '39'],
          ['up', '38'],
          ['down', '40'],
        ]),
        'button option'
      )
      .appendField(
        new Blockly.FieldLabelSerializable('button pressed'),
        'NAME'
      );
    this.appendStatementInput('NAME').setCheck(null);
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(270);
    this.setTooltip('when button pressed do what');
    this.setHelpUrl('');
  },
};

Blockly.Blocks['when_sprite_touches_surface'] = {
  init: function () {
    this.appendDummyInput()
      .appendField(new Blockly.FieldLabelSerializable('when'), 'when')
      .appendField(new Blockly.FieldTextInput('sprite name'), 'sprite 1')
      .appendField(new Blockly.FieldLabelSerializable('touches'), 'touches')
      .appendField(
        new Blockly.FieldDropdown([
          ['top edge', 'top'],
          ['bottom edge', 'down'],
          ['left edge', 'left'],
          ['right edge', 'right'],
        ]),
        'surface name'
      );
    this.appendStatementInput('NAME').setCheck(null);
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(270);
    this.setTooltip('');
    this.setHelpUrl('');
  },
};

Blockly.Blocks['when_group_touches_edge'] = {
  init: function () {
    this.appendDummyInput()
      .appendField('when')
      .appendField(new Blockly.FieldTextInput('group'), 'group_name')
      .appendField('touches')
      .appendField(
        new Blockly.FieldDropdown([
          ['left edge', 'left'],
          ['right edge', 'right'],
          ['top edge', 'top'],
          ['bottom edge', 'down'],
        ]),
        'edge'
      );
    this.appendDummyInput()
      .appendField('get touched sprite')
      .appendField(new Blockly.FieldVariable('sprite'), 'touched_sprite');
    this.appendStatementInput('NAME').setCheck(null);
    this.setNextStatement(true, null);
    this.setColour(270);
    this.setTooltip('');
    this.setHelpUrl('');
  },
};

Blockly.Blocks['when_sprite_touches'] = {
  init: function () {
    this.appendDummyInput()
      .appendField(new Blockly.FieldLabelSerializable('when'), 'when')
      .appendField(new Blockly.FieldTextInput('sprite name'), 'sprite 1')
      .appendField(new Blockly.FieldLabelSerializable('touches'), 'touches')
      .appendField(new Blockly.FieldTextInput('sprite name'), 'sprite 2');
    this.appendStatementInput('NAME').setCheck(null);
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(270);
    this.setTooltip('when sprite interacts with another');
    this.setHelpUrl('');
  },
};

Blockly.Blocks['when_sprite_touches_group'] = {
  init: function () {
    this.appendDummyInput()
      .appendField('When sprite')
      .appendField(new Blockly.FieldTextInput('name'), 'sprite')
      .appendField('touches group')
      .appendField(new Blockly.FieldTextInput('name'), 'group');
    this.appendDummyInput()
      .appendField('get touched sprite in')
      .appendField(new Blockly.FieldVariable('sprite'), 'touched_sprite');
    this.appendStatementInput('NAME').setCheck(null);
    this.setColour(270);
    this.setTooltip('');
    this.setHelpUrl('');
  },
};

Blockly.Blocks['when_group_touches_group'] = {
  init: function () {
    this.appendDummyInput()
      .appendField('when')
      .appendField(new Blockly.FieldTextInput('group'), 'group1')
      .appendField('touches')
      .appendField(new Blockly.FieldTextInput('group'), 'group2');
    this.appendDummyInput()
      .appendField('get touched sprites in ')
      .appendField(new Blockly.FieldVariable('s1'), 's1')
      .appendField(new Blockly.FieldVariable('s2'), 's2');
    this.appendStatementInput('NAME').setCheck(null);
    this.setColour(270);
    this.setTooltip('');
    this.setHelpUrl('');
  },
};

Blockly.Blocks['when_timer_starts'] = {
  init: function () {
    this.appendDummyInput()
      .appendField('when ')
      .appendField(new Blockly.FieldTextInput('timer name'), 'NAME')
      .appendField('starts');
    this.appendStatementInput('NAME').setCheck(null);
    this.setColour(270);
    this.setTooltip('');
    this.setHelpUrl(null);
  },
};

Blockly.Blocks['create_time_interval'] = {
  init: function () {
    this.appendDummyInput()
      .appendField(
        new Blockly.FieldLabelSerializable('Create timer'),
        'create timer'
      )
      .appendField(new Blockly.FieldTextInput('timer_name'), 'timer_name');
    this.appendValueInput('interval count')
      .setCheck(null)
      .appendField(
        new Blockly.FieldLabelSerializable('with interval'),
        'interval'
      );
    this.setInputsInline(true);
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(270);
    this.setTooltip('');
    this.setHelpUrl('');
  },
};

Blockly.Blocks['start_timer'] = {
  init: function () {
    this.appendDummyInput()
      .appendField('start timer ')
      .appendField(new Blockly.FieldTextInput('name'), 'NAME');
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(270);
    this.setTooltip('');
    this.setHelpUrl('');
  },
};

Blockly.Blocks['pause_timer'] = {
  init: function () {
    this.appendDummyInput()
      .appendField('pause timer')
      .appendField(new Blockly.FieldTextInput('name'), 'NAME');
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(270);
    this.setTooltip('');
    this.setHelpUrl('');
  },
};

Blockly.Blocks['set_timer_property'] = {
  init: function () {
    this.appendDummyInput()
      .appendField('set timer')
      .appendField(new Blockly.FieldTextInput('name'), 'NAME')
      .appendField('property')
      .appendField(
        new Blockly.FieldDropdown([
          ['delay', 'delay'],
          ['interval', 'interval'],
        ]),
        'prop'
      )
      .appendField('to')
      .appendField(new Blockly.FieldNumber(0), 'val');
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(270);
    this.setTooltip('');
    this.setHelpUrl('');
  },
};

Blockly.Blocks['wait'] = {
  init: function () {
    this.appendDummyInput()
      .appendField('wait for')
      .appendField(new Blockly.FieldNumber(0), 'sec');
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(270);
    this.setTooltip('');
    this.setHelpUrl('');
  },
};

Blockly.Blocks['stop_timer'] = {
  init: function () {
    this.appendDummyInput()
      .appendField('stop timer')
      .appendField(new Blockly.FieldTextInput('timer name'), 'timer name');
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(270);
    this.setTooltip('Stop the created timer');
    this.setHelpUrl('');
  },
};

/* Text */

Blockly.Blocks['text1'] = {
  init: function () {
    this.appendDummyInput().appendField(
      new Blockly.FieldTextInput('text'),
      'string1'
    );
    this.setOutput(true, null);
    this.setColour(230);
    this.setTooltip('');
    this.setHelpUrl('');
  },
};

Blockly.Blocks['create_text_input'] = {
  init: function () {
    this.appendValueInput('name')
      .setCheck(null)
      .appendField(
        new Blockly.FieldLabelSerializable('draw text with name'),
        'text'
      );

    this.appendValueInput('initialText').appendField(
      new Blockly.FieldLabelSerializable('with text'),
      'text input'
    );
    this.appendValueInput('x location')
      .setCheck(null)
      .appendField(
        new Blockly.FieldLabelSerializable('at x position'),
        'x location'
      );
    this.appendValueInput('y location')
      .setCheck(null)
      .appendField(
        new Blockly.FieldLabelSerializable('at y position'),
        'y location'
      );
    this.setInputsInline(false);
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(15);
    this.setTooltip('Create text box with given text input and location');
    this.setHelpUrl('');
  },
};

Blockly.Blocks['set_text_property'] = {
  init: function () {
    this.appendDummyInput().appendField('set text');
    this.appendValueInput('sprite name').setCheck(null);
    this.appendDummyInput()
      .appendField('property ')
      .appendField(
        new Blockly.FieldDropdown([
          ['text', 'Text'],
          ['x', 'X'],
          ['y', 'Y'],
          ['size', 'FontSize'],
          ['color', 'Color'],
        ]),
        'prop'
      )
      .appendField('to');
    this.appendValueInput('prop value').setCheck(null);
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(15);
    this.setTooltip('');
    this.setHelpUrl('');
  },
};
Blockly.Blocks['get_text_as_number'] = {
  init: function () {
    this.appendDummyInput()
      .appendField('Get text ')
      .appendField(new Blockly.FieldTextInput('text name'), 'NAME')
      .appendField('as number');
    this.setOutput(true, null);
    this.setColour(15);
    this.setTooltip('');
    this.setHelpUrl('');
  },
};

Blockly.Blocks['get_text_property'] = {
  init: function () {
    this.appendDummyInput().appendField('get sprite');
    this.appendValueInput('sprite name').setCheck(null);
    this.appendDummyInput()
      .appendField('property')
      .appendField(
        new Blockly.FieldDropdown([
          ['text', 'text'],
          ['x', 'x'],
          ['y', 'y'],
          ['size', 'size'],
          ['color', 'color'],
        ]),
        'prop'
      );
    this.setOutput(true, null);
    this.setColour(15);
    this.setTooltip('');
    this.setHelpUrl('');
  },
};

/* Buttons */

Blockly.Blocks['create_button'] = {
  init: function () {
    this.appendDummyInput()
      .appendField(
        new Blockly.FieldLabelSerializable('create button'),
        'create button'
      )
      .appendField(new Blockly.FieldTextInput('button name'), 'button name');
    this.appendDummyInput()
      .appendField(
        new Blockly.FieldLabelSerializable('with width'),
        'with width'
      )
      .appendField(new Blockly.FieldNumber(0, 0), 'width')
      .appendField(
        new Blockly.FieldLabelSerializable('with height'),
        'with height'
      )
      .appendField(new Blockly.FieldNumber(0, 0), 'height');
    this.appendValueInput('X position')
      .setCheck(null)
      .appendField(
        new Blockly.FieldLabelSerializable('at X positon'),
        'at X positon'
      );
    this.appendValueInput('Y position')
      .setCheck(null)
      .appendField(
        new Blockly.FieldLabelSerializable('at Y position'),
        'at Y position'
      );
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(30);
    this.setTooltip('Create button with given width and height');
    this.setHelpUrl('');
  },
};

Blockly.Blocks['set_button_colour'] = {
  init: function () {
    this.appendValueInput('NAME')
      .setCheck(null)
      .appendField(
        new Blockly.FieldLabelSerializable('set colour for'),
        'set button colour'
      )
      .appendField(new Blockly.FieldTextInput('button name'), 'button name')
      .appendField(new Blockly.FieldLabelSerializable('to'), 'to');
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(30);
    this.setTooltip('Set button colour');
    this.setHelpUrl('');
  },
};

Blockly.Blocks['set_button_text'] = {
  init: function () {
    this.appendDummyInput()
      .appendField(
        new Blockly.FieldLabelSerializable('set text for'),
        'set text for'
      )
      .appendField(new Blockly.FieldTextInput('button name'), 'button name')
      .appendField(new Blockly.FieldLabelSerializable('as'), 'as')
      .appendField(new Blockly.FieldTextInput('text input'), 'text input');
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(30);
    this.setTooltip('Set button text');
    this.setHelpUrl('');
  },
};

Blockly.Blocks['set_button_text_colour'] = {
  init: function () {
    this.appendValueInput('NAME')
      .setCheck(null)
      .appendField(
        new Blockly.FieldLabelSerializable('set text colour of'),
        'set text colour'
      )
      .appendField(new Blockly.FieldTextInput('button name'), 'button name');
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(30);
    this.setTooltip('Set button text colour');
    this.setHelpUrl('');
  },
};

Blockly.Blocks['set_button_text_size'] = {
  init: function () {
    this.appendValueInput('NAME')
      .setCheck(null)
      .appendField(
        new Blockly.FieldLabelSerializable('set text size of'),
        'set text size'
      )
      .appendField(new Blockly.FieldTextInput('button name'), 'button name')
      .appendField(new Blockly.FieldLabelSerializable('to'), 'to');
    this.setInputsInline(true);
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(30);
    this.setTooltip('Set button text size');
    this.setHelpUrl('');
  },
};
Blockly.Blocks['button_visibility'] = {
  init: function () {
    this.appendDummyInput()
      .appendField('set')
      .appendField(new Blockly.FieldTextInput('button name'), 'NAME')
      .appendField('visibility to')
      .appendField(
        new Blockly.FieldDropdown([
          ['true', 'true'],
          ['false', 'false'],
        ]),
        'val'
      );
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(30);
    this.setTooltip('');
    this.setHelpUrl('');
  },
};

Blockly.Blocks['setter_block_screen'] = {
  init: function () {
    this.appendValueInput('set screen')
      .setCheck(null)
      .setAlign(Blockly.ALIGN_RIGHT)
      .appendField(new Blockly.FieldLabelSerializable('using'), 'using')
      .appendField(
        new Blockly.FieldDropdown([
          ['button1', 'button1'],
          ['button2', 'button2'],
          ['button3', 'button3'],
        ]),
        'set_button options'
      )
      .appendField('set')
      .appendField(
        new Blockly.FieldDropdown([
          ['Background Color', 'Background Color'],
          ['Text', 'Text'],
          ['Text Color', 'Text Color'],
          ['Font Size', 'Fsize'],
          ['Font Bold', 'FBold'],
          ['Font Italic', 'FItalic'],
        ]),
        'button_properties'
      )
      .appendField('to');
    this.setInputsInline(false);
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(90);
    this.setTooltip('Set Screen properties');
    this.setHelpUrl('');
  },
};

/* Color  */
Blockly.Blocks['color_select'] = {
  init: function () {
    this.appendDummyInput().appendField(
      new Blockly.FieldColour('#ff0000'),
      'color'
    );
    this.setOutput(true, null);
    this.setColour(230);
    this.setTooltip('');
    this.setHelpUrl('');
  },
};

Blockly.Blocks['transparent'] = {
  init: function () {
    this.appendDummyInput().appendField('transparent');
    this.setOutput(true, null);
    this.setColour(230);
    this.setTooltip('');
    this.setHelpUrl('');
  },
};

/* Math11 */

Blockly.Blocks['number'] = {
  init: function () {
    this.appendDummyInput().appendField(new Blockly.FieldNumber(0), 'NAME');
    this.setOutput(true, null);
    this.setColour(230);
    this.setTooltip('');
    this.setHelpUrl('');
  },
};

Blockly.Blocks['boolean'] = {
  init: function () {
    this.appendDummyInput().appendField(
      new Blockly.FieldTextInput('true'),
      'bool'
    );
    this.setInputsInline(false);
    this.setOutput(true, 'Boolean');
    this.setColour(230);
    this.setTooltip('');
    this.setHelpUrl('');
  },
};

// Blockly.Blocks['change_background1'] = {
//   init: function() {
//     this.appendValueInput("change_background")
//         .setCheck(null)
//         .appendField("change background to")
//         .appendField(new Blockly.FieldDropdown([["aquatic world","aqua"], ["forest world","forest"], ["urban world","urban"]]), "change_options");
//     this.setPreviousStatement(true, null);
//     this.setColour(230);
//  this.setTooltip("Change background world to");
//  this.setHelpUrl("");
//   }
// };

// Blockly.Blocks['create_sprite_group1'] = {
//   init: function() {
//     this.appendDummyInput()
//         .appendField("create sprite group")
//         .appendField(new Blockly.FieldDropdown([["main fish","main fish"], ["small fish","small fish"], ["big fish","big fish"]]), "sprite group options");
//     this.appendDummyInput()
//         .appendField("with group name as")
//         .appendField(new Blockly.FieldTextInput("default"), "NAME");
//     this.setPreviousStatement(true, null);
//     this.setNextStatement(true, null);
//     this.setColour(230);
//  this.setTooltip("Create Sprite group of your chosen type");
//  this.setHelpUrl("");
//   }
// };

// Blockly.Blocks['create_timer1'] = {
//   init: function() {
//     this.appendDummyInput()
//         .appendField("start timer");
//     this.appendDummyInput()
//         .appendField("with name")
//         .appendField(new Blockly.FieldTextInput(""), "NAME");
//     this.appendDummyInput()
//         .appendField("with start time")
//         .appendField(new Blockly.FieldDropdown([["0","20"], ["5","15"], ["10","10"], ["15","5"], ["20","0"]]), "start time")
//         .appendField(new Blockly.FieldDropdown([["sec","sec"]]), "time stamp");
//     this.appendDummyInput()
//         .appendField("with end time")
//         .appendField(new Blockly.FieldDropdown([["0","20"], ["5","15"], ["10","10"], ["15","5"], ["20","0"], ["Infinity","Infinity"]]), "end time")
//         .appendField(new Blockly.FieldDropdown([["sec","sec"], ["min","min"]]), "end time stamp");
//     this.appendDummyInput()
//         .appendField("set time triggers every")
//         .appendField(new Blockly.FieldDropdown([["0","20"], ["5","15"], ["10","10"], ["15","5"], ["20","0"]]), "trigger time")
//         .appendField(new Blockly.FieldDropdown([["sec","sec"]]), "trig time stamp");
//     this.setPreviousStatement(true, null);
//     this.setNextStatement(true, null);
//     this.setColour(230);
//  this.setTooltip("");
//  this.setHelpUrl("");
//   }
// };

// Blockly.Blocks['sprite_behaviours1'] = {
//   init: function() {
//     this.appendDummyInput()
//         .appendField("Sprite Group Name")
//         .appendField(new Blockly.FieldDropdown([["monkey","monkey"], ["small fish","small fish"], ["big fish","big fish"]]), "Sprite group name");
//     this.appendValueInput("Behaviour input")
//         .setCheck(null)
//         .appendField("begins");
//     this.setPreviousStatement(true, null);
//     this.setNextStatement(true, null);
//     this.setColour(230);
//  this.setTooltip("");
//  this.setHelpUrl("");
//   }
// };

// Blockly.Blocks['move_around1'] = {
//   init: function() {
//     this.appendDummyInput()
//         .appendField("move")
//         .appendField(new Blockly.FieldDropdown([["left","left 1"], ["right","right 1"], ["up","up 1"], ["down","down 1"]]), "direction 1")
//         .appendField("to")
//         .appendField(new Blockly.FieldDropdown([["right","right 2"], ["left","left 2"], ["up","up 2"], ["down ","down 2"]]), "direction 2");
//     this.appendDummyInput()
//         .appendField(new Blockly.FieldLabelSerializable("with speed"), "with speed")
//         .appendField(new Blockly.FieldNumber(0, 0, 10), "speed input");
//     this.setOutput(true, null);
//     this.setColour(230);
//  this.setTooltip("");
//  this.setHelpUrl("");
//   }
// };

// Blockly.Blocks['scoring_block1'] = {
//   init: function() {
//     this.appendValueInput("NAME")
//         .setCheck(null)
//         .appendField("Increase Score by");
//     this.setInputsInline(true);
//     this.setPreviousStatement(true, null);
//     this.setNextStatement(true, null);
//     this.setColour(230);
//  this.setTooltip("Enter number of score to be increased");
//  this.setHelpUrl("");
//   }
// };

// Blockly.Blocks['if_conditonal1'] = {
//   init: function() {
//     this.appendDummyInput()
//         .appendField("if")
//         .appendField(new Blockly.FieldTextInput("sprite group"), "sprite 1")
//         .appendField("touches")
//         .appendField(new Blockly.FieldTextInput("sprite group"), "sprite 2");
//     this.appendStatementInput("NAME")
//         .setCheck(null);
//     this.setPreviousStatement(true, null);
//     this.setNextStatement(true, null);
//     this.setColour(230);
//  this.setTooltip("");
//  this.setHelpUrl("");
//   }
// };

// Blockly.Blocks['random_number1'] = {
//   init: function() {
//     this.appendDummyInput()
//         .appendField("random number between")
//         .appendField(new Blockly.FieldNumber(0, 0), "min")
//         .appendField("to")
//         .appendField(new Blockly.FieldNumber(0, 0), "max");
//     this.setOutput(true, null);
//     this.setColour(230);
//  this.setTooltip("");
//  this.setHelpUrl(null);
//   }
// };

// Blockly.Blocks['move_randomly_with_speed1'] = {
//   init: function() {
//     this.appendDummyInput()
//         .appendField(new Blockly.FieldLabelSerializable("move randomly with speed"), "move random")
//         .appendField(new Blockly.FieldNumber(0, 0, 10), "NAME");
//     this.setOutput(true, null);
//     this.setColour(230);
//  this.setTooltip("");
//  this.setHelpUrl("");
//   }
// };

// Blockly.Blocks['get_touched_sprite1'] = {
//   init: function() {
//     this.appendDummyInput()
//         .appendField(new Blockly.FieldLabelSerializable("get touched sprite in"), "get touched sprite function")
//         .appendField(new Blockly.FieldTextInput("sprite variable"), "dynamic sprite name");
//     this.setPreviousStatement(true, null);
//     this.setNextStatement(true, null);
//     this.setColour(230);
//  this.setTooltip("");
//  this.setHelpUrl("");
//   }
// };

// Blockly.Blocks['get_prop_of_sprite1'] = {
//   init: function() {
//     this.appendDummyInput()
//         .appendField(new Blockly.FieldLabelSerializable("get"), "get")
//         .appendField(new Blockly.FieldDropdown([["length","length"], ["breadth","breadth"], ["radius","radius"]]), "rectangle props")
//         .appendField(new Blockly.FieldLabelSerializable("of"), "of")
//         .appendField(new Blockly.FieldTextInput("sprite name"), "sprite name");
//     this.appendDummyInput()
//         .appendField(new Blockly.FieldLabelSerializable("in variable"), "in variable")
//         .appendField(new Blockly.FieldTextInput("variable name"), "variable name");
//     this.setPreviousStatement(true, null);
//     this.setNextStatement(true, null);
//     this.setColour(230);
//  this.setTooltip("");
//  this.setHelpUrl("");
//   }
// };

// Blockly.Blocks['set_text1'] = {
//   init: function() {
//     this.appendDummyInput()
//         .appendField(new Blockly.FieldLabelSerializable("set text for"), "text")
//         .appendField(new Blockly.FieldTextInput("text variable name"), "text variable");
//     this.appendDummyInput()
//         .appendField(new Blockly.FieldLabelSerializable("as"), "NAME")
//         .appendField(new Blockly.FieldTextInput("text input"), "text input");
//     this.appendValueInput("x location")
//         .setCheck(null)
//         .appendField(new Blockly.FieldLabelSerializable("at X location"), "x location");
//     this.appendValueInput("y location")
//         .setCheck(null)
//         .appendField(new Blockly.FieldLabelSerializable("at Y location"), "y location");
//     this.setPreviousStatement(true, null);
//     this.setNextStatement(true, null);
//     this.setColour(230);
//  this.setTooltip("");
//  this.setHelpUrl("");
//   }
// };

// Blockly.Blocks['get_text_value1'] = {
//   init: function() {
//     this.appendDummyInput()
//         .appendField(new Blockly.FieldLabelSerializable("get text value of"), "get text value")
//         .appendField(new Blockly.FieldTextInput("text input variable"), "text input variable");
//     this.appendDummyInput()
//         .appendField(new Blockly.FieldLabelSerializable("in text value variable"), "in text value")
//         .appendField(new Blockly.FieldTextInput("text value variable"), "text value");
//     this.setPreviousStatement(true, null);
//     this.setNextStatement(true, null);
//     this.setColour(230);
//  this.setTooltip("");
//  this.setHelpUrl("");
//   }
// };

// Blockly.Blocks['change_sprite_properties1'] = {
//   init: function() {
//     this.appendDummyInput()
//         .appendField(new Blockly.FieldLabelSerializable("change rectangle "), "change rectangle")
//         .appendField(new Blockly.FieldTextInput("rectangle name"), "rect name");
//     this.appendDummyInput()
//         .appendField(new Blockly.FieldLabelSerializable("set length to"), "set length");
//     this.appendValueInput("new length")
//         .setCheck(null);
//     this.appendDummyInput()
//         .appendField(new Blockly.FieldLabelSerializable("and set breadth to"), "NAME");
//     this.appendValueInput("new breadth")
//         .setCheck(null);
//     this.setInputsInline(true);
//     this.setPreviousStatement(true, null);
//     this.setNextStatement(true, null);
//     this.setColour(230);
//  this.setTooltip("");
//  this.setHelpUrl("");
//   }
// };

// Blockly.Blocks['change_rectangle_properties1'] = {
//   init: function() {
//     this.appendDummyInput()
//         .appendField(new Blockly.FieldLabelSerializable("change rectangle "), "change rectangle")
//         .appendField(new Blockly.FieldTextInput("rectangle name"), "rect name");
//     this.appendValueInput("new length")
//         .setCheck(null)
//         .appendField(new Blockly.FieldLabelSerializable("set new length to"), "set length");
//     this.appendValueInput("new breadth")
//         .setCheck(null)
//         .appendField(new Blockly.FieldLabelSerializable("and set new breadth to"), "NAME");
//     this.setPreviousStatement(true, null);
//     this.setNextStatement(true, null);
//     this.setColour(230);
//  this.setTooltip("");
//  this.setHelpUrl("");
//   }
// };

// Blockly.Blocks['change_circle_properties1'] = {
//   init: function() {
//     this.appendDummyInput()
//         .appendField(new Blockly.FieldLabelSerializable("change circle radius"), "change radius")
//         .appendField(new Blockly.FieldTextInput("circle name"), "radius value");
//     this.appendValueInput("new radius")
//         .setCheck(null)
//         .appendField(new Blockly.FieldLabelSerializable("set new radius to"), "NAME");
//     this.setPreviousStatement(true, null);
//     this.setNextStatement(true, null);
//     this.setColour(230);
//  this.setTooltip("");
//  this.setHelpUrl("");
//   }
// };

// Blockly.Blocks['variable_name1'] = {
//   init: function() {
//     this.appendDummyInput()
//         .appendField(new Blockly.FieldTextInput("variable name"), "NAME");
//     this.setOutput(true, null);
//     this.setColour(225);
//  this.setTooltip("Set variable name");
//  this.setHelpUrl("");
//   }
// };

// Blockly.Blocks['change_sprite_properties_by1'] = {
//   init: function() {
//     this.appendDummyInput()
//         .appendField(new Blockly.FieldLabelSerializable("change sprite"), "change sprite")
//         .appendField(new Blockly.FieldTextInput("sprite name"), "sprite name");
//     this.appendDummyInput()
//         .appendField(new Blockly.FieldDropdown([["width","width"], ["height","height"], ["X Position","X Position"], ["Y Positon","Y Position"]]), "properties");
//     this.appendValueInput("NAME")
//         .setCheck(null)
//         .appendField(new Blockly.FieldLabelSerializable("by"), "NAME");
//     this.setInputsInline(true);
//     this.setPreviousStatement(true, null);
//     this.setNextStatement(true, null);
//     this.setColour(0);
//  this.setTooltip("");
//  this.setHelpUrl("");
//   }
// };

// Blockly.Blocks['numerical_input_value1'] = {
//   init: function() {
//     this.appendDummyInput()
//         .appendField(new Blockly.FieldNumber(0), "numerical input value");
//     this.setOutput(true, null);
//     this.setColour(230);
//  this.setTooltip("");
//  this.setHelpUrl("");
//   }
// };

Blockly.Blocks['bounce'] = {
  init: function () {
    this.appendDummyInput().appendField('bounce');
    this.setOutput(true, null);
    this.setColour(135);
    this.setTooltip('');
    this.setHelpUrl(null);
  },
};

Blockly.Blocks['every_movement1'] = {
  init: function () {
    this.appendDummyInput().appendField(
      new Blockly.FieldLabelSerializable('stops every movement'),
      'stops every movement'
    );
    this.setOutput(true, null);
    this.setColour(135);
    this.setTooltip('');
    this.setHelpUrl('');
  },
};

Blockly.Blocks['text_color1'] = {
  init: function () {
    this.appendDummyInput().appendField(
      new Blockly.FieldColour('#ff0000'),
      'NAME'
    );
    this.setOutput(true, null);
    this.setColour(230);
    this.setTooltip('');
    this.setHelpUrl('');
  },
};

Blockly.Blocks['set_variable1'] = {
  init: function () {
    this.appendValueInput('NAME')
      .setCheck(null)
      .appendField(new Blockly.FieldLabelSerializable('set'), 'set')
      .appendField(new Blockly.FieldTextInput('variable name'), 'variable name')
      .appendField(new Blockly.FieldLabelSerializable('to'), 'to');
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(225);
    this.setTooltip('Set variable value');
    this.setHelpUrl('');
  },
};

Blockly.Blocks['get_variable_value1'] = {
  init: function () {
    this.appendDummyInput()
      .appendField(
        new Blockly.FieldLabelSerializable('get value of'),
        'get value of'
      )
      .appendField(
        new Blockly.FieldTextInput('variable name'),
        'variable name'
      );
    this.setOutput(true, null);
    this.setColour(225);
    this.setTooltip('Get value for variable');
    this.setHelpUrl('');
  },
};

Blockly.Blocks['text_value1'] = {
  init: function () {
    this.appendDummyInput().appendField(
      new Blockly.FieldTextInput('text input value'),
      'NAME'
    );
    this.setOutput(true, null);
    this.setColour(230);
    this.setTooltip('text input');
    this.setHelpUrl('');
  },
};

Blockly.Blocks['create_screen1'] = {
  init: function () {
    this.appendDummyInput()
      .appendField(
        new Blockly.FieldLabelSerializable('create screen'),
        'create screen'
      )
      .appendField(new Blockly.FieldTextInput('screen name'), 'screen name');
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(90);
    this.setTooltip('Create different game screens');
    this.setHelpUrl('');
  },
};

Blockly.Blocks['change_screens1'] = {
  init: function () {
    this.appendDummyInput()
      .appendField(new Blockly.FieldLabelSerializable('change'), 'change')
      .appendField(
        new Blockly.FieldTextInput('current screen name'),
        'screen name 1'
      )
      .appendField(new Blockly.FieldLabelSerializable('to'), 'to')
      .appendField(
        new Blockly.FieldTextInput('next screen name'),
        'screen name 2'
      );
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(90);
    this.setTooltip('change between different screens');
    this.setHelpUrl('');
  },
};

Blockly.Blocks['change_sprite1'] = {
  init: function () {
    this.appendDummyInput()
      .appendField('change sprite')
      .appendField(
        new Blockly.FieldDropdown([
          [
            {
              src: 'https://www.gstatic.com/codesite/ph/images/star_on.gif',
              width: 15,
              height: 15,
              alt: '*',
            },
            'monkey',
          ],
          [
            {
              src: 'https://www.gstatic.com/codesite/ph/images/star_on.gif',
              width: 15,
              height: 15,
              alt: '*',
            },
            'apple',
          ],
          [
            {
              src: 'https://www.gstatic.com/codesite/ph/images/star_on.gif',
              width: 15,
              height: 15,
              alt: '*',
            },
            'fairy',
          ],
          [
            {
              src: 'https://www.gstatic.com/codesite/ph/images/star_on.gif',
              width: 15,
              height: 15,
              alt: '*',
            },
            'astronaut',
          ],
        ]),
        'sprite options'
      );
    this.appendDummyInput()
      .appendField('with name')
      .appendField(new Blockly.FieldTextInput('sprite name'), 'sprite name')
      .appendField('to')
      .appendField(
        new Blockly.FieldDropdown([
          [
            {
              src: 'https://www.gstatic.com/codesite/ph/images/star_on.gif',
              width: 15,
              height: 15,
              alt: '*',
            },
            'monkey',
          ],
          [
            {
              src: 'https://www.gstatic.com/codesite/ph/images/star_on.gif',
              width: 15,
              height: 15,
              alt: '*',
            },
            'apple',
          ],
          [
            {
              src: 'https://www.gstatic.com/codesite/ph/images/star_on.gif',
              width: 15,
              height: 15,
              alt: '*',
            },
            'fairy',
          ],
          [
            {
              src: 'https://www.gstatic.com/codesite/ph/images/star_on.gif',
              width: 15,
              height: 15,
              alt: '*',
            },
            'astronaut',
          ],
        ]),
        'sprite options'
      );
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(0);
    this.setTooltip('Used to change existing sprite to new sprite');
    this.setHelpUrl('');
  },
};
