var assets = {
    1: {
        "background": [['Background', 'space_story_background.png']],
        "assets": [[{ "src": "/images/StoryLab/astrostory_0png.png", "width": 35, "height": 35, "alt": "*" }, "astrostory_0png.png"], [{ "src": "/images/StoryLab/astrostory_90.png", "width": 35, "height": 35, "alt": "*" }, "astrostory_90.png"], [{ "src": "/images/StoryLab/astrostory_180.png", "width": 35, "height": 35, "alt": "*" }, "astrostory_180.png"], [{ "src": "/images/StoryLab/astrostory_270.png", "width": 35, "height": 35, "alt": "*" }, "astrostory_270.png"], [{ "src": "/images/StoryLab/pinkgem_story.png", "width": 35, "height": 35, "alt": "*" }, "pinkgem_story.png"]]
    },
    2: {
        "background": [['Background', 'space_story_background2.png']],
        "assets": [[{ "src": "/images/StoryLab/astrostory_0png.png", "width": 35, "height": 35, "alt": "*" }, "astrostory_0png.png"], [{ "src": "/images/StoryLab/astrostory_90.png", "width": 35, "height": 35, "alt": "*" }, "astrostory_90.png"], [{ "src": "/images/StoryLab/astrostory_180.png", "width": 35, "height": 35, "alt": "*" }, "astrostory_180.png"], [{ "src": "/images/StoryLab/astrostory_270.png", "width": 35, "height": 35, "alt": "*" }, "astrostory_270.png"], [{ "src": "/images/StoryLab/bluegem_story.png", "width": 35, "height": 35, "alt": "*" }, "bluegem_story.png"], [{ "src": "/images/StoryLab/graygem_story.png", "width": 35, "height": 35, "alt": "*" }, "graygem_story.png"], [{ "src": "/images/StoryLab/treasure_chest-01.png", "width": 35, "height": 35, "alt": "*" }, "treasure_chest-01.png"]]
    },
    3: {
        "background": [['Background', 'space_story_background3.png']],
        "assets": [[{ "src": "/images/StoryLab/astrostory_0png.png", "width": 35, "height": 35, "alt": "*" }, "astrostory_0png.png"], [{ "src": "/images/StoryLab/astrostory_90.png", "width": 35, "height": 35, "alt": "*" }, "astrostory_90.png"], [{ "src": "/images/StoryLab/astrostory_180.png", "width": 35, "height": 35, "alt": "*" }, "astrostory_180.png"], [{ "src": "/images/StoryLab/astrostory_270.png", "width": 35, "height": 35, "alt": "*" }, "astrostory_270.png"], [{ "src": "/images/StoryLab/bluegem_story.png", "width": 35, "height": 35, "alt": "*" }, "bluegem_story.png"], [{ "src": "/images/StoryLab/graygem_story.png", "width": 35, "height": 35, "alt": "*" }, "graygem_story.png"], [{ "src": "/images/StoryLab/asteroid_story.png", "width": 35, "height": 35, "alt": "*" }, "asteroid_story.png"]]
    },
    4: {
        "background": [['Background', 'space_story_background4.png']],
        "assets": [[{ "src": "/images/StoryLab/astrostory_0png.png", "width": 35, "height": 35, "alt": "*" }, "astrostory_0png.png"], [{ "src": "/images/StoryLab/astrostory_90.png", "width": 35, "height": 35, "alt": "*" }, "astrostory_90.png"], [{ "src": "/images/StoryLab/astrostory_180.png", "width": 35, "height": 35, "alt": "*" }, "astrostory_180.png"], [{ "src": "/images/StoryLab/astrostory_270.png", "width": 35, "height": 35, "alt": "*" }, "astrostory_270.png"], [{ "src": "/images/StoryLab/purplegem_story.png", "width": 35, "height": 35, "alt": "*" }, "purplegem_story.png"], [{ "src": "/images/StoryLab/bluegem_story.png", "width": 35, "height": 35, "alt": "*" }, "bluegem_story.png"], [{ "src": "/images/StoryLab/graygem_story.png", "width": 35, "height": 35, "alt": "*" }, "graygem_story.png"], [{ "src": "/images/StoryLab/pinkgem_story.png", "width": 35, "height": 35, "alt": "*" }, "pinkgem_story.png"], [{ "src": "/images/StoryLab/rocket_story.png", "width": 35, "height": 35, "alt": "*" }, "rocket_story.png"], [{ "src": "/images/StoryLab/purplealien_story_0.png", "width": 35, "height": 35, "alt": "*" }, "purplealien_story_0.png"], [{ "src": "/images/StoryLab/purplealien_story_90.png", "width": 35, "height": 35, "alt": "*" }, "purplealien_story_90.png"], [{ "src": "/images/StoryLab/purplealien_story_180.png", "width": 35, "height": 35, "alt": "*" }, "purplealien_story_180.png"], [{ "src": "/images/StoryLab/purplealien_story_270.png", "width": 35, "height": 35, "alt": "*" }, "purplealien_story_270.png"]]
    }
}

export default assets;