import * as actionTypes from './actionTypes';
import api from '../../apis/config';

export const authStart = () => {
    return {
        type: actionTypes.AUTH_START
    }
};

export const authSuccess = (token, userId) => {
    return {
        type: actionTypes.AUTH_SUCCESS,
        token,
        userId
    }
}


export const authFailed = (error) => {
    return {
        type: actionTypes.AUTH_FAILED,
        error: error
    }
}


export const fetchUserInfoStart = () => {
    return {
        type: actionTypes.FETCH_USER_INFO_START
    }
};

export const fetchUserInfoSuccess = (userData) => {
    return {
        type: actionTypes.FETCH_USER_INFO_SUCCESS,
        userData
    }
}


export const fetchUserInfoFailed = (error) => {
    return {
        type: actionTypes.FETCH_USER_INFO_FAILED,
        error: error
    }
}

export const authLogout = () => {
    return {
        type: actionTypes.AUTH_LOGOUT
    }
}

export const auth = (username, password) => {
    return dispath => {
        dispath(authStart());
        api.post('auth/login', { username, password })
            .then(res => {
                if (res.status === 200) {
                    localStorage.setItem('token', res.data.token);
                    localStorage.setItem('userId', res.data.userId);
                    dispath(authSuccess(res.data.token, res.data.userId));


                } else {
                    dispath(authFailed(res.data.error));
                }
            })
            .catch(err => {
                console.log(err);
                dispath(authFailed(err));
            })
    }
}


export const registerSuccess = (userData) => {
    return {
        type: actionTypes.REGISTER_SUCCESS,
        userData
    }
}

export const resetPasswordSuccess = (userData) => {
    return {
        type: actionTypes.RESET_PASSWORD_SUCCESS,
        userData
    }
}

export const getPasswordLinkSuccess = (userData) => {
    return {
        type: actionTypes.RESET_PASSWORD_LINK_SUCCESS,
        userData
    }
}

export const passwordChangesSuccess = (userData) => {
    return {
        type: actionTypes.PASSWORD_CHANGE_SUCCESS,
        userData
    }
}


export const register = (firstname, lastname, email, phone, username, password) => {
    return dispatch => {
        dispatch(authStart());
        const authData = {
            firstname: firstname,
            lastname: lastname,
            phone: phone,
            email: email,
            password: password,
            username: username
        }
        api.post('/auth/register', authData)
            .then(response => {
                if (response.status === 200) {
                    dispatch(registerSuccess(response.data.data));
                    window.location.href = '/';
                } else {
                    dispatch(authFailed(response.data.error));
                }

            })
            .catch(err => {
                console.log(err);
                dispatch(authFailed(err));

            });
    }
}

export const resetPassword = (email) => {
    return dispatch => {
        console.log(email);

        dispatch(authStart());
        const authData = {
            email: email
        }
        api.post('/auth/reset', authData)
            .then(response => {
                if (response.status === 200) {
                    dispatch(resetPasswordSuccess(response.data))
                } else {
                    dispatch(authFailed(response.data.error));
                }
            })
            .catch(err => {
                console.log(err);
                dispatch(authFailed(err));

            });
    }
}


export const fetchUserInfo = (userId) => {
    return dispatch => {
        dispatch(fetchUserInfoStart());
        api.get('/auth/user/' + userId)
            .then(response => {
                if (response.status === 200) {
                    dispatch(fetchUserInfoSuccess(response.data))
                } else {
                    dispatch(fetchUserInfoFailed(response.data.error));
                }
            })
            .catch(err => {
                // console.log(err);
                dispatch(fetchUserInfoFailed(err));

            });
    }
}


export const resetPasswordLink = (url) => {
    return dispatch => {
        console.log(url);
        dispatch(authStart());
        api.get(url)
            .then(response => {
                if (response.status === 200) {
                    dispatch(getPasswordLinkSuccess(response.data))
                } else {
                    dispatch(authFailed(response.data.error));
                }
            })
            .catch(err => {
                console.log(err);
                dispatch(authFailed(err));

            });
    }
}

export const changePasswordEmail = (id, resetToken, password) => {
    return dispatch => {
        dispatch(authStart());
        const authData = {
            userId: id,
            resetToken: resetToken,
            password: password
        }
        api.post('/auth/new-password', authData)
            .then(response => {
                dispatch(passwordChangesSuccess(response.data))
            })
            .catch(err => {
                console.log(err);
                dispatch(authFailed(err));

            });
    }
}
