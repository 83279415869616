import * as Blockly from "blockly/core";

Blockly.Blocks['when_run'] = {
  init: function() {
    this.appendDummyInput()
        .appendField(new Blockly.FieldLabelSerializable("when run"), "when_run");
    this.appendStatementInput("all_maze_statements")
        .setCheck(null)
        .appendField(new Blockly.FieldLabelSerializable(""), "NAME");
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(230);
 this.setTooltip("");
 this.setHelpUrl("");
  }
};

Blockly.Blocks['transparent'] = {
  init: function() {
    this.appendDummyInput()
        .appendField("transparent");
    this.setOutput(true, null);
    this.setColour(230);
 this.setTooltip(null);
 this.setHelpUrl(null);
  }
};

Blockly.Blocks['set_maze_character'] = {
  init: function() {
    this.appendDummyInput()
        .appendField("set")
        .appendField(new Blockly.FieldDropdown([
          [{"src":`${process.env.PUBLIC_URL}/images/bee_90.png`,"width":15,"height":15,"alt":"*"},"bee"], 
          [{"src":`${process.env.PUBLIC_URL}/images/fairy_char_90.png`,"width":15,"height":15,"alt":"*"},"fairy_char"],
          [{"src":`${process.env.PUBLIC_URL}/images/monkey_char_90.png`,"width":15,"height":15,"alt":"*"},"monkey_char"],
          [{"src":`${process.env.PUBLIC_URL}/images/red_alien_char_90.png`,"width":15,"height":15,"alt":"*"},"red_alien_char"],
          [{"src":`${process.env.PUBLIC_URL}/images/astro_half_char_90.png`,"width":15,"height":15,"alt":"*"},"astro_half_char"]

        ]), "character")
        .appendField(new Blockly.FieldDropdown([["front","180"], ["back","0"], ["right","90"], ["left","270"]]), "direction")
        .appendField("as")
        .appendField(new Blockly.FieldDropdown([["char","character"]]), "variable")
        .appendField(new Blockly.FieldDropdown([["1","1"], ["2","2"], ["3","3"], ["4","4"]]), "number");
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(0);
 this.setTooltip("");
 this.setHelpUrl("");
  }
};
Blockly.Blocks['set_maze_obstacles'] = {
  init: function() {
    this.appendDummyInput()
        .appendField("set")
        .appendField(new Blockly.FieldDropdown([
          [{"src":`${process.env.PUBLIC_URL}/images/maze_obst_stone 1.png`,"width":15,"height":15,"alt":"*"},"maze_obst_stone 1"], 
          [{"src":`${process.env.PUBLIC_URL}/images/maze_obst_stone 2.png`,"width":15,"height":15,"alt":"*"},"maze_obst_stone 2"],
          [{"src":`${process.env.PUBLIC_URL}/images/maze_obst_stone 3.png`,"width":15,"height":15,"alt":"*"},"maze_obst_stone 3"],
          [{"src":`${process.env.PUBLIC_URL}/images/maze_obst_stone 4.png`,"width":15,"height":15,"alt":"*"},"maze_obst_stone 4"],
          [{"src":`${process.env.PUBLIC_URL}/images/maze_obst_whitestar.png`,"width":15,"height":15,"alt":"*"},"maze_obst_whitestar"],
          [{"src":`${process.env.PUBLIC_URL}/images/purple_gem.png`,"width":15,"height":15,"alt":"*"},"purple_gem"],
          [{"src":`${process.env.PUBLIC_URL}/images/maze_obst_yellowflower.png`,"width":15,"height":15,"alt":"*"},"maze_obst_yellowflower"],
          [{"src":`${process.env.PUBLIC_URL}/images/maze_obst_yellowstar.png`,"width":15,"height":15,"alt":"*"},"maze_obst_yellowstar"],
        ]), "obstacle")
        .appendField("as")
        .appendField(new Blockly.FieldDropdown([["obs","obstacle"]]), "variable")
        .appendField(new Blockly.FieldDropdown([["1","1"], ["2","2"], ["3","3"], ["4","4"]]), "number");
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(0);
 this.setTooltip("");
 this.setHelpUrl("");
  }
};



Blockly.Blocks['set_maze_path'] = {
  init: function() {
    this.appendDummyInput()
        .appendField("set")
        .appendField(new Blockly.FieldDropdown([
          [{"src":`${process.env.PUBLIC_URL}/images/dark_blue_tile.png`,"width":15,"height":15,"alt":"*"},"dark_blue_tile"], 
          [{"src":`${process.env.PUBLIC_URL}/images/dark_cream_tile.png`,"width":15,"height":15,"alt":"*"},"dark_cream_tile"],
          [{"src":`${process.env.PUBLIC_URL}/images/dark_pink_tile.png`,"width":15,"height":15,"alt":"*"},"dark_pink_tile"],
          [{"src":`${process.env.PUBLIC_URL}/images/light_blue_tile.png`,"width":15,"height":15,"alt":"*"},"light_blue_tile"],
          [{"src":`${process.env.PUBLIC_URL}/images/light_cream_tile.png`,"width":15,"height":15,"alt":"*"},"light_cream_tile"],
          [{"src":`${process.env.PUBLIC_URL}/images/light_pink_tile.png`,"width":15,"height":15,"alt":"*"},"light_pink_tile"]
          
        ]), "path")
        .appendField("as")
        .appendField(new Blockly.FieldDropdown([["path","path"]]), "variable")
        .appendField(new Blockly.FieldDropdown([["1","1"], ["2","2"], ["3","3"], ["4","4"]]), "number");
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(0);
 this.setTooltip("");
 this.setHelpUrl("");
  }
};

Blockly.Blocks['set_maze_condition'] = {
  init: function() {
    this.appendDummyInput()
        .appendField("set")
        .appendField(new Blockly.FieldDropdown([
          [{"src":`${process.env.PUBLIC_URL}/images/maze_cond_blue_question.png`,"width":15,"height":15,"alt":"*"},"maze_cond_blue_question"], 
          [{"src":`${process.env.PUBLIC_URL}/images/maze_cond_orange_question.png`,"width":15,"height":15,"alt":"*"},"maze_cond_orange_question"],
          [{"src":`${process.env.PUBLIC_URL}/images/maze_cond_yellow_question.png`,"width":15,"height":15,"alt":"*"},"maze_cond_yellow_question"],
          
        ]), "conditional")
        .appendField("as")
        .appendField(new Blockly.FieldDropdown([["con","condition"]]), "variable")
        .appendField(new Blockly.FieldDropdown([["1","1"], ["2","2"], ["3","3"], ["4","4"]]), "number");
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(0);
 this.setTooltip("");
 this.setHelpUrl("");
  }
};

Blockly.Blocks['set_maze_background'] = {
  init: function() {
    this.appendDummyInput()
        .appendField(new Blockly.FieldLabelSerializable("set"), "set")
        .appendField(new Blockly.FieldDropdown([
          [{"src":`${process.env.PUBLIC_URL}/images/maze_back_1.png`,"width":15,"height":15,"alt":"*"},"maze_back_1"], 
          [{"src":`${process.env.PUBLIC_URL}/images/maze_back_2.png`,"width":15,"height":15,"alt":"*"},"maze_back_2"],
          [{"src":`${process.env.PUBLIC_URL}/images/maze_back_3.png`,"width":15,"height":15,"alt":"*"},"maze_back_3"],
         
          
        ]), "background")
        .appendField(new Blockly.FieldLabelSerializable("as maze background"), "as maze background");
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(0);
 this.setTooltip("");
 this.setHelpUrl("");
  }
};

Blockly.Blocks['set_maze_row_input'] = {
  init: function() {
    this.appendDummyInput()
        .appendField("set row")
        .appendField(new Blockly.FieldDropdown([["1","1"], ["2","2"], ["3","3"], ["4","4"], ["5","5"], ["6","6"], ["7","7"], ["8","8"]]), "row_input");
    this.appendValueInput("column1")
        .setCheck(null);
    this.appendValueInput("column2")
        .setCheck(null);
    this.appendValueInput("column3")
        .setCheck(null);
    this.appendValueInput("column4")
        .setCheck(null);
    this.appendValueInput("column5")
        .setCheck(null);
    this.appendValueInput("column6")
        .setCheck(null);
    this.appendValueInput("column7")
        .setCheck(null);
    this.appendValueInput("column8")
        .setCheck(null);
    this.setInputsInline(true);
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(230);
 this.setTooltip("");
 this.setHelpUrl("");
  }
};

Blockly.Blocks['maze_input_type'] = {
  init: function() {
    this.appendDummyInput()
        .appendField(new Blockly.FieldDropdown([["char","character"],["path","path"], ["obs","obstacle"], ["con","condition"], ["end", "conditional_end"], ["score", "score"], ["collect", "collect"], ["dest", "destination"]]), "maze_input_option")
        .appendField(new Blockly.FieldDropdown([["1","1"], ["2","2"], ["3","3"], ["4","4"], ["5","5"]]), "numerical");
    this.setOutput(true, null);
    this.setColour(180);
 this.setTooltip("");
 this.setHelpUrl("");
  }
};

Blockly.Blocks['set_maze_condition_answers'] = {
  init: function() {
    this.appendDummyInput()
        .appendField("set")
        .appendField(new Blockly.FieldDropdown([
          [{"src":`${process.env.PUBLIC_URL}/images/maze_cond_carrot.png`,"width":15,"height":15,"alt":"*"},"maze_cond_carrot"],
          [{"src":`${process.env.PUBLIC_URL}/images/maze_cond_pink_flower.png`,"width":15,"height":15,"alt":"*"},"maze_cond_pink_flower"],
          [{"src":`${process.env.PUBLIC_URL}/images/bee_90.png`,"width":15,"height":15,"alt":"*"},"bee_90"],
          [{"src":`${process.env.PUBLIC_URL}/images/rabbit_char_90.png`,"width":15,"height":15,"alt":"*"},"rabbit_char_90"],
          [{"src":`${process.env.PUBLIC_URL}/images/maze_cond_beetroot.png`,"width":15,"height":15,"alt":"*"},"maze_cond_beetroot"]          ,
          [{"src":`${process.env.PUBLIC_URL}/images/honeycomb.png`,"width":15,"height":15,"alt":"*"},"honeycomb"]
        ]), "ends")
        .appendField("as")
        .appendField(new Blockly.FieldDropdown([["end", "conditional_end"]]), "variable")
        .appendField(new Blockly.FieldDropdown([["1","1"], ["2","2"], ["3","3"], ["4","4"]]), "number");
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(0);
 this.setTooltip("");
 this.setHelpUrl("");
  }
};

Blockly.Blocks['set_maze_collect'] = {
  init: function() {
    this.appendDummyInput()
        .appendField("set")
        .appendField(new Blockly.FieldDropdown([
          [{"src":`${process.env.PUBLIC_URL}/images/maze_func_apple.png`,"width":15,"height":15,"alt":"*"},"maze_func_apple"],
          [{"src":`${process.env.PUBLIC_URL}/images/maze_func_orange.png`,"width":15,"height":15,"alt":"*"},"maze_func_orange"]
      
        ]), "collect")
        .appendField("as")
        .appendField(new Blockly.FieldDropdown([["collect", "collect"]]), "variable")
        .appendField(new Blockly.FieldDropdown([["1","1"], ["2","2"], ["3","3"], ["4","4"]]), "number");
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(0);
 this.setTooltip("");
 this.setHelpUrl("");
  }
};

Blockly.Blocks['set_maze_score'] = {
  init: function() {
    this.appendDummyInput()
        .appendField("set")
        .appendField(new Blockly.FieldDropdown([
          [{"src":`${process.env.PUBLIC_URL}/images/apple_basket.png`,"width":15,"height":15,"alt":"*"},"apple_basket"],
          [{"src":`${process.env.PUBLIC_URL}/images/orange_basket.png`,"width":15,"height":15,"alt":"*"},"orange_basket"]
      
        ]), "score")
        .appendField("as")
        .appendField(new Blockly.FieldDropdown([["score", "score"]]), "variable")
        .appendField(new Blockly.FieldDropdown([["1","1"], ["2","2"], ["3","3"], ["4","4"]]), "number");
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(0);
 this.setTooltip("");
 this.setHelpUrl("");
  }
};

Blockly.Blocks['set_maze_destination'] = {
  init: function() {
    this.appendDummyInput()
        .appendField("set")
        .appendField(new Blockly.FieldDropdown([
          [{"src":`${process.env.PUBLIC_URL}/images/rocket.png`,"width":15,"height":15,"alt":"*"},"rocket"],
          [{"src":`${process.env.PUBLIC_URL}/images/unicorn_left.png`,"width":15,"height":15,"alt":"*"},"unicorn_left"]
      
        ]), "destination")
        .appendField("as")
        .appendField(new Blockly.FieldDropdown([["dest", "destination"]]), "variable")
        .appendField(new Blockly.FieldDropdown([["1","1"], ["2","2"], ["3","3"], ["4","4"]]), "number");
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(0);
 this.setTooltip("");
 this.setHelpUrl("");
  }
};


