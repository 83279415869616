/* eslint no-eval: 0 */

import React from 'react';
import _ from 'lodash';

import './StoryLabContainer.css';
import Header from '../../components/UI/Header/Header';
import BlocklyContainer from '../BlocklyContainer';
import BlocklyJS from 'blockly/javascript';
import RunButton from '../RunButton';
import assets from './StoryLab/assets';
import '../../blocks/BlockFunctions';
import levels from './StoryLab/levels';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/index';
import Story from './Story';

class StoryLabContainer extends React.Component {
    _keydownhandler = [];
    constructor(props) {
        super(props);
        this.totalLevel = Object.keys(levels);
        this.exerciseTitle = this.props.match.params.exerciseName;
        this.projectId = this.props.match.params.projectId;
        this.level = 1;
        this.BlocklyContainer = BlocklyContainer;
        this.sprite = {};
        this.storyCanvas = React.createRef();
    }

    state = {
        level: levels[1],
        assets: assets[1],
        reload: false,
        background: "",
        userProject: null
    };


    componentDidMount() {
        this.props.fetchProject(this.projectId);
        const canvas = this.storyCanvas.current;
        const ctx = canvas.getContext('2d');
        var story = new Story(ctx, '', 0, 0, 100, 100, this.state.level);
        var intervalId = setInterval(this.gameLoop, 10);
        this.setState({ intervalId: intervalId, ctx: ctx, story: story });
    }

    componentDidUpdate(prevProps) {
        window.onpopstate = e => {
            localStorage.setItem("ExerciseLevel", 1);
            window.location.reload();
        }
        if (_.isEqual(prevProps.userProject, this.props.userProject) === false) {
            this.setState({
                userProject: this.props.userProject
            })
        }
    }


    createSprite = (name, path, x, y, w = 100, h = 100) => {
        this.sprite[name] = new Story(this.state.ctx, path, x, y, w, h, this.state.level);
    }

    gameLoop = () => {
        if (this.state.background.length !== 0) {
            var background = new Image();
            background.src = "../../../images/" + this.state.background;
            this.state.ctx.drawImage(background, 0, 0, 400, 400);
        }
        // eval(this.state.level.initial_code);
        if (Object.keys(this.sprite).length !== 0) {
            Object.keys(this.sprite).forEach(t => {
                this.sprite[t].draw();
                this.sprite[t].update();
            })
        }

    }


    reset = () => {
        window.removeEventListener('keydown', this._keydownhandler[0]);
        if (this._keydownhandler.length > 1) {
            window.removeEventListener("keydown", this._keydownhandler[1]);
        }
        if (this._keydownhandler.length > 2) {
            window.removeEventListener("keydown", this._keydownhandler[2]);
        }
        if (this._keydownhandler.length > 3) {
            window.removeEventListener("keydown", this._keydownhandler[3]);
        }
        this._keydownhandler = [];
        this.sprite = {};
        Object.keys(this.sprite).forEach(s => {
            this.sprite[s].x = 0;
            this.sprite[s].y = 0;
            this.sprite[s].animation_list = {
                'north': 0,
                'east': 0,
                'west': 0,
                'south': 0,
                'g_scale_height': 0,
                'g_scale_width': 0,
                's_scale_height': 0,
                's_scale_width': 0
            };
            this.sprite[s].actions = [];
            this.sprite[s].target_x = 0;
            this.sprite[s].target_y = 0;
            this.sprite[s].width = 0;
            this.sprite[s].height = 0;
            this.sprite[s].dx = 0;
            this.sprite[s].dy = 0;
        });

    }

    submit = () => {
    }

    onKeyPressed = (keyType, direction, gap, speed, sprite_name) => {
        this._keydownhandler.push(this.keyDownHandler(keyType, direction, gap, speed, sprite_name));
        window.addEventListener("keydown", this._keydownhandler[0]);
        if (this._keydownhandler.length > 1) {
            window.addEventListener("keydown", this._keydownhandler[1]);
        }
        if (this._keydownhandler.length > 2) {
            window.addEventListener("keydown", this._keydownhandler[2]);
        }
        if (this._keydownhandler.length > 3) {
            window.addEventListener("keydown", this._keydownhandler[3]);
        }
    }



    onRunClickHandler = () => {
        this.reset();
        var code = BlocklyJS.workspaceToCode(this.BlocklyContainer.primaryWorkspace);
        console.log(code, this.sprite);
        eval(code);

    }




    keyDownHandler = (keyType, direction, gap, speed, sprite_name) => {

        return (e) => {

            if (keyType === "right") {
                if (e.key === "Right" || e.key === "ArrowRight") {
                    eval("this.sprite." + sprite_name + ".degree=" + Number(direction));
                    eval("this.sprite." + sprite_name + ".move_forward();");
                }
            }

            if (keyType === "left") {
                if (e.key === "Left" || e.key === "ArrowLeft") {
                    eval("this.sprite." + sprite_name + ".degree=" + Number(direction));
                    eval("this.sprite." + sprite_name + ".move_forward();");
                }
            }

            if (keyType === "up") {
                if (e.key === "Up" || e.key === "ArrowUp") {
                    eval("this.sprite." + sprite_name + ".degree=" + Number(direction));
                    eval("this.sprite." + sprite_name + ".move_forward();");
                }
            }

            if (keyType === "down") {
                if (e.key === "Down" || e.key === "ArrowDown") {
                    eval("this.sprite." + sprite_name + ".degree=" + Number(direction));
                    eval("this.sprite." + sprite_name + ".move_forward();");
                }
            }
        }



    }



    render() {

        // if (this.props.loading) {
        //     return <Spinner />
        // }


        return (
            <React.Fragment>
                <Header color="#F2F2F2" />
                <div className="container">
                    <div className="grid-maze">
                        <h2>{(this.state.userProject !== null) ? this.state.userProject.name : '...'}</h2><br />
                        <div className="preview">
                            <canvas ref={this.storyCanvas} width={400} height={400} style={{ 'border': "1px solid black" }} />
                            <br />
                            <div className="btnSection">
                                <div>
                                    <RunButton runClickHandler={this.onRunClickHandler.bind(this)} />
                                    <button onClick={this.reset} className="btn">Reset</button>
                                </div>
                                <div>
                                    <button onClick={this.submit} className="btn" style={{ backgroundColor: 'red' }}>Submit</button>
                                </div>
                            </div>
                        </div>
                        <BlocklyContainer initialXml={(this.props.userProject) ? this.props.userProject.xml : ' '} blocks={levels[1].blocks} projectId={this.props.match.params.projectId} />
                    </div>
                </div >
            </React.Fragment>
        )
    }
}

const mapDispatchToProps = dispatch => {
    return {
        fetchProject: (id) => dispatch(actions.fetchProject(id))
    }
}

const mapStateToProps = state => {
    return {
        userProject: state.projects.userProject
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(StoryLabContainer);