/**
 * @license
 * 
 * Copyright 2019 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

/**
 * @fileoverview Blockly React Component.
 * @author samelh@google.com (Sam El-Husseini)
 */

import React from 'react';
import { connect } from 'react-redux';
import Cookie from 'js-cookie';
import * as actions from '../../store/actions'
import './BlocklyComponent.css';

import Blockly from 'blockly/core';
import locale from 'blockly/msg/en';
import 'blockly/blocks';
import { Block } from '.';

Blockly.setLocale(locale);

class BlocklyComponent extends React.Component {

    componentDidMount() {
        // console.log('component did mount: Blockly Component')
        const { initialXml, children, ...rest } = this.props;
        this.primaryWorkspace = Blockly.inject(
            this.blocklyDiv,
            {
                toolbox: this.toolbox,
                trashcan: true,
                ...rest
            },
        );

        if (initialXml) {
            try{
                Blockly.Xml.domToWorkspace(Blockly.Xml.textToDom(initialXml), this.primaryWorkspace);
            }catch(e){
                console.log(e);
            }
        }

        this.primaryWorkspace.addChangeListener(this.onBlockChange);
    }

    componentDidUpdate(prevProps){
        // console.log('component did update: Blockly Component')
        if(this.props.initialXml !== null){
            if(prevProps.initialXml !== this.props.initialXml){
                try{
                    Blockly.Xml.domToWorkspace(Blockly.Xml.textToDom(this.props.initialXml), this.primaryWorkspace);
                }catch(e){
                    console.log(e);
                }
            }
        }
       
    }

    
    onBlockChange = () => {
        if(this.props.blockSave !== false){
            this.onSaveXml();
        }
       
    }

    onSaveXml = () => {
        var xml = Blockly.Xml.workspaceToDom(this.primaryWorkspace);
        var xml_text = Blockly.Xml.domToText(xml);
        const userId = Cookie.get('userId');
        // console.log(this.props.exerciseId, this.props.level);
        if(!this.props.projectId){
            this.props.saveXml(userId, this.props.exerciseId, this.props.level, xml_text);
        }else{
            
            this.props.saveProjectXml(this.props.projectId, xml_text, this.props.data);
        }
    }

    //to do: add onDataSave

    get workspace() {
        return this.primaryWorkspace;
    }

    setXml(xml) {
        Blockly.Xml.domToWorkspace(Blockly.Xml.textToDom(xml), this.primaryWorkspace);
    }

    render() {
        // console.log('component render: Blockly Component')
        const { children } = this.props;
        // console.log(children);

        return <React.Fragment>
            <div ref={e => this.blocklyDiv = e} id="blocklyDiv" />
            <xml xmlns="https://developers.google.com/blockly/xml" is="blockly" style={{ display: 'none' }} ref={(toolbox) => { this.toolbox = toolbox; }}>
                {children}
            </xml>
        </React.Fragment>;
    }
}

const mapDispatchToProps = (dispatch) => {
    return{
        saveXml: (userId, exerciseId, level, xml) => dispatch(actions.saveXml(userId, exerciseId, level, xml)),
        saveProjectXml: (id, xml, data) => dispatch(actions.saveProjectXml(id, xml, data))
    }
}


export default connect(null, mapDispatchToProps, null, { forwardRef: true })(BlocklyComponent);
