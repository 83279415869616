/* eslint no-eval: 0 */
/*eslint no-useless-concat: 0*/
/*eslint no-unused-vars: 0*/
/*eslint no-redeclare: 0*/
/*eslint array-callback-return: 0*/
import { Block } from 'blockly';
import * as Blockly from 'blockly/core';
import 'blockly/javascript';

/* World */

Blockly.JavaScript['set_background'] = function (block) {
  var dropdown_set_background = block.getFieldValue('set background');
  // TODO: Assemble JavaScript into code variable.
  // var code =
  //   'gameBuilder.changeBackgroundImage("' + dropdown_set_background + '");\n';

  // if (block.getRootBlock().toString().includes('When run')) {
  var code =
    // eslint-disable-next-line no-template-curly-in-string
    'preloadCode += "this.load.image(`background`, `/images/GameLab/' +
    dropdown_set_background +
    '.png`);"; \n createCode += "this.add.sprite(0, 0, `background`).setOrigin(0);"; \n';
  // }else {
  //   var code =
  // }

  return code;
};

Blockly.JavaScript['set_background_color'] = function (block) {
  var value_set_background_colour = Blockly.JavaScript.valueToCode(
    block,
    'set background colour',
    Blockly.JavaScript.ORDER_NONE
  );
  // TODO: Assemble JavaScript into code variable.
  var code =
    'gameBuilder.changeBackgroundColor(' + value_set_background_colour + ');\n';
  return code;
};

/* Sprites */

Blockly.JavaScript['create_sprite'] = function (block) {
  var dropdown_sprite_options = block.getFieldValue('sprite options');
  var text_sprite_name = block.getFieldValue('sprite name');
  var value_x_location = Blockly.JavaScript.valueToCode(
    block,
    'X location',
    Blockly.JavaScript.ORDER_NONE
  );
  var value_y_location = Blockly.JavaScript.valueToCode(
    block,
    'Y location',
    Blockly.JavaScript.ORDER_NONE
  );
  // TODO: Assemble JavaScript into code variable.
  // var code =
  //   'gameBuilder.drawSprite("' +
  //   text_sprite_name +
  //   '", ' +
  //   value_x_location +
  //   ', ' +
  //   value_y_location +
  //   ', "' +
  //   dropdown_sprite_options +
  //   '" );\n';

  var code =
    'gVar += "var ' +
    text_sprite_name +
    '; var dx_' +
    text_sprite_name +
    '= 0; var dy_' +
    text_sprite_name +
    ' = 0;";\n';
  code +=
    'preloadCode += "' +
    'this.load.image(`' +
    text_sprite_name +
    '`, `/images/GameLab/' +
    dropdown_sprite_options +
    '.png`);"; \n';
  code +=
    'createCode += "' +
    text_sprite_name +
    ' = this.physics.add.sprite(' +
    value_x_location +
    ',  ' +
    value_y_location +
    ' , `' +
    text_sprite_name +
    '`).setOrigin(0).setInteractive();"; \n';
  return code;
};

Blockly.JavaScript['set_sprite_properties'] = function (block) {
  var text_sprite_name = Blockly.JavaScript.valueToCode(
    block,
    'sprite name',
    Blockly.JavaScript.ORDER_NONE
  );
  var dropdown_properties = block.getFieldValue('properties');
  var value_name = Blockly.JavaScript.valueToCode(
    block,
    'NAME',
    Blockly.JavaScript.ORDER_NONE
  );
  // TODO: Assemble JavaScript into code variable.
  // var code =
  //   'gameBuilder.setSpriteProperty(' +
  //   text_sprite_name +
  //   ', "' +
  //   dropdown_properties +
  //   '", ' +
  //   value_name +
  //   ');\n';
  var code = '';
  if (block.getRootBlock().toString().includes('When run')) {
    code =
      'createCode += "' +
      eval(text_sprite_name) +
      '.' +
      dropdown_properties +
      '= ' +
      value_name +
      ';";\n';
  } else {
    code =
      eval(text_sprite_name) +
      '.' +
      dropdown_properties +
      '= ' +
      value_name +
      ';';
  }
  return code;
};

Blockly.JavaScript['get_sprite_properties'] = function (block) {
  var text_name = Blockly.JavaScript.valueToCode(
    block,
    'sprite name',
    Blockly.JavaScript.ORDER_NONE
  );
  var dropdown_property = block.getFieldValue('property');
  // TODO: Assemble JavaScript into code variable.
  // var code =
  //   'gameBuilder.getSpriteProperty(' +
  //   text_name +
  //   ', "' +
  //   dropdown_property +
  //   '")';

  var code = eval(text_name) + '.' + dropdown_property;

  // TODO: Change ORDER_NONE to the correct strength.
  return [code, Blockly.JavaScript.ORDER_NONE];
};

Blockly.JavaScript['hide_sprite'] = function (block) {
  var text_name = block.getFieldValue('NAME');
  // TODO: Assemble JavaScript into code variable.
  var code = '';
  if (block.getRootBlock().toString().includes('When run')) {
    code = 'createCode = "' + text_name + '.kill();";';
  } else {
    code = text_name + '.destroy();';
  }
  return code;
};

/* Sprite Groups */

Blockly.JavaScript['create_group'] = function (block) {
  var text_group_name = block.getFieldValue('group name');
  // TODO: Assemble JavaScript into code variable.

  // var code = 'gameBuilder.createGroup("' + text_group_name + '");\n';
  var code = 'gVar += "var ' + text_group_name + ';"; \n';
  code +=
    'createCode += "' + text_group_name + ' = this.physics.add.group();";\n';
  return code;
};

Blockly.JavaScript['add_sprite_to_group'] = function (block) {
  var dropdown_sprite_name = block.getFieldValue('sprite name');
  var text_group_name = block.getFieldValue('group name');
  var value_x_position = Blockly.JavaScript.valueToCode(
    block,
    'X position',
    Blockly.JavaScript.ORDER_NONE
  );
  var value_y_position = Blockly.JavaScript.valueToCode(
    block,
    'Y position',
    Blockly.JavaScript.ORDER_NONE
  );

  function randomInteger(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  var random_integer = randomInteger(1, 1000000);
  var code = '';
  if (block.getRootBlock().toString().includes('When run')) {
    code = 'gVar += "var ' + text_group_name + '_' + random_integer + ';"; \n';
    code +=
      'preloadCode += "this.load.image(`' +
      text_group_name +
      '_' +
      random_integer +
      '`, `' +
      '/images/GameLab/' +
      dropdown_sprite_name +
      '.png' +
      '`);";\n';
    code +=
      'createCode += "' +
      text_group_name +
      '_' +
      random_integer +
      ' = this.add.sprite(' +
      value_x_position +
      ', ' +
      value_y_position +
      ', `' +
      text_group_name +
      '_' +
      random_integer +
      '`).setOrigin(0).setInteractive();' +
      text_group_name +
      '.add(' +
      text_group_name +
      '_' +
      random_integer +
      ');"; \n';
  } else {
    code +=
      text_group_name +
      '.create(' +
      value_x_position +
      ',' +
      value_y_position +
      ',`' +
      text_group_name +
      '_img`);";';
  }
  return code;
};

Blockly.JavaScript['hide_group'] = function (block) {
  var text_group_name = block.getFieldValue('group name');
  // TODO: Assemble JavaScript into code variable.
  var code = 'gameBuilder.hideGroup("' + text_group_name + '");\n';
  return code;
};

Blockly.JavaScript['random_int'] = function (block) {
  var number_min = block.getFieldValue('min');
  var number_max = block.getFieldValue('max');
  // TODO: Assemble JavaScript into code variable.

  var code = 'randomIntFromInterval(' + number_min + ', ' + number_max + ')';
  // TODO: Change ORDER_NONE to the correct strength.
  return [code, Blockly.JavaScript.ORDER_NONE];
};

/* Drawings */

Blockly.JavaScript['create_rectangle'] = function (block) {
  var text_rectangle_name = block.getFieldValue('rectangle name');
  var number_width = block.getFieldValue('width');
  var number_height = block.getFieldValue('height');
  var value_x_location = Blockly.JavaScript.valueToCode(
    block,
    'x location',
    Blockly.JavaScript.ORDER_NONE
  );
  var value_y_location = Blockly.JavaScript.valueToCode(
    block,
    'y location',
    Blockly.JavaScript.ORDER_NONE
  );
  var value_sprite_color = Blockly.JavaScript.valueToCode(
    block,
    'sprite color',
    Blockly.JavaScript.ORDER_NONE
  );
  // TODO: Assemble JavaScript into code variable.
  // var code =
  //   'gameBuilder.drawRectangle("' +
  //   text_rectangle_name +
  //   '", ' +
  //   number_width +
  //   ', ' +
  //   number_height +
  //   ', ' +
  //   value_x_location +
  //   ', ' +
  //   value_y_location +
  //   ', ' +
  //   value_sprite_color +
  //   ');\n';

  var code = '';
  code = "gVar += 'var " + text_rectangle_name + ";';\n";
  if (block.getRootBlock().toString().includes('When run')) {
    code +=
      'createCode += "' +
      text_rectangle_name +
      '= this.add.rectangle(' +
      value_x_location +
      ',' +
      value_y_location +
      ',' +
      number_width +
      ',' +
      number_height +
      ',' +
      '0xff6699' +
      ');";\n';
    code +=
      'createCode += "this.physics.add.existing(' +
      text_rectangle_name +
      ');";';
  } else {
    code +=
      text_rectangle_name +
      '= this.add.rectangle(' +
      value_x_location +
      ',' +
      value_y_location +
      ',' +
      number_width +
      ',' +
      number_height +
      ',' +
      '0xff6699' +
      ');";\n';
    code += 'this.physics.add.existing(' + text_rectangle_name + ')';
  }
  return code;
};

Blockly.JavaScript['create_circle'] = function (block) {
  var text_sprite_name = block.getFieldValue('sprite name');
  var number_radius = block.getFieldValue('radius');
  var value_x_location = Blockly.JavaScript.valueToCode(
    block,
    'x location',
    Blockly.JavaScript.ORDER_NONE
  );
  var value_y_location = Blockly.JavaScript.valueToCode(
    block,
    'y location',
    Blockly.JavaScript.ORDER_NONE
  );
  var value_sprite_color = Blockly.JavaScript.valueToCode(
    block,
    'sprite color',
    Blockly.JavaScript.ORDER_NONE
  );
  // TODO: Assemble JavaScript into code variable.
  // var code =
  //   'gameBuilder.drawCircle("' +
  //   text_sprite_name +
  //   '", ' +
  //   number_radius +
  //   ', ' +
  //   value_x_location +
  //   ',' +
  //   value_y_location +
  //   ', ' +
  //   value_sprite_color +
  //   ');\n';

  var code = '';
  code = "gVar += 'var " + text_sprite_name + ";';\n";
  if (block.getRootBlock().toString().includes('When run')) {
    code +=
      'createCode += "' +
      text_sprite_name +
      '= this.add.circle(' +
      value_x_location +
      ',' +
      value_y_location +
      ',' +
      number_radius +
      ',' +
      '0xff6699' +
      ');";\n';

    code +=
      'createCode += "this.physics.add.existing(' + text_sprite_name + ');";';
  } else {
    code +=
      text_sprite_name +
      '= this.physics.add.circle(' +
      value_x_location +
      ',' +
      value_y_location +
      ',' +
      number_radius +
      ',' +
      '0xff6699' +
      ');";\n';

    code += 'this.physics.add.existing(' + text_sprite_name + ')';
  }

  return code;
};

Blockly.JavaScript['set_shape_propeties'] = function (block) {
  var value_sprite_name = Blockly.JavaScript.valueToCode(
    block,
    'sprite name',
    Blockly.JavaScript.ORDER_NONE
  );
  var dropdown_property = block.getFieldValue('property');
  var value_prop_value = Blockly.JavaScript.valueToCode(
    block,
    'prop_value',
    Blockly.JavaScript.ORDER_NONE
  );
  // TODO: Assemble JavaScript into code variable.
  // var code =
  //   'gameBuilder.setShapeProperty(' +
  //   value_sprite_name +
  //   ', "' +
  //   dropdown_property +
  //   '", ' +
  //   value_prop_value +
  //   ');\n';

  var code = '';
  if (block.getRootBlock().toString().includes('When run')) {
    code =
      'createCode += "' +
      eval(value_sprite_name) +
      '.' +
      dropdown_property +
      '=' +
      value_prop_value +
      ';";\n';
  } else {
    code =
      eval(value_sprite_name) +
      '.' +
      dropdown_property +
      '=' +
      value_prop_value +
      ';';
  }
  return code;
};

Blockly.JavaScript['get_shape_property'] = function (block) {
  var value_sprite_name = Blockly.JavaScript.valueToCode(
    block,
    'sprite name',
    Blockly.JavaScript.ORDER_NONE
  );
  var dropdown_prop = block.getFieldValue('prop');
  // TODO: Assemble JavaScript into code variable.
  // var code =
  //   'gameBuilder.getShapeProperty(' +
  //   value_sprite_name +
  //   ', "' +
  //   dropdown_prop +
  //   '")';
  var code = eval(value_sprite_name) + '.' + dropdown_prop + '';
  return [code, Blockly.JavaScript.ORDER_NONE];
};

/* Sprite Movements */

Blockly.JavaScript['sprite_begins'] = function (block) {
  var value_spritename = Blockly.JavaScript.valueToCode(
    block,
    'spritename',
    Blockly.JavaScript.ORDER_NONE
  );
  var value_begins = Blockly.JavaScript.valueToCode(
    block,
    'begins',
    Blockly.JavaScript.ORDER_NONE
  );
  // TODO: Assemble JavaScript into code variable.
  // var code = 'self.spriteStarts(' + value_spritename + ', ' + value_begins + ');\n';
  // var code =
  //   'gameBuilder.sprites[' + value_spritename + '].' + value_begins + '; \n';
  var code = '';
  if (block.getRootBlock().toString().includes('When run')) {
    code =
      'createCode += "' + eval(value_spritename) + '.' + value_begins + '";';
  } else {
    code = eval(value_spritename) + '.' + value_begins + ';';
  }
  return code;
};

Blockly.JavaScript['stops_every_movement'] = function (block) {
  var value_name = Blockly.JavaScript.valueToCode(
    block,
    'sprite name',
    Blockly.JavaScript.ORDER_NONE
  );
  // TODO: Assemble JavaScript into code variable.
  var code = 'gameBuilder.spriteStopAllMovements(' + value_name + ');\n';
  return code;
};

Blockly.JavaScript['sprite_stops'] = function (block) {
  var text_sprite_name = block.getFieldValue('sprite name');
  var value_stops_movements = Blockly.JavaScript.valueToCode(
    block,
    'stops movements',
    Blockly.JavaScript.ORDER_ATOMIC
  );
  // TODO: Assemble JavaScript into code variable.
  var code =
    'gameBuilder.spriteStops("' +
    text_sprite_name +
    '", ' +
    value_stops_movements +
    ');\n';
  return code;
};

Blockly.JavaScript['sprite_group_begins'] = function (block) {
  var text_group_name = block.getFieldValue('group name');
  var value_name = Blockly.JavaScript.valueToCode(
    block,
    'NAME',
    Blockly.JavaScript.ORDER_NONE
  );
  // TODO: Assemble JavaScript into code variable.
  // var code =
  //   'gameBuilder.groups["' + text_group_name + '"].' + value_name + ';\n';
  // var code =
  //   '_.filter(gameBuilder.sprites, {"group": "' +
  //   text_group_name +
  //   '"}).forEach(g => { g.' +
  //   value_name +
  //   '; });\n';

  var code = '';
  if (block.getRootBlock().toString().includes('When run')) {
    code = 'createCode += "' + text_group_name + '.' + value_name + '";';
  } else {
    code = text_group_name + '.' + value_name + ';';
  }
  return code;
};

Blockly.JavaScript['group_bounce'] = function (block) {
  var text_name = block.getFieldValue('NAME');
  // TODO: Assemble JavaScript into code variable.
  var code =
    'updateCode += "window.Phaser.Actions.Call(' +
    text_name +
    '.getChildren(), function(' +
    text_name +
    ') {' +
    text_name +
    '.body.collideWorldBounds = true;' +
    text_name +
    '.body.bounce.set(1);});";';
  return code;
};

Blockly.JavaScript['jittering'] = function (block) {
  var value_name = Blockly.JavaScript.valueToCode(
    block,
    'NAME',
    Blockly.JavaScript.ORDER_NONE
  );
  // TODO: Assemble JavaScript into code variable.
  var code = 'jittering_block(' + value_name + ')';
  // TODO: Change ORDER_NONE to the correct strength.
  return [code, Blockly.JavaScript.ORDER_NONE];
};

Blockly.JavaScript['bounce'] = function (block) {
  // TODO: Assemble JavaScript into code variable.
  var code = 'bounce_block(direction)';

  // TODO: Change ORDER_NONE to the correct strength.
  return [code, Blockly.JavaScript.ORDER_NONE];
};

Blockly.JavaScript['bounce_from_walls'] = function (block) {
  var text_name = block.getFieldValue('NAME');
  // TODO: Assemble JavaScript into code variable.
  var code =
    'createCode += "' +
    text_name +
    '.body.collideWorldBounds = true;' +
    text_name +
    '.body.bounce.set(1);";\n';
  return code;
};

Blockly.JavaScript['group_stops'] = function (block) {
  var text_group_name = block.getFieldValue('group name');
  var value_stops = Blockly.JavaScript.valueToCode(
    block,
    'stops',
    Blockly.JavaScript.ORDER_ATOMIC
  );
  // TODO: Assemble JavaScript into code variable.
  var code =
    'gameBuilder.groupStops("' + text_group_name + '", ' + value_stops + ');\n';
  return code;
};

Blockly.JavaScript['move_randomly'] = function (block) {
  var value_name = Blockly.JavaScript.valueToCode(
    block,
    'NAME',
    Blockly.JavaScript.ORDER_NONE
  );

  var degree = randomIntFromInterval(0, 360);

  // TODO: Assemble JavaScript into code variable.
  var code =
    'setVelocity(' +
    value_name +
    ' * Math.cos((' +
    degree +
    ' * Math.PI) / 180), ' +
    value_name +
    ' * Math.sin((' +
    degree +
    ' * Math.PI) / 180));';

  // TODO: Change ORDER_NONE to the correct strength.
  return [code, Blockly.JavaScript.ORDER_NONE];
};
function randomIntFromInterval(min, max) {
  // min and max included
  return Math.floor(Math.random() * (max - min + 1) + min);
}

Blockly.JavaScript['move_left'] = function (block) {
  var dropdown_direction = block.getFieldValue('direction');
  var value_left_distance = Blockly.JavaScript.valueToCode(
    block,
    'left distance',
    Blockly.JavaScript.ORDER_NONE
  );
  var value_speed = Blockly.JavaScript.valueToCode(
    block,
    'speed',
    Blockly.JavaScript.ORDER_NONE
  );
  // TODO: Assemble JavaScript into code variable.
  var code =
    'move_block(' +
    dropdown_direction +
    ', ' +
    value_left_distance +
    ', ' +
    value_speed +
    ')';
  // TODO: Change ORDER_NONE to the correct strength.
  return [code, Blockly.JavaScript.ORDER_NONE];
};

Blockly.JavaScript['stop_all_movement'] = function (block) {
  // TODO: Assemble JavaScript into code variable.
  var code = 'gameBuilder.spriteStopAllMovements();\n';
  return code;
};

Blockly.JavaScript['grow_sprite'] = function (block) {
  var value_name = Blockly.JavaScript.valueToCode(
    block,
    'NAME',
    Blockly.JavaScript.ORDER_NONE
  );

  // var code = 'grow_block(' + value_name + ')';
  var code = 'setScale(' + value_name + ')';
  // TODO: Change ORDER_NOE to the correct strength.
  return [code, Blockly.JavaScript.ORDER_NONE];
};

Blockly.JavaScript['shrink_sprite'] = function (block) {
  var value_name = Blockly.JavaScript.valueToCode(
    block,
    'NAME',
    Blockly.JavaScript.ORDER_NONE
  );
  // TODO: Assemble JavaScript into code variable.
  var code = 'setScale(' + value_name + ')';

  // TODO: Change ORDER_NONE to the correct strength.
  return [code, Blockly.JavaScript.ORDER_NONE];
};

/* Events and Timers */

Blockly.JavaScript['when_run_game'] = function (block) {
  var statements_name = Blockly.JavaScript.statementToCode(block, 'NAME');
  // TODO: Assemble JavaScript into code variable.
  var code = statements_name;
  return code;
};

Blockly.JavaScript['when_clicked'] = function (block) {
  var text_entity_name = block.getFieldValue('entity name');
  var statements_name = Blockly.JavaScript.statementToCode(block, 'NAME');
  // TODO: Assemble JavaScript into code variable.
  // var code =
  //   'function ' +
  //   text_entity_name +
  //   '_clicked(){' +
  //   statements_name +
  //   '};gameBuilder.isObjectClick("' +
  //   text_entity_name +
  //   '",' +
  //   text_entity_name +
  //   '_clicked' +
  //   ');\n';
  // var code =
  //   'gameBuilder.buttons["' +
  //   text_entity_name +
  //   '"].setOnClick = true; gameBuilder.buttons["' +
  //   text_entity_name +
  //   '"].onClick = function () {' +
  //   statements_name +
  //   '};\n';

  var code =
    "createCode += '" +
    text_entity_name +
    '.on(`pointerdown`, function(pointer) {' +
    statements_name +
    "});';\n";
  return code;
};

Blockly.JavaScript['when_button_pressed'] = function (block) {
  var dropdown_button_option = block.getFieldValue('button option');
  var statements_name = Blockly.JavaScript.statementToCode(block, 'NAME');

  // var code =
  //   'document.addEventListener("keydown", function(e){ if(e.keyCode == "' +
  //   dropdown_button_option +
  //   '"){' +
  //   statements_name +
  //   ' } });';
  var code = '';
  if (dropdown_button_option === '37') {
    // code +=
    //   'updateCode += "if (key.left.isDown) { ' + statements_name + ' };";';
    code +=
      'createCode += "this.input.keyboard.on(`keydown-LEFT`, function(event) {' +
      statements_name +
      '});";\n';
  }

  if (dropdown_button_option === '39') {
    code +=
      'createCode += "this.input.keyboard.on(`keydown-RIGHT`, function(event) {' +
      statements_name +
      '});";\n';
  }

  if (dropdown_button_option === '38') {
    code +=
      'createCode += "this.input.keyboard.on(`keydown-UP`, function(event) {' +
      statements_name +
      '});";\n';
  }

  if (dropdown_button_option === '40') {
    code +=
      'createCode += "this.input.keyboard.on(`keydown-DOWN`, function(event) {' +
      statements_name +
      '});";\n';
  }

  return code;
};

Blockly.JavaScript['when_sprite_touches'] = function (block) {
  var text_sprite_1 = block.getFieldValue('sprite 1');
  var text_sprite_2 = block.getFieldValue('sprite 2');
  var statements_name = Blockly.JavaScript.statementToCode(block, 'NAME');
  // TODO: Assemble JavaScript into code variable.
  // var code =
  //   'function ' +
  //   text_sprite_1 +
  //   '_' +
  //   text_sprite_2 +
  //   '(){ ' +
  //   statements_name +
  //   ' };gameBuilder.sprite_touches_sprite("' +
  //   text_sprite_1 +
  //   '","' +
  //   text_sprite_2 +
  //   '", ' +
  //   text_sprite_1 +
  //   '_' +
  //   text_sprite_2 +
  //   ');\n';

  var code =
    'updateCode += "this.physics.overlap(' +
    text_sprite_1 +
    ',' +
    text_sprite_2 +
    ', function(' +
    text_sprite_1 +
    ',' +
    text_sprite_2 +
    ') {' +
    statements_name +
    '});";';
  return code;
};

Blockly.JavaScript['when_group_touches_edge'] = function (block) {
  var text_group_name = block.getFieldValue('group_name');
  var dropdown_edge = block.getFieldValue('edge');
  var variable_touched_sprite = Blockly.JavaScript.variableDB_.getName(
    block.getFieldValue('touched_sprite'),
    Blockly.Variables.NAME_TYPE
  );
  var statements_name = Blockly.JavaScript.statementToCode(block, 'NAME');
  // TODO: Assemble JavaScript into code variable.
  var code =
    'function ' +
    text_group_name +
    '_' +
    dropdown_edge +
    '(direction, ' +
    variable_touched_sprite +
    '){' +
    statements_name +
    '}; gameBuilder.group_touches_edge("' +
    text_group_name +
    '", "' +
    dropdown_edge +
    '", (direction, ' +
    variable_touched_sprite +
    ') => ' +
    text_group_name +
    '_' +
    dropdown_edge +
    '(direction, ' +
    variable_touched_sprite +
    '));\n';
  return code;
};

Blockly.JavaScript['when_sprite_touches_group'] = function (block) {
  var text_sprite = block.getFieldValue('sprite');
  var text_group = block.getFieldValue('group');
  var variable_touched_sprite = Blockly.JavaScript.variableDB_.getName(
    block.getFieldValue('touched_sprite'),
    Blockly.Variables.NAME_TYPE
  );
  var statements_name = Blockly.JavaScript.statementToCode(block, 'NAME');
  // TODO: Assemble JavaScript into code variable.
  // var code =
  //   'function ' +
  //   text_sprite +
  //   '_' +
  //   text_group +
  //   '(' +
  //   variable_touched_sprite +
  //   ', sprite13133){ ' +
  //   statements_name +
  //   ' };gameBuilder.sprite_touches_group("' +
  //   text_sprite +
  //   '","' +
  //   text_group +
  //   '", (' +
  //   variable_touched_sprite +
  //   ', sprite13133) => ' +
  //   text_sprite +
  //   '_' +
  //   text_group +
  //   '(' +
  //   variable_touched_sprite +
  //   ', sprite13133));\n';

  var code =
    'updateCode += "this.physics.overlap(' +
    text_sprite +
    ',' +
    text_group +
    ', function(' +
    text_sprite +
    ',' +
    text_group.replace(' ', '') +
    's' +
    ') {' +
    statements_name +
    '});";\n';
  return code;
};

Blockly.JavaScript['when_group_touches_group'] = function (block) {
  var text_group1 = block.getFieldValue('group1');
  var text_group2 = block.getFieldValue('group2');
  var variable_s1 = Blockly.JavaScript.variableDB_.getName(
    block.getFieldValue('s1'),
    Blockly.Variables.NAME_TYPE
  );
  var variable_s2 = Blockly.JavaScript.variableDB_.getName(
    block.getFieldValue('s2'),
    Blockly.Variables.NAME_TYPE
  );
  var statements_name = Blockly.JavaScript.statementToCode(block, 'NAME');
  // TODO: Assemble JavaScript into code variable.
  var code =
    'updateCode += "this.physics.overlap(' +
    text_group1 +
    ',' +
    text_group2 +
    ', function(' +
    text_group1.replace(' ', '') +
    's,' +
    text_group2.replace(' ', '') +
    's' +
    ') {' +
    statements_name +
    '});";\n';

  return code;
};

Blockly.JavaScript['when_timer_starts'] = function (block) {
  var text_name = block.getFieldValue('NAME');
  var statements_name = Blockly.JavaScript.statementToCode(block, 'NAME');
  // TODO: Assemble JavaScript into code variable.
  // var code = 'var ' + text_name + ' = setInterval(function(){ if(gameBuilder.isTimerStart("' + text_name + '")){ ' + statements_name + ' }}, gameBuilder.getInterval("' + text_name + '"));\n';
  var code =
    'createCode += "' +
    text_name +
    '.callback = function() { ' +
    statements_name +
    '};";\n';
  return code;
};

Blockly.JavaScript['create_time_interval'] = function (block) {
  var text_timer_name = block.getFieldValue('timer_name');
  var value_interval_count = Blockly.JavaScript.valueToCode(
    block,
    'interval count',
    Blockly.JavaScript.ORDER_NONE
  );
  // TODO: Assemble JavaScript into code variable.
  // var code =
  //   'gameBuilder.createTimer("' +
  //   text_timer_name +
  //   '", ' +
  //   parseInt(value_interval_count) * 1000 +
  //   ');\n';

  var code = '';

  if (block.getRootBlock().toString().includes('When run')) {
    code += 'gVar += "var ' + text_timer_name + ';";\n';
    code +=
      'createCode += "' +
      text_timer_name +
      ' = this.time.addEvent({ delay: ' +
      value_interval_count +
      ', callback: null, callbackScope: this, loop: true, paused: true });";\n';
  } else {
    code += 'gVar += "var ' + text_timer_name + ';";\n';
    code +=
      text_timer_name +
      ' = this.time.addEvent({ delay: ' +
      value_interval_count +
      ', callback: null, callbackScope: this, loop: true, paused: true });";\n';
  }
  return code;
};

Blockly.JavaScript['start_timer'] = function (block) {
  var text_name = block.getFieldValue('NAME');
  // TODO: Assemble JavaScript into code variable.
  var code = '';
  if (block.getRootBlock().toString().includes('When run')) {
    code = 'createCode += "' + text_name + '.paused = false;";\n';
  } else {
    code = text_name + '.paused = false;';
  }
  return code;
};

Blockly.JavaScript['pause_timer'] = function (block) {
  var text_name = block.getFieldValue('NAME');
  // TODO: Assemble JavaScript into code variable.
  var code = '';
  if (block.getRootBlock().toString().includes('When run')) {
    code = 'createCode += "' + text_name + '.paused = true;";\n';
  } else {
    code = text_name + '.paused = true;';
  }
  return code;
};

Blockly.JavaScript['set_timer_property'] = function (block) {
  var text_name = block.getFieldValue('NAME');
  var dropdown_prop = block.getFieldValue('prop');
  var number_val = block.getFieldValue('val');
  // TODO: Assemble JavaScript into code variable.
  var code =
    'gameBuilder.setTimerProperty("' +
    text_name +
    '", "' +
    dropdown_prop +
    '", ' +
    number_val +
    ');\n';
  return code;
};

Blockly.JavaScript['wait'] = function (block) {
  var number_sec = block.getFieldValue('sec');
  // TODO: Assemble JavaScript into code variable.
  var code = 'gameBuilder.sleep(' + number_sec * 1000 + ');\n';
  return code;
};

/* Text 99 */

Blockly.JavaScript['create_text_input'] = function (block) {
  var text_text_variable = Blockly.JavaScript.valueToCode(
    block,
    'name',
    Blockly.JavaScript.ORDER_NONE
  );
  var text_text_input = String(
    Blockly.JavaScript.valueToCode(
      block,
      'initialText',
      Blockly.JavaScript.ORDER_NONE
    )
  );

  var value_x_location = Blockly.JavaScript.valueToCode(
    block,
    'x location',
    Blockly.JavaScript.ORDER_NONE
  );
  var value_y_location = Blockly.JavaScript.valueToCode(
    block,
    'y location',
    Blockly.JavaScript.ORDER_NONE
  );
  // TODO: Assemble JavaScript into code variable.
  // var code =
  //   'gameBuilder.drawText(' +
  //   text_text_variable +
  //   ' , ' +
  //   text_text_input +
  //   ', ' +
  //   value_x_location +
  //   ', ' +
  //   value_y_location +
  //   ');\n';

  var code = '';
  console.log(text_text_input);
  code = "gVar += 'var " + eval(text_text_variable) + ";';\n";
  if (block.getRootBlock().toString().includes('When run')) {
    code +=
      'createCode += "' +
      eval(text_text_variable) +
      '= this.add.text(' +
      value_x_location +
      ',' +
      value_y_location +
      ',' +
      text_text_input +
      ');";\n';
  } else {
    code +=
      eval(text_text_variable) +
      '= this.add.text(' +
      value_x_location +
      ',' +
      value_y_location +
      ',' +
      text_text_input +
      ');";\n';
  }

  return code;
};

Blockly.JavaScript['set_text_property'] = function (block) {
  var value_sprite_name = Blockly.JavaScript.valueToCode(
    block,
    'sprite name',
    Blockly.JavaScript.ORDER_ATOMIC
  );
  var dropdown_prop = block.getFieldValue('prop');
  var value_prop_value = Blockly.JavaScript.valueToCode(
    block,
    'prop value',
    Blockly.JavaScript.ORDER_NONE
  );
  // TODO: Assemble JavaScript into code variable.
  // var code =
  //   'gameBuilder.setTextProperty(' +
  //   value_sprite_name +
  //   ', "' +
  //   dropdown_prop +
  //   '", ' +
  //   value_prop_value +
  //   ');\n';
  var code = '';
  if (block.getRootBlock().toString().includes('When run')) {
    code =
      'createCode += "' +
      eval(value_sprite_name) +
      '.set' +
      dropdown_prop +
      '(' +
      value_prop_value +
      ');";\n';
  } else {
    code =
      eval(value_sprite_name) +
      '.set' +
      dropdown_prop +
      '(' +
      value_prop_value +
      ');';
  }
  return code;
};

Blockly.JavaScript['get_text_property'] = function (block) {
  var value_sprite_name = Blockly.JavaScript.valueToCode(
    block,
    'sprite name',
    Blockly.JavaScript.ORDER_ATOMIC
  );
  var dropdown_prop = block.getFieldValue('prop');
  // TODO: Assemble JavaScript into code variable.
  // var code =
  //   'gameBuilder.getTextProperty(' +
  //   value_sprite_name +
  //   ', "' +
  //   dropdown_prop +
  //   '")';

  var code = eval(value_sprite_name) + '.' + dropdown_prop;
  return [code, Blockly.JavaScript.ORDER_NONE];
};

Blockly.JavaScript['get_text_as_number'] = function (block) {
  var text_name = block.getFieldValue('NAME');
  // TODO: Assemble JavaScript into code variable.
  var code = 'parseInt(' + text_name + '.text)';
  // TODO: Change ORDER_NONE to the correct strength.
  return [code, Blockly.JavaScript.ORDER_NONE];
};

/* Buttons */

Blockly.JavaScript['create_button'] = function (block) {
  var text_button_name = block.getFieldValue('button name');
  var number_width = block.getFieldValue('width');
  var number_height = block.getFieldValue('height');
  var value_x_position = Blockly.JavaScript.valueToCode(
    block,
    'X position',
    Blockly.JavaScript.ORDER_NONE
  );
  var value_y_position = Blockly.JavaScript.valueToCode(
    block,
    'Y position',
    Blockly.JavaScript.ORDER_NONE
  );
  // TODO: Assemble JavaScript into code variable.
  var code =
    'gameBuilder.drawButton("' +
    text_button_name +
    '", ' +
    number_width +
    ', ' +
    number_height +
    ', ' +
    value_x_position +
    ', ' +
    value_y_position +
    ');\n';
  return code;
};

Blockly.JavaScript['set_button_colour'] = function (block) {
  var text_button_name = block.getFieldValue('button name');
  var value_name = Blockly.JavaScript.valueToCode(
    block,
    'NAME',
    Blockly.JavaScript.ORDER_NONE
  );
  // TODO: Assemble JavaScript into code variable.
  var code =
    'gameBuilder.setButtonBgColor("' +
    text_button_name +
    '", ' +
    value_name +
    ');\n';
  return code;
};

Blockly.JavaScript['set_button_text'] = function (block) {
  var text_button_name = block.getFieldValue('button name');
  var text_text_input = block.getFieldValue('text input');
  // TODO: Assemble JavaScript into code variable.
  var code =
    'gameBuilder.setButtonText("' +
    text_button_name +
    '", "' +
    text_text_input +
    '");\n';
  return code;
};

Blockly.JavaScript['if_state'] = function (block) {
  var value_name = Blockly.JavaScript.valueToCode(
    block,
    'NAME',
    Blockly.JavaScript.ORDER_ATOMIC
  );
  var statements_state = Blockly.JavaScript.statementToCode(block, 'state');
  // TODO: Assemble JavaScript into code variable.
  var code = 'if (' + value_name + ') {' + statements_state + '};';
  return code;
};

Blockly.JavaScript['set_button_text_colour'] = function (block) {
  var text_button_name = block.getFieldValue('button name');
  var value_name = Blockly.JavaScript.valueToCode(
    block,
    'NAME',
    Blockly.JavaScript.ORDER_NONE
  );
  // TODO: Assemble JavaScript into code variable.
  var code =
    'gameBuilder.setButtonTextColor("' +
    text_button_name +
    '", ' +
    value_name +
    ');\n';
  return code;
};

Blockly.JavaScript['set_button_text_size'] = function (block) {
  var text_button_name = block.getFieldValue('button name');
  var value_name = Blockly.JavaScript.valueToCode(
    block,
    'NAME',
    Blockly.JavaScript.ORDER_NONE
  );
  // TODO: Assemble JavaScript into code variable.
  var code =
    'gameBuilder.setButtonTextSize("' +
    text_button_name +
    '",  ' +
    value_name +
    ');\n';

  return code;
};

Blockly.JavaScript['button_visibility'] = function (block) {
  var text_name = block.getFieldValue('NAME');
  var dropdown_val = block.getFieldValue('val');
  // TODO: Assemble JavaScript into code variable.
  var code =
    'gameBuilder.setButtonVisibility("' +
    text_name +
    '", ' +
    dropdown_val +
    ');\n';
  return code;
};

Blockly.JavaScript['setter_block_screen'] = function (block) {
  var dropdown_set_button_options = block.getFieldValue('set_button options');
  var dropdown_button_properties = block.getFieldValue('button_properties');
  var value_set_screen = Blockly.JavaScript.valueToCode(
    block,
    'set screen',
    Blockly.JavaScript.ORDER_ATOMIC
  );
  // TODO: Assemble JavaScript into code variable.
  var code = '...;\n';
  return code;
};

Blockly.JavaScript['when_sprite_touches_surface'] = function (block) {
  var text_sprite_1 = block.getFieldValue('sprite 1');
  var dropdown_surface_name = block.getFieldValue('surface name');
  var statements_name = Blockly.JavaScript.statementToCode(block, 'NAME');
  // TODO: Assemble JavaScript into code variable.
  // var code =
  //   'function ' +
  //   text_sprite_1 +
  //   '_' +
  //   dropdown_surface_name +
  //   '(direction){' +
  //   statements_name +
  //   ' };gameBuilder.sprite_touches_edge("' +
  //   text_sprite_1 +
  //   '", "' +
  //   dropdown_surface_name +
  //   '", ' +
  //   text_sprite_1 +
  //   '_' +
  //   dropdown_surface_name +
  //   ');\n';
  var code = '';
  if (dropdown_surface_name === 'top') {
    code =
      'updateCode += "if(' +
      text_sprite_1 +
      '.y <= 0){' +
      statements_name +
      '};"; \n';
  }

  if (dropdown_surface_name === 'down') {
    code =
      'updateCode += "if(' +
      text_sprite_1 +
      '.y >= 550){' +
      statements_name +
      '};"; \n';
  }

  if (dropdown_surface_name === 'left') {
    code =
      'updateCode += "if(' +
      text_sprite_1 +
      '.x <= 0){' +
      statements_name +
      '};"; \n';
  }

  if (dropdown_surface_name === 'right') {
    code =
      'updateCode += "if(' +
      text_sprite_1 +
      '.x + ' +
      text_sprite_1 +
      '.width >= 360){' +
      statements_name +
      '};"; \n';
  }
  return code;
};

/* Color */

Blockly.JavaScript['color_select'] = function (block) {
  var colour_color = block.getFieldValue('color');
  // TODO: Assemble JavaScript into code variable.
  var code = '"' + colour_color + '"';
  // TODO: Change ORDER_NONE to the correct strength.
  return [code, Blockly.JavaScript.ORDER_NONE];
};

Blockly.JavaScript['transparent'] = function (block) {
  // TODO: Assemble JavaScript into code variable.
  var code = 'transparent';
  // TODO: Change ORDER_NONE to the correct strength.
  return [code, Blockly.JavaScript.ORDER_NONE];
};

/* Numbers */

Blockly.JavaScript['number'] = function (block) {
  var number_name = block.getFieldValue('NAME');
  // TODO: Assemble JavaScript into code variable.
  var code = number_name;
  // TODO: Change ORDER_NONE to the correct strength.
  return [code, Blockly.JavaScript.ORDER_NONE];
};

Blockly.JavaScript['boolean'] = function (block) {
  var text_bool = block.getFieldValue('bool');
  // TODO: Assemble JavaScript into code variable.
  var code = text_bool;
  // TODO: Change ORDER_NONE to the correct strength.
  return [code, Blockly.JavaScript.ORDER_NONE];
};
