import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../helpers/utility';

const initialState = {
    exercises: [],
    error: null,
    loading: false
};

const fetchStart = (state, action) => {
    return updateObject(state, { error: null, loading: true });
}

const fetchSuccess = (state, action) => {
    return updateObject(state, {
        exercises: action.exercises,
        loading: false,
        error: null
    })
}

const fetchFailed = (state, action) => {
    return updateObject(state, {
        loading: false,
        error: action.error
    });
}


const fetchCompletedStart = (state, action) => {
    return updateObject(state, { error: null, loading: true });
}

const fetchCompletedSuccess = (state, action) => {
    return updateObject(state, {
        exercises: action.exercises,
        loading: false,
        error: null
    })
}

const fetchCompletedFailed = (state, action) => {
    return updateObject(state, {
        loading: false,
        error: action.error
    });
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_EXERCISES_START: return fetchStart(state, action);
        case actionTypes.FETCH_EXERCISES_SUCCESS: return fetchSuccess(state, action);
        case actionTypes.FETCH_EXERCISES_FAILED: return fetchFailed(state, action);
        case actionTypes.FETCH_COMPLETED_EXERCISE_START: return fetchCompletedStart(state, action);
        case actionTypes.FETCH_COMPLETED_EXERCISE_SUCCESS: return fetchCompletedSuccess(state, action);
        case actionTypes.FETCH_COMPLETED_EXERCISE_FAILED: return fetchCompletedFailed(state, action);
        default: return state;
    }
}

export default reducer;