/*eslint no-useless-concat: 0*/
/*eslint no-unused-vars: 0*/
import * as Blockly from "blockly/core";
import "blockly/javascript";

Blockly.JavaScript['when_run'] = function (block) {
  var statements_all_maze_statements = Blockly.JavaScript.statementToCode(block, 'all_maze_statements');
  // TODO: Assemble JavaScript into code variable.
  var code = '...;\n';
  return code;
};

Blockly.JavaScript['set_maze_character'] = function (block) {
  var character = block.getFieldValue('character');
  var direction = block.getFieldValue('direction')
  var type = block.getFieldValue('variable');
  var dropdown_numerical = block.getFieldValue('number');
  // TODO: Assemble JavaScript into code variable.

  var code = 'this.createAsset("' + type + '", "' + character + '", "' + type + '_' + dropdown_numerical + '", "' + direction + '");\n';
  return code;
};

Blockly.JavaScript['transparent'] = function(block) {
  // TODO: Assemble JavaScript into code variable.
  var code = 'rgba(255, 255, 255, 0)';
  // TODO: Change ORDER_NONE to the correct strength.
  return [code, Blockly.JavaScript.ORDER_NONE];
};
Blockly.JavaScript['set_maze_obstacles'] = function (block) {
  var obstacle = block.getFieldValue('obstacle');
  var type = block.getFieldValue('variable');
  var dropdown_numerical = block.getFieldValue('number');
  // TODO: Assemble JavaScript into code variable.
  var code = 'this.createAsset("' + type + '", "' + obstacle + '", "' + type + '_' + dropdown_numerical + '");\n';
  return code;
};




Blockly.JavaScript['set_maze_path'] = function (block) {
  var path = block.getFieldValue('path');
  var type = block.getFieldValue('variable');
  var dropdown_numerical = block.getFieldValue('number');
  // TODO: Assemble JavaScript into code variable.
  var code = 'this.createAsset("' + type + '", "' + path + '", "' + type + '_' + dropdown_numerical + '");\n';
  return code;
};

Blockly.JavaScript['set_maze_condition'] = function (block) {
  var condtional = block.getFieldValue('conditional');
  var type = block.getFieldValue('variable');
  var dropdown_numerical = block.getFieldValue('number');
  // TODO: Assemble JavaScript into code variable.
  var code = 'this.createAsset("' + type + '", "' + condtional + '", "' + type + '_' + dropdown_numerical + '");\n';

  return code;
};

Blockly.JavaScript['set_maze_background'] = function (block) {
  var dropdown_maze_backround_option = block.getFieldValue('background');
  // TODO: Assemble JavaScript into code variable.
  var code = 'this.changeBackground("' + dropdown_maze_backround_option + '");\n';
  return code;
};

Blockly.JavaScript['set_maze_row_input'] = function (block) {
  var dropdown_row_input = block.getFieldValue('row_input');
  var value_column1 = Blockly.JavaScript.valueToCode(block, 'column1', Blockly.JavaScript.ORDER_NONE);
  var value_column2 = Blockly.JavaScript.valueToCode(block, 'column2', Blockly.JavaScript.ORDER_NONE);
  var value_column3 = Blockly.JavaScript.valueToCode(block, 'column3', Blockly.JavaScript.ORDER_NONE);
  var value_column4 = Blockly.JavaScript.valueToCode(block, 'column4', Blockly.JavaScript.ORDER_NONE);
  var value_column5 = Blockly.JavaScript.valueToCode(block, 'column5', Blockly.JavaScript.ORDER_NONE);
  var value_column6 = Blockly.JavaScript.valueToCode(block, 'column6', Blockly.JavaScript.ORDER_NONE);
  var value_column7 = Blockly.JavaScript.valueToCode(block, 'column7', Blockly.JavaScript.ORDER_NONE);
  var value_column8 = Blockly.JavaScript.valueToCode(block, 'column8', Blockly.JavaScript.ORDER_NONE);
  // TODO: Assemble JavaScript into code variable.
  var code = '';
  if (value_column1 !== '') {
    code += 'this.changeMatrix(' + (parseInt(dropdown_row_input) - 1) + ',' + 0 + ',' + value_column1 + ');\n';
  }

  if (value_column2 !== '') {
    code += 'this.changeMatrix(' + (parseInt(dropdown_row_input) - 1) + ',' + 1 + ',' + value_column2 + ');\n';
  }

  if (value_column3 !== '') {
    code += 'this.changeMatrix(' + (parseInt(dropdown_row_input) - 1) + ',' + 2 + ',' + value_column3 + ');\n';
  }

  if (value_column4 !== '') {
    code += 'this.changeMatrix(' + (parseInt(dropdown_row_input) - 1) + ',' + 3 + ',' + value_column4 + ');\n';
  }

  if (value_column5 !== '') {
    code += 'this.changeMatrix(' + (parseInt(dropdown_row_input) - 1) + ',' + 4 + ',' + value_column5 + ');\n';
  }

  if (value_column6 !== '') {
    code += 'this.changeMatrix(' + (parseInt(dropdown_row_input) - 1) + ',' + 5 + ',' + value_column6 + ');\n';
  }

  if (value_column7 !== '') {
    code += 'this.changeMatrix(' + (parseInt(dropdown_row_input) - 1) + ',' + 6 + ',' + value_column7 + ');\n';

  }

  if (value_column8 !== '') {
    code += 'this.changeMatrix(' + (parseInt(dropdown_row_input) - 1) + ',' + 7 + ',' + value_column8 + ');\n';

  }

  return code;
};

Blockly.JavaScript['maze_input_type'] = function (block) {
  var dropdown_maze_input_option = block.getFieldValue('maze_input_option');
  var dropdown_numerical = block.getFieldValue('numerical');
  // TODO: Assemble JavaScript into code variable.
  var code = 'this.getAssetNumber("' + dropdown_maze_input_option + '_' + dropdown_numerical + '")';
  // TODO: Change ORDER_NONE to the correct strength.
  return [code, Blockly.JavaScript.ORDER_NONE];
};

Blockly.JavaScript['set_maze_condition_answers'] = function (block) {
  var ends = block.getFieldValue('ends');
  var type = block.getFieldValue('variable');
  var dropdown_numerical = block.getFieldValue('number');
  // TODO: Assemble JavaScript into code variable.
  var code = 'this.createAsset("' + type + '", "' + ends + '", "' + type + '_' + dropdown_numerical + '");\n';

  return code;

};

Blockly.JavaScript['set_maze_collect'] = function (block) {
  var ends = block.getFieldValue('collect');
  var type = block.getFieldValue('variable');
  var name = type.split('_');
  var dropdown_numerical = block.getFieldValue('number');
  // TODO: Assemble JavaScript into code variable.
  var code = 'this.createAsset("' + type + '", "' + ends + '", "' + type + '_' + dropdown_numerical + '", null, "apple");\n';

  return code;
};

Blockly.JavaScript['set_maze_score'] = function (block) {
  var ends = block.getFieldValue('score');
  var type = block.getFieldValue('variable');
  var dropdown_numerical = block.getFieldValue('number');
  // TODO: Assemble JavaScript into code variable.
  var code = 'this.createAsset("' + type + '", "' + ends + '", "' + type + '_' + dropdown_numerical + '");\n';

  return code;
};


Blockly.JavaScript['set_maze_destination'] = function (block) {
  var ends = block.getFieldValue('destination');
  var type = block.getFieldValue('variable');
  var dropdown_numerical = block.getFieldValue('number');
  // TODO: Assemble JavaScript into code variable.
  var code = 'this.createAsset("' + type + '", "' + ends + '", "' + type + '_' + dropdown_numerical + '");\n';

  return code;
};