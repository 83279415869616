import * as actionTypes from './actionTypes';
import api from '../../apis/config';

export const fetchLevelSuccess = (levelInfo) => {
    return {
        type: actionTypes.FETCH_LEVEL_SUCCESS,
        levelInfo
    }
}

export const fetchLevelFailed = (error) => {
    return {
        type: actionTypes.FETCH_LEVEL_FAILED,
        error
    }
}

export const saveXmlSuccess = () => {
    return {
        type: actionTypes.SAVE_XML_SUCCESS
    }
}

export const saveXmlFailed = (error) => {
    return {
        type: actionTypes.SAVE_XML_FAILED
    }
}

export const submitExerciseSuccess = (complete) => {
    return {
        type: actionTypes.SUBMIT_EXERCISE_SUCCESS,
        complete
    }
}

export const submitExerciseStart = (error) => {
    return {
        type: actionTypes.SUBMIT_EXERCISE_START,
    }
}

export const fetchLevelStart = (error) => {
    return {
        type: actionTypes.FETCH_LEVEL_START,
    }
}

export const submitExerciseFailed = (error) => {
    return {
        type: actionTypes.SUBMIT_EXERCISE_FAILED,
        error
    }
}

export const submitExerciseReset = () => {
    return {
        type: actionTypes.SUBMIT_EXERCISE_RESET
    }
}

export const resetSubmitExercise = () => {
    return dispatch => {

        dispatch(submitExerciseReset());
    }

}

export const fetchLevel = (userId, exerciseId, level) => {
    return dispatch => {
        dispatch(fetchLevelStart())
        api.post('/userExercise', { userId, exerciseId, level })
            .then(response => {
                if (response.status === 200) {

                    dispatch(fetchLevelSuccess(response.data.data));

                } else {
                    dispatch(fetchLevelFailed(response.data))
                }
            })
            .catch(error => {
                dispatch(fetchLevelFailed(error));
            })
    }
}

export const saveXml = (userId, exerciseId, level, xml) => {
    return dispatch => {
        api.post('/userExercise/' + userId + '/' + exerciseId + '/' + level, { xml: xml })
            .then(response => {
                //TO DO: add a green/red meter signifying blocks are saved or not.
            })
            .catch(error => {

            })
    }
}


export const submitExercise = (userId, exerciseId, level) => {
    return dispatch => {
        api.post('/userExercise/update', {
            userId,
            exerciseId,
            level
        })
            .then(response => {
                if (response.status === 200) {

                    dispatch(submitExerciseSuccess(true));
                }
            })
            .catch(error => {
                dispatch(submitExerciseFailed(error))
            })
    }
}