class Sprite {
  constructor(ctx, name, src, x, y, group = null) {
    this.group = group;
    this.CANVAS_WIDTH = 360;
    this.CANVAS_HEIGHT = 550;
    this.ctx = ctx;
    this.src = src;
    this.name = name;
    this.x = x;
    this.y = y;
    this.width = 0;
    this.height = 0;
    this.visible = true;

    this.original_x = this.x;
    this.original_y = this.y;
    this.final_x = 0;
    this.final_y = 0;

    this.dx = 0;
    this.dy = 0;
    this.jx = 0;
    this.jy = 0;

    this.speed = 0;

    this.dw = 0;
    this.dh = 0;

    this.touching = [];

    this.images_folder = `${process.env.PUBLIC_URL}/images/GameLab/`;

    this.movements = [];
  }

  draw() {
    if (this.visible === true) {
      var img = new Image();
      img.src = this.images_folder + this.src + '.png';

      if (this.width === 0 || this.height === 0) {
        this.width = img.naturalWidth;
        this.height = img.naturalHeight;

        this.original_width = img.naturalWidth;
        this.original_height = img.naturalHeight;
      }

      this.ctx.drawImage(img, this.x, this.y, this.width, this.height);
    }
  }

  update() {
    var movements_count = this.movements.length;

    this.x = this.x + this.jx + this.dx;
    this.y = this.y + this.dy;
    this.width = this.width + this.dw;
    this.height = this.height + this.dh;

    if (movements_count > 0) {
      var i = 0;
      this.movements.forEach((movement) => {
        if (movement.type === 'jittering') {
          this.jittering(i, movement.scale);
        }

        if (movement.type === 'grow') {
          this.grow(i, movement.scale);
        }

        if (movement.type === 'shrink') {
          this.shrink(i, movement.scale);
        }

        if (movement.type === 'sprite_touch_edge') {
          this.sprite_touches_edge(i, movement.edge, movement.func);
        }

        if (movement.type === 'random_move') {
          this.move_randomly(i, movement.speed);
        }

        if (movement.type === 'bounce') {
          this.bounce(i, movement.direction);
        }

        if (movement.type === 'sprite_touches_sprite') {
          this.sprite_touches_sprite(i, movement.sprite, movement.func);
        }

        if (movement.type === 'sprite_touches_group') {
          this.sprite_touches_group(i, movement.group, movement.func);
        }

        if (movement.type === 'move') {
          this.move(
            i,
            movement.direction,
            movement.speed,
            movement.final_x,
            movement.final_y,
            movement.initial_x,
            movement.initial_y
          );
        }

        i += 1;
      });
    }
  }

  sprite_touches_sprite = (index, sprite, func) => {
    if (this.visible === true && sprite.visible === true) {
      if (
        this.x + this.width >= sprite.x &&
        this.y + this.height >= sprite.y &&
        this.y <= sprite.y + sprite.height &&
        this.x <= sprite.x + sprite.width
      ) {
        if (!this.touching.includes(sprite.name)) {
          func();
        }

        this.touching.push(sprite.name);
      } else {
        var i = this.touching.indexOf(sprite.name);
        if (i) {
          this.touching.splice(i, 1);
        }
      }
    }
  };

  sprite_touches_group = (index, group, func) => {
    group.forEach((sprite) => {
      var name = sprite.name;

      if (this.visible === true && sprite.visible === true) {
        if (
          this.x + this.width >= sprite.x &&
          this.y + this.height >= sprite.y &&
          this.y <= sprite.y + sprite.height &&
          this.x <= sprite.x + sprite.width
        ) {
          if (!this.touching.includes(name)) {
            func(sprite.name, this.name);
          }

          this.touching.push(name);
        } else {
          var i = this.touching.indexOf(name);
          if (i) {
            this.touching.splice(i, 1);
          }
        }
      }
    });
  };

  sprite_touches_edge = (index, edge, func) => {
    if (this.x + this.width >= this.CANVAS_WIDTH && edge === 'right') {
      func('right', this.name);
    } else if (this.x <= 0 && edge === 'left') {
      func('left', this.name);
    } else if (this.y + this.height >= this.CANVAS_HEIGHT && edge === 'down') {
      func('down', this.name);
    } else if (this.y <= 0 && edge === 'top') {
      func('top', this.name);
    }
  };

  jittering_block = (scale) => {
    this.movements.push({
      type: 'jittering',
      scale: scale,
    });
  };

  jittering = (index, scale) => {
    if (this.x >= this.original_x + scale) {
      this.jx = -1;
    } else if (
      this.x <= this.original_x - scale ||
      this.x === this.original_x
    ) {
      this.jx = 1;
    }
  };

  bounce_block = (direction) => {
    this.movements.push({
      type: 'bounce',
      direction: direction,
    });
  };

  bounce = (index, direction = null) => {
    if (direction === 'right') {
      this.dx = -Math.abs(this.dx);
    }

    if (direction === 'left') {
      this.dx = Math.abs(this.dx);
    }

    if (direction === 'top') {
      this.dy = Math.abs(this.dy);
    }

    if (direction === 'down') {
      this.dy = -Math.abs(this.dy);
    }

    this.movements.splice(index, 1);
  };

  move_randomly_block = (speed) => {
    this.movements.push({
      type: 'random_move',
      speed: speed,
    });
  };

  move_randomly = (id, speed) => {
    if (this.dx === 0 && this.dy === 0) {
      this.speed = speed / 10;
      var degree = this.randomIntFromInterval(0, 360);
      this.dx = (speed / 10) * Math.cos((degree * Math.PI) / 180);
      this.dy = (speed / 10) * Math.sin((degree * Math.PI) / 180);
    }
  };

  move_block = (direction, displacement, speed) => {
    var final_x = this.x + displacement * Math.cos((direction * Math.PI) / 180);
    var final_y = this.y + displacement * Math.sin((direction * Math.PI) / 180);

    this.movements.push({
      type: 'move',
      direction: direction,
      displacement: displacement,
      speed: speed,
      initial_x: this.x,
      initial_y: this.y,
      final_x,
      final_y,
    });
  };

  move = (index, direction, speed, final_x, final_y, initial_x, initial_y) => {
    this.dx = (speed / 10) * Math.cos((direction * Math.PI) / 180);
    this.dy = (speed / 10) * Math.sin((direction * Math.PI) / 180);

    var x_done = false;
    var y_done = false;

    if (initial_x < final_x) {
      if (this.x < final_x) {
        this.dx = Math.abs(this.dx);
      } else {
        x_done = true;
      }
    } else if (initial_x === final_x) {
      x_done = true;
    } else {
      if (this.x > final_x) {
        this.dx = -Math.abs(this.dx);
      } else {
        x_done = true;
      }
    }

    if (initial_y < final_y) {
      if (this.y < final_y) {
        this.dy = Math.abs(this.dy);
      } else {
        y_done = true;
      }
    } else if (initial_y === final_y) {
      y_done = true;
    } else {
      if (this.y > final_y) {
        this.dy = -Math.abs(this.dy);
      } else {
        y_done = true;
      }
    }

    if (y_done && x_done) {
      this.dx = 0;
      this.dy = 0;
      this.x = final_x;
      this.y = final_y;
      this.movements.splice(index, 1);
    }
  };

  grow_block = (scale) => {
    this.movements.push({
      type: 'grow',
      scale: scale,
    });
  };

  grow = (index, scale) => {
    if (this.width <= this.original_width + scale) {
      this.dw = this.original_width / this.original_height;
      this.dh = this.original_height / this.original_width;
    } else {
      this.dw = 0;
      this.dh = 0;
      this.original_width = this.width;
      this.original_height = this.height;
      this.movements.splice(index, 1);
    }
  };

  shrink_block = (scale) => {
    this.movements.push({
      type: 'shrink',
      scale: scale,
    });
  };

  shrink = (index, scale) => {
    if (this.width >= this.original_width - scale) {
      this.dw = -(this.original_width / this.original_height);
      this.dh = -(this.original_height / this.original_width);
    } else {
      this.dw = 0;
      this.dh = 0;
      this.original_width = this.width;
      this.original_height = this.height;
      this.movements.splice(index, 1);
    }
  };

  randomIntFromInterval(min, max) {
    // min and max included
    return Math.floor(Math.random() * (max - min + 1) + min);
  }
}

export default Sprite;
