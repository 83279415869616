var levels = {
    1: {
        "blocks": {
            'World': ['set_background'],
            'Sprites': ['create_sprite_lab2', 'change_sprite_lab2', 'hide_sprite', 'show_sprite'],
            'Sprite Movements': ['sprite_begins', 'sprite_stops', 'move_randomly_with_speed', 'move_left_right', 'move_up_down', 'move_down', 'grow_sprite', 'shrink_sprite', 'move_left', 'stops_every_movement'],
            'Events': ['when_run', 'when_clicked', 'when_button_pressed',
                'when_sprite_touches', 'when_sprite_touches_surface'],
            'Text': ['create_text_input', 'set_text', 'set_text_color', 'set_text_size', 'text_value'],
            'Buttons': ['create_button', 'set_button_colour', 'set_button_text', 'set_button_text_colour', 'set_button_text_size'],
            'Math': ['math_number', 'default_x', 'default_y', 'color_select']
        },
        "initial_background": "unicorn_story_background1.png",
        "initial_code": 'this.state.ctx.beginPath();this.state.ctx.rect(0, 250, 120, 120);this.state.ctx.rect(240, 420, 120, 120);this.state.ctx.stroke();this.state.ctx.fillStyle = "#000000";',
        "validations": ["error_background", "error_sprite_xy", "error_sprite_events", "error_sprite_dest"],
        "test_cases": {
            "x": 0,
            "y": 250,
            "sprite": "fairy",
            "dest_x": 260,
            "dest_y": 450,
            "width": 100,
            "height": 100,
            "events": [{ "fairy": "isTouching" }, { "redstar": "hide" }, { "bluestar": "hide" }],
            "background": "unicorn_story_background1.png"
        },
    },
    2: {

        "blocks": {

            'World': ['set_background'],

            'Sprites': ['create_sprite_lab2', 'change_sprite_lab2', 'hide_sprite', 'show_sprite'],
            'Sprite Movements': ['sprite_begins', 'sprite_stops', 'move_randomly_with_speed', 'move_left_right', 'move_up_down', 'move_down', 'grow_sprite', 'shrink_sprite', 'move_left', 'stops_every_movement'],

            'Events': ['when_run', 'when_clicked', 'when_button_pressed',

                'when_sprite_touches', 'when_sprite_touches_surface'],

            'Text': ['create_text_input', 'set_text', 'set_text_color', 'set_text_size', 'text_value'],

            'Buttons': ['create_button', 'set_button_colour', 'set_button_text', 'set_button_text_colour', 'set_button_text_size'],
            'Math': ['math_number', 'default_x', 'default_y', 'color_select']


        },

        "initial_background": "unicorn_story_background2.png",

        "initial_code": 'this.state.ctx.beginPath();this.state.ctx.rect(0, 250, 120, 120);this.state.ctx.stroke();this.state.ctx.fillStyle = "#000000";',

        "validations": ["error_background", "error_sprite_xy", "error_sprite_events", "error_sprite_dest"],

        "test_cases": {

            "x": 0,

            "y": 250,

            "sprite": "fairy",

            "dest_x": 280,

            "dest_y": 280,

            "width": 100,

            "height": 100,

            "events": [{ "fairy": "isTouching" }, { "pumpkin_story": "hide" }],

            "background": "unicorn_story_background2.png"

        },

    },

    3: {

        "blocks": {

            'World': ['set_background'],
            'Sprites': ['create_sprite_lab2', 'change_sprite_lab2', 'hide_sprite', 'show_sprite'],
            'Sprite Movements': ['sprite_begins', 'sprite_stops', 'move_randomly_with_speed', 'move_left_right', 'move_up_down', 'move_down', 'grow_sprite', 'shrink_sprite', 'move_left', 'stops_every_movement'],
            'Events': ['when_run', 'when_clicked', 'when_button_pressed',
                'when_sprite_touches', 'when_sprite_touches_surface'],
            'Text': ['create_text_input', 'set_text', 'set_text_color', 'set_text_size', 'text_value'],
            'Buttons': ['create_button', 'set_button_colour', 'set_button_text', 'set_button_text_colour', 'set_button_text_size'],
            'Math': ['math_number', 'default_x', 'default_y', 'color_select']

        },
        "initial_background": "space_story_background3.png",
        "initial_code": 'this.state.ctx.beginPath();this.state.ctx.stroke();this.state.ctx.fillStyle = "#000000";',
        "validations": ["error_background", "error_sprite_events"],
        "test_cases": {

            "x": 0,

            "y": 350,

            "sprite": "fairy",

            "dest_x": 280,

            "dest_y": 250,

            "width": 100,

            "height": 100,

            "events": [{ "fairy": "isTouching" }, { "rabbit": "move_left_right" }],

            "background": "unicorn_story_background3.png"

        },

    },

    4: {

        "blocks": {

            'World': ['set_background'],

            'Sprites': ['create_sprite_lab2', 'change_sprite_lab2', 'hide_sprite', 'show_sprite'],

            'Sprite Movements': ['sprite_begins', 'sprite_stops', 'move_randomly_with_speed', 'move_left_right', 'move_up_down', 'move_down', 'move_up', 'grow_sprite', 'shrink_sprite', 'move_left', 'stops_every_movement'],

            'Events': ['when_run', 'when_clicked', 'when_button_pressed',

                'when_sprite_touches', 'when_sprite_touches_surface'],

            'Text': ['create_text_input', 'set_text', 'set_text_color', 'set_text_size', 'text_value'],

            'Buttons': ['create_button', 'set_button_colour', 'set_button_text', 'set_button_text_colour', 'set_button_text_size'],

            'Math': ['math_number', 'default_x', 'default_y', 'color_select']

        },

        "initial_background": "unicorn_story_background4.png",

        "initial_code": 'this.state.ctx.beginPath();;this.state.ctx.stroke();this.state.ctx.fillStyle = "#000000";',

        "validations": ["error_background", "error_sprite_events"],

        "test_cases": {
            "x": 0,
            "y": 250,
            "sprite": "fairy",
            "dest_x": 280,
            "dest_y": 280,
            "width": 100,
            "height": 100,
            "events": [{ "fairy": "isTouching" }],
            "background": "unicorn_story_background4.png"

        },

    }

}

export default levels;
