/* eslint no-eval: 0 */
/*eslint no-unused-vars: 0*/

import React from 'react';
import _ from 'lodash';
import swal from 'sweetalert';
import classes from './MazePlay.module.css';
import Header from '../../components/UI/Header/Header';
import BlocklyContainer from '../BlocklyContainer';
import BlocklyJS from 'blockly/javascript';
import RunButton from '../RunButton';
import '../../blocks/MazeCreator/BlockFunctions';
import levels from './MazeCreatorPlay/level';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/index';
import MazePlay from './MazePlay';
import { Link } from 'react-router-dom';


class MazeCreatorBlocks extends React.Component {
    constructor(props) {
        super(props);

        this.projectId = this.props.match.params.projectId;
        this.mazeCanvas = React.createRef();
        this.BlocklyContainer = BlocklyContainer;

    }

    state = {
        name: '',
        background_source: 'maze_back_1',
        withCode: true,
        assets: {},
        matrix: [
            [0, 0, 0, 0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0, 0, 0, 0]
        ],
        loaded: false,
        random: {},
        level: {
            'validations': [
                {
                    type: 'only_path_exists'
                },
                {
                    type: 'destination_reached'
                }
            ]
        }
    }

    componentDidMount() {
        this.props.fetchProject(this.projectId);

        var background = new Image();
        background.src = `${process.env.PUBLIC_URL}/images/` + this.state.background_source + '.png';

        var canvas = this.mazeCanvas.current;
        var ctx = canvas.getContext('2d');

        var character_info = this.character_info(this.state.assets, this.state.matrix);

        var maze = new MazePlay(ctx, this.state.matrix, this.state.assets, character_info);

        var intervalId = setInterval(this.gameLoop, 10);

        this.setState({ intervalId: intervalId, ctx: ctx, background: background, maze: maze });

    }

    componentDidUpdate(prevProps, prevState) {
        if (_.isEqual(prevProps.userProject, this.props.userProject) === false) {
            this.setState({
                background_source: (this.props.userProject.data.background) ? this.props.userProject.data.background : 'maze_back_1',
                name: (this.props.userProject.name) ? this.props.userProject.name : '...',
                assets: (this.props.userProject.data.assets) ? this.props.userProject.data.assets : [],
                matrix: (this.props.userProject.data.matrix) ? this.props.userProject.data.matrix : this.state.matrix
            })
        }


        if (_.isEqual(prevState.matrix, this.state.matrix) === false || _.isEqual(prevState.assets, this.state.assets !== false)) {

            var maze = new MazePlay(this.state.ctx, this.state.matrix, this.state.assets, this.character_info(this.state.assets, this.state.matrix));
            this.setState({
                maze: maze
            })
        }
    }

    gameLoop = () => {
        var background = new Image();
        background.src = `${process.env.PUBLIC_URL}/images/` + this.state.background_source + '.png';

        this.state.ctx.drawImage(background, 0, 0, 400, 400);


        this.state.maze.draw();
        this.state.maze.update();
    }

    character_info = (assets, matrix) => {
        var character_info = {};
        //check where is the character in the matrix

        //we get the matrix and asset from the state variable
        if (_.isEmpty(assets) === false) {
            //iterate over the matrix
            for (var i = 0; i < matrix.length; i++) {
                for (var j = 0; j < matrix[0].length; j++) {
                    if (assets[matrix[i][j] - 1]) {

                        var current_element = assets[matrix[i][j] - 1];

                        if (current_element.type === 'character') {
                            character_info['asset_number'] = matrix[i][j] - 1;
                            character_info['x'] = j * 50;
                            character_info['y'] = i * 50;
                            character_info['angle'] = parseInt(current_element.angle);
                        }
                    }
                }
            }
        }
        //show asset_number, x, y, angle
        if (_.isEmpty(character_info)) {
            character_info = {
                x: 0,
                y: 0,
                angle: 0,
                asset_number: -1
            }
        }
        return character_info;
    }

    runButtonHandler = () => {
        var self = this;

        var code = BlocklyJS.workspaceToCode(this.BlocklyContainer.primaryWorkspace);
       
        this.state.maze.reset();
        self.state.maze.run = true;
        console.log(this.state.assets);
        eval(code);
    }

    onMoveButton = () => {
        
        this.state.maze.move();
    }

    onTurnLeft = () => {
        this.state.maze.turn_left();
    }

    onTurnRight = () => {
        this.state.maze.turn_right();
    }

    onShare = () => {
        alert('s');
    }

    onSubmit = () => {
        var result = this.state.maze.submit();

        if (result.length > 0) {
            swal({
                title: "Oops!",
                text: result[0].message,
                icon: "error",
                button: "Try Again!",
            });
        }else {
            //successful call to the database
            swal({
                title: "Good Job!!",
                text: "Successful",
                icon: "success",
                button: "Share",
            })

        }
    }


    withCodeToggle = (state) => {
        this.setState({
            withCode: state
        })
    }

    render() {
        var disable = true;
        if (this.props.userProject) {
            if (this.props.userProject.status) {
                if (this.props.userProject.status === true) {
                    disable = false;
                }
            }
        }

        var buttons = '';
        var blockly = '';
        if (this.state.withCode === false) {
            buttons = (
                <div>
                    <button onClick={this.onTurnLeft} className="btn">turn left</button>
                    <button onClick={this.onTurnRight} className="btn">turn right</button>
                    <button onClick={this.onMoveButton} className="btn">move</button>
                </div>
            );


        } else {
            buttons = (
                <div>
                    <button className="btn" onClick = {this.runButtonHandler}>Run</button>
                    <button className="btn" style={{ backgroundColor: 'red' }}>Reset</button>
                    <button onClick = {this.onSubmit} className="btn" style={{ backgroundColor: 'red' }}>Submit</button>
                </div>
            )

            blockly = (
                <BlocklyContainer marginTop = {45} blockSave={false} initialXml={''} blocks={levels[1].blocks} projectId={this.props.match.params.projectId} />
            )
        }



        return (
            <React.Fragment>
                <Header color="#F2F2F2" />
                <div className="container">
                    <div class={classes.center}>
                        <div classname={classes.playTabs}>
                            <a href={"/project/maze/" + this.projectId} class={classes.tabButton}>Builder</a>
                            <a href={"/project/maze/play/" + this.projectId} class={classes.tabButton}>Play</a>
                            <a href={"/project/maze/blocks/" + this.projectId} class={[classes.tabButton, classes.active].join(' ')}>Play with Code</a>
                        </div>
                    </div>

                    <div className={classes.centerGrid}>

                        <div className={classes.preview}>

                            <h2>{(this.state.name !== null) ? this.state.name : '...'}</h2><br />
                            <canvas ref={this.mazeCanvas} width={400} height={400} style={{ 'border': "1px solid black" }} />
                            <br />
                            <div className="btnSection">
                                {buttons}
                                <div>
                                    <button className="btn" onClick={this.onShare} disabled={disable} style={{ backgroundColor: 'red' }}>Share</button>
                                </div>
                            </div>
                        </div>

                        {blockly}


                    </div>
                </div>
            </React.Fragment>
        )
    }
}

const mapDispatchToProps = dispatch => {
    return {
        fetchProject: (id) => dispatch(actions.fetchProject(id))
    }
}

const mapStateToProps = state => {
    return {
        userProject: state.projects.userProject
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MazeCreatorBlocks);