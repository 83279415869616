import React, { useState } from 'react';
import classes from './ProjectCard.module.css';
import editImage from '../../../assets/edit.png';
import deleteImage from '../../../assets/delete.png';
import deleteImageYellow from '../../../assets/icon_delete_yellow.png';
import editImageYellow from '../../../assets/icon_edit_yellow.png';
import editImageIcon from '../../../assets/edit.svg';
import editImageIconYellow from '../../../assets/editYellow.svg';

import { Link } from 'react-router-dom';

const ProjectCard = (props) => {

    const [deleteImg, setDeleteImg] = useState(deleteImage);
    const [editImg, setEditImg] = useState(editImage);
    const [deleteText, setDeleteText] = useState('#FFFFFF');
    const [editText, setEditText] = useState('#FFFFFF');
    const [editIcon, setEditIcon] = useState(editImageIcon);

    const editImageMouseOn = () => {
        setEditImg(editImg => editImageYellow);
        setEditText(editText => '#FCB52F')
    }

    const editImageMouseOut = () => {
        setEditImg(editImg => editImage);
        setEditText(editText => '#FFFFFF')
    }

    const deleteImageMouseOn = () => {
        setDeleteImg(deleteImg => deleteImageYellow);
        setDeleteText(deleteText => '#FCB52F')
    }

    const deleteImageMouseOut = () => {
        setDeleteImg(deleteImg => deleteImage);
        setDeleteText(deleteText => '#FFFFFF')
    }


    return (
        <div className={classes.polaroid}>
            <div className={classes.titleDiv}>
                <span>{props.name}</span>
                <span className={classes.editIcon} onClick={() => props.editPopUp(props.name, 'edit', props.id)}>
                    <img src={editIcon}
                        alt="Edit"
                        onMouseEnter={() => {
                            setEditIcon(editImageIconYellow)
                        }} onMouseOut={() => {
                            setEditIcon(editImageIcon)
                        }} />
                </span>
            </div>
            <div className={classes.actionDiv}>
                <div className={classes.actionText} >
                    <Link to={'/project/' + props.lab + '/' + props.id}>
                        <img src={editImg} alt="Open"
                            onMouseEnter={() => {
                                editImageMouseOn();
                            }} onMouseOut={() => {
                                editImageMouseOut();
                            }} />
                        <p style={{ color: editText, textDecoration: 'none' }}>Open</p>
                    </Link>

                </div>
                <div className={classes.actionText}>
                    <img src={deleteImg} alt="Delete" onClick={(e, id) => props.delete(e, props.id)}
                        onMouseEnter={() => {
                            deleteImageMouseOn();
                        }} onMouseOut={() => {
                            deleteImageMouseOut();
                        }} />
                    <p style={{ color: deleteText }}>Delete</p>
                </div>
            </div>
        </div >
    );
}

export default ProjectCard;