import * as actionTypes from './actionTypes';
import api from '../../apis/config';

export const exercisesFetchStart = () => {
    return {
        type: actionTypes.FETCH_EXERCISES_START
    }
}

export const exercisesFetchSuccess = (exercises) => {
    return {
        type: actionTypes.FETCH_EXERCISES_SUCCESS,
        exercises
    }
}

export const exercisesFetchFailed = (error) => {
    return {
        type: actionTypes.FETCH_EXERCISES_FAILED,
        error
    }
}

export const fetchExercises = (lab, userId) => {
    return dispatch => {
        dispatch(exercisesFetchStart());
        api.get('/exercise/' + lab + '/' + userId)
            .then(response => {
                if (response.status === 200) {
                    //trim the data to get only the bits we want to use in the home page
                    //ie. name, image, id, labname and progress of the exercise
                    dispatch(exercisesFetchSuccess(response.data.data));
                } else {
                    dispatch(exercisesFetchFailed(response.data.error))
                }
            })
            .catch(err => {
                console.log(err);
                dispatch(exercisesFetchFailed(err));
            });
    }
}

export const exercisesFetchCompletedStart = (exercises) => {
    return {
        type: actionTypes.FETCH_COMPLETED_EXERCISE_START
    }
}


export const exercisesFetchCompletedSuccess = (exercises) => {
    return {
        type: actionTypes.FETCH_COMPLETED_EXERCISE_SUCCESS,
        exercises
    }
}

export const exercisesFetchCompletedFailed = (error) => {
    return {
        type: actionTypes.FETCH_COMPLETED_EXERCISE_FAILED,
        error
    }
}

export const fetchCompletedExercises = (userId) => {
    return dispatch => {
        dispatch(exercisesFetchCompletedStart());
        api.get('/exercise/completed/task/' + userId)
            .then(response => {
                if (response.status === 200) {
                

                    //trim the data to get only the bits we want to use in the home page
                    //ie. name, image, id, labname and progress of the exercise
                    dispatch(exercisesFetchSuccess(response.data.data));
                } else {
                    dispatch(exercisesFetchFailed(response.data.error))
                }
            })
            .catch(err => {
                console.log(err);
                dispatch(exercisesFetchFailed(err));
            });
    }
}