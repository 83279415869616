import React, { useState } from 'react';
import { Switch, Route, Redirect } from 'react-router';

// import Login from './containers/Auth/Login/Login';
import Register from './containers/Auth/Register/Register';
import ForgotPassword from './containers/Auth/ForgotPassword/ForgotPassword';
import Home from './containers/Home/Home';
import { ProtectedRoute } from './components/ProtectedRoute/ProtectedRoute';
import Exercise from './containers/Exercise/Exercise';
import MazeCreatorContainer from './Apps/Maze/MazeCreatorContainer';
import StoryLabContainer from './Apps/Story/StoryLabContainer';
import ResetPassword from './containers/Auth/ResetPassword/ResetPassword';
import MazeCreatorPlay from './Apps/Maze/MazeCreatorPlay';
import Profile from './containers/Profile/Profile';
import Redirect404 from './containers/Redirect404/Redirect404';
import { BrowserRouter, HashRouter } from 'react-router-dom';
import MazeCreatorBlocks from './Apps/Maze/MazeCreatorBlocks';
import GameLabContainer from './Apps/Game/GameLabContainer';

function App() {
  return (
    <React.Fragment>
      <HashRouter>
        <Switch>
          <ProtectedRoute path='/profile' exact component={Profile} />
          <ProtectedRoute path='/:lab' exact component={Home} />
          <ProtectedRoute path='/register' exact component={Register} />
          <ProtectedRoute
            path='/exercise/:exerciseName/:exerciseId/:level'
            exact
            component={Exercise}
          />
          <ProtectedRoute
            path='/project/maze/:projectId'
            exact
            component={MazeCreatorContainer}
          />
          <ProtectedRoute
            path='/project/story/:projectId'
            exact
            component={StoryLabContainer}
          />
          <ProtectedRoute
            path='/project/game/:projectId'
            exact
            component={GameLabContainer}
          />
          <ProtectedRoute
            path='/project/maze/play/:projectId'
            exact
            component={MazeCreatorPlay}
          />
          <ProtectedRoute
            path='/project/maze/blocks/:projectId'
            exact
            component={MazeCreatorBlocks}
          ></ProtectedRoute>
          <ProtectedRoute
            path='/forgot-password'
            exact
            component={ForgotPassword}
          />
          <ProtectedRoute
            path='/auth/reset/:resetId'
            exact
            component={ResetPassword}
          />
          <Redirect from='/' to='/maze'></Redirect>
          <Route component={Redirect404} />
        </Switch>
      </HashRouter>
    </React.Fragment>
  );
}

export default App;
