import axios from 'axios';
import Cookie from 'js-cookie';

const defaultOptions = {
    baseURL: process.env.REACT_APP_API_BASE,
    headers: {
        'Content-Type': 'application/json'
    }
}

let instance = axios.create(defaultOptions);

instance.interceptors.request.use(function (config) {
    const token = Cookie.get('token');
    config.headers.Authorization = token ? token : ''
    return config;
})

export default instance;