import * as actionTypes from './actionTypes';
import api from '../../apis/config';

export const unpluggedFetchStart = () => {
    return {
        type: actionTypes.FETCH_UNPLUGGED_START
    }
}

export const unpluggedFetchSuccess = (unplugged) => {
    return {
        type: actionTypes.FETCH_UNPLUGGED_SUCCESS,
        unplugged
    }
}

export const unpluggedFetchFailed = (error) => {
    return{
        type: actionTypes.FETCH_UNPLUGGED_FAILED,
        error
    }
}

export const fetchUnplugged = (lab) => {
    return dispatch => {
        dispatch(unpluggedFetchStart());
        api.get('/activity/' + lab)
        .then(response => {
            if(response.status === 200){
                dispatch(unpluggedFetchSuccess(response.data.data))
            }else{
                dispatch(unpluggedFetchFailed(response.data.error))
            }
        })
        .catch(error => {
            console.log(error);

        })
    }
}