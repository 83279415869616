var level = {
    1: {
        'blocks': { 
            'Movement': ['when_run','move_forward', 'turn_left', 'turn_right'],
            // 'Conditions': ['controls_if', 'check_question_block', 'yellow_flower', 'carrot'], 
            'Loops': ['controls_repeat_ext', 'math_number'],
            'Collect': ['collect', 'apple', 'orange']
           
            
        },

}
}

export default level;