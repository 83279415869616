/* eslint no-eval: 0 */

class MazePlay {
  constructor(ctx, matrix, assets, character_info) {
    this.ctx = ctx;
    this.matrix = matrix;
    this.assets = assets;
    this.character_info = character_info;
    this.images_folder = `${process.env.PUBLIC_URL}/images/`;

    this.initial_x = character_info.x;
    this.initial_y = character_info.y;
    this.x = character_info.x;
    this.y = character_info.y;
    this.angle = character_info.angle;
    this.asset_number = character_info.asset_number;

    this.movements_list = [];
    this.debug_list = [];
    this.movement_x = 0;
    this.movement_y = 0;
    this.final_x = this.x;
    this.final_y = this.y;

    this.change_angle = this.angle;


    this.positions = [];

    this.collections = {};
    this.basket = {};
  }

  draw = () => {
    var cx = -1, cy = -1;

    for (var i = 0; i < this.matrix.length; i++) {
      for (var j = 0; j < this.matrix[0].length; j++) {
        var number = this.matrix[i][j] - 1;

        var object = this.assets[number];
        if (object !== undefined) {
          if (object.type === 'character') {
            // var character = new Image();
            // character.src = this.images_folder + object.path + '_' + object.angle + '.png';
            // this.ctx.drawImage(character, j * 50 + 5, i * 50 + 5, 40, 40)

            cx = i;
            cy = j;
          }

          if (object.type === 'obstacle') {
            var obstacle = new Image();
            obstacle.src = this.images_folder + object.path + '.png';
            this.ctx.drawImage(obstacle, j * 50 + 5, i * 50 + 5, 40, 40);
          }

          if (object.type === 'path') {
            var path = new Image();
            path.src = this.images_folder + object.path + '.png';
            this.ctx.drawImage(path, j * 50, i * 50, 50, 50);
          }

          if (object.type === 'condition') {
            var condition = new Image();
            condition.src = this.images_folder + object.path + '.png';
            this.ctx.drawImage(condition, j * 50 + 5, i * 50 + 5, 40, 40);
          }

          if (object.type === 'destination') {

            var destination = new Image();
            destination.src = this.images_folder + object.path + '.png';
            this.ctx.drawImage(destination, j * 50 + 5, i * 50 + 5, 40, 40);
          }

          if (object.type === 'collect') {
            var collect_name = object.name;
            var collect = new Image();
            collect.src = this.images_folder + object.path + '.png';
            if (this.checkCollectHide(i, j, collect_name) === true) {
              this.ctx.drawImage(collect, j * 50 + 5, i * 50 + 5, 40, 40);
            }

          }

          if (object.type === 'conditional_end') {
            var conditional_end = new Image();
            conditional_end.src = this.images_folder + object.path + '.png';
            this.ctx.drawImage(conditional_end, j * 50 + 5, i * 50 + 5, 40, 40);

          }

        }
      }
    }

    if (cx !== -1 && cy !== -1) {
      var character = new Image();
      character.src = this.images_folder + this.assets[this.asset_number].path + '_' + this.angle + '.png';
      this.ctx.drawImage(character, this.x, this.y, 40, 40);
    }

  };
  checkCollectHide(i, j, name) {
    let x = 0;

    if (this.collections[name]) {
      this.collections[name].forEach((collect) => {
        if (i === collect[0] && j === collect[1]) {
          x += 1;
        }
      });
    }

    if (x === 0) {
      return true;
    }

    return false;
  }

  check_if_collect = (name) => {

    var row = parseInt(this.final_y / 50);
    var column = parseInt(this.final_x / 50);

    var position_info = this.position_info(row, column);

    if (position_info.type === "collect" && position_info.name === name) {
      return true;
    } else {
      return false;
    }
  }

  add_to_basket = (number, type) => {

    this.movements_list.push({
      'type': 'add_to_basket',
      'number': number,
      'type_of_object': type
    })

  }

  collect = (name) => {
    this.movements_list.push({
      type: 'collect',
      name: name
    })

  };

  collectWithoutName = () => {
    var row = parseInt(this.final_y / 50);
    var column = parseInt(this.final_x / 50);

    var position_info = this.position_info(row, column);

    this.movements_list.push({
      type: 'collect',
      name: position_info.name
    })
  }

  submit = () => {
    var errors = [];
    
    //getting the current column and row values for the character
    var row = parseInt(this.y / 50);
    var column = parseInt(this.x / 50);

    var current_position_info = this.position_info(row, column);

    //check if the maze contains a destination type asset
    var destination = this.assets_used(this.assets, this.matrix).includes('destination');

    if(destination === true){
      if(current_position_info.type !== 'destination'){
        errors.push({
          type: 'DESTINATION_NOT_REACHED',
          message: "The character didnt reach the destination"
        })
      }
    }

    this.positions.forEach((element, index) => {
      var type = this.position_info(element.row, element.column).type;
      
      if (type !== "path" && type !== "destination" && type !== 'collect' && type !== 'condition' && type !== 'condition_end') {
        errors.push({
          type: true,
          message: "Your character is going in the wrong direction. Please try again using correct turn left or right blocks.",
        });
      }
    });

    var collect_exists = this.assets_used(this.assets, this.matrix).includes('collect');
    var collect_cnt = this.count_asset_types(this.assets, this.matrix, 'collect');

    if(collect_exists === true){
      
      if(Object.keys(this.collections).length !== collect_cnt){
        errors.push({
          type: true,
          message: "You may have missed using the appropriate collect ",
        });
      }
    
    }

    return errors;
  }

  count_asset_types = (assets, matrix, type) => {
    var cnt = 0;
    for(var i = 0; i < matrix.length; i++){
      for(var j = 0; j < matrix[0].length; j++){
        if (assets[matrix[i][j] - 1] !== undefined) {
          if(assets[matrix[i][j] - 1].type === type){
            
            cnt++
          }
        }
      }
    }

    return cnt;
  }

  assets_used = (assets, matrix) => {
    var asset_list = [];
    for(var i = 0; i < matrix.length; i++){
      for(var j = 0; j < matrix[0].length; j++){
        if (assets[matrix[i][j]] !== undefined) {
          asset_list.push(assets[matrix[i][j]].type);
        }
      }
    }
    return asset_list;
  }

  update = () => {
    if (this.movements_list.length !== 0) {
      if (this.movements_list[0].type === 'add_to_basket') {
        var number = this.movements_list[0].number;
        var type = this.movements_list[0].type_of_object;

        if (this.basket[type]) {
          this.basket[type] += number;
        } else {
          this.basket[type] = number;
        }

        this.movements_list.shift();
      }
    }

    if (this.movements_list.length !== 0) {

      if (this.movements_list[0].type === 'collect') {
        var row = parseInt(this.y / 50);
        var column = parseInt(this.x / 50);
        var name = this.movements_list[0].name;

        var position_info = this.position_info(row, column);
     
        if (position_info.type === "collect" && position_info.name === name) {

          if (this.collections[name]) {
            this.collections[name].push([row, column]);
          } else {
            this.collections[name] = [[row, column]];
          }
        }

        this.movements_list.shift();
      }
    }

    if (this.movements_list.length !== 0) {

      if (this.movements_list[0].type === "turn_left") {
        if (this.angle - 90 < 0) {
          this.angle = 270;

        } else {
          this.angle = this.angle - 90;

        }

        this.movements_list.shift();
      }
    }


    if (this.movements_list.length !== 0) {
      if (this.movements_list[0].type === "turn_right") {
        if (this.angle + 90 > 270) {
          this.angle = 0;
        } else {
          this.angle = this.angle + 90;
        }

        this.movements_list.shift();
      }
    }
    if (this.movements_list.length !== 0) {
      if (this.movements_list[0].type === "move") {
        var fx = this.movements_list[0].final_x;
        var fy = this.movements_list[0].final_y;
        var angle = this.movements_list[0].angle;

        //up
        if (angle === 0 || angle === 360) {
          if (this.y - this.movement_y < fy || this.y === fy) {
            this.y = fy;
          } else {
            this.movement_y = this.movement_y + 0.1;
            this.y = this.y - this.movement_y;
          }
        }

        if (angle === 90) {
          if (this.x + this.movement_x > fx || this.x === fx) {
            this.x = fx;
          } else {
            this.movement_x = this.movement_x + 0.1;
            this.x = this.x + this.movement_x;
          }
        }

        //down
        if (angle === 180) {
          if (this.y + this.movement_y > fy || this.y === fy) {
            this.y = fy;
          } else {
            this.movement_y = this.movement_y + 0.1;
            this.y = this.y + this.movement_y;
          }
        }

        if (angle === 270) {
          if (this.x - this.movement_x < fx || this.x === fx) {
            this.x = fx;
          } else {
            this.movement_x = this.movement_x + 0.1;
            this.x = this.x - this.movement_x;
          }
        }

        if (this.x === fx && this.y === fy) {
          this.movements_list.shift();
          this.movement_x = 0;
          this.movement_y = 0;
        }
      }
    }
  };

  reset = () => {
    this.x = this.initial_x;
    this.y = this.initial_y;
    this.movements_list = [];
    this.final_x = this.initial_x;
    this.final_y = this.initial_y;
    this.movement_x = 0;
    this.movement_y = 0;
    this.positions = [];
    this.angle = this.character_info.angle;
    this.change_angle = this.angle;
  }

  position_info = (row, column) => {
    var info = {};

    info.row = row;
    info.column = column;

    if (this.matrix[info.row]) {

      var number = this.matrix[info.row][info.column] - 1;
      if (this.assets[number]) {
        info.type = this.assets[number].type;

        if (this.assets[number].name) {
          info.name = this.assets[number].name;
        }

        if (this.assets[number].connect) {
          info.connect = this.assets[number].connect;
        }
      } else {
        info.type = "space";
        info.name = "blank space";
      }
    } else {
      info.type = 'space';
      info.name = 'blank space';
    }


    return info;
  };
  move = () => {
    this.change_angle = parseInt(this.change_angle);
    if (this.change_angle === 90) {
      this.final_x = this.final_x + 50;

    } else if (this.change_angle === 270) {
      this.final_x = this.final_x - 50;
    } else if (this.change_angle === 180) {
      this.final_y = this.final_y + 50;
    } else if (this.change_angle === 0 || this.change_angle === 360) {
      this.final_y = this.final_y - 50;

    }

    this.movements_list.push({
      type: "move",
      angle: this.change_angle,
      final_x: this.final_x,
      final_y: this.final_y,
    });

    this.debug_list.push({
      type: "move",
      angle: this.change_angle,

      final_x: this.final_x,
      final_y: this.final_y,
    })

    this.positions.push({
      row: parseInt(this.final_y / 50),
      column: parseInt(this.final_x / 50),
    });
  };

  turn_left = () => {
    if (this.change_angle - 90 < 0) {
      this.change_angle = 270;
    } else {
      this.change_angle = this.change_angle - 90;
    }

    this.movements_list.push({
      type: "turn_left",
      angle: this.change_angle,
    });
  };

  turn_right = () => {

    if (this.change_angle + 90 >= 360) {
      this.change_angle = 0;
    } else {
      this.change_angle = this.change_angle + 90;
    }

    this.movements_list.push({
      type: "turn_right",
      angle: this.change_angle,
    });


  };

  collect = (name) => {
    this.movements_list.push({
      type: 'collect',
      name: name
    })

    
  };

}


export default MazePlay;