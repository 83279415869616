var assets = {
    1: {
        "path": "unicorn_left.png",
        "type": "destination",
        "below": "light_blue_tile.png"
    },
    2: {
        "angle": 90,
        "path": "fairy_char",
        "type": "character",
        "below": "dark_blue_tile.png"
    },
    3: {
        "path": "unicorn_left.png",
        "type": "destination",
    },
    4: {
        "path": "maze_obst_yellowstar.png",
        "type": "obstacle"
    },
    5: {
        "path": "dark_blue_tile.png",
        "type": "path"
    },
    6: {
        "path": "light_blue_tile.png",
        "type": "path"
    },
    7: {
        "angle": 90,
        "path": "fairy_char",
        "type": "character",
        "below": "light_blue_tile.png"
    },
    8: {
        "angle": 270,
        "path": "fairy_char",
        "type": "character",
    },
    9: {
        "angle": 90,
        "path": "fairy_char",
        "type": "character",
    },
   10: {
        "path": "unicorn_left.png",
        "type": "destination",
        "below": "dark_blue_tile.png"
    }
}

export default assets;