class Button {
  constructor(ctx, name, width, height, x, y) {
    this.ctx = ctx;
    this.name = name;
    this.width = width;
    this.height = height;
    this.x = x;
    this.y = y;
    this.backgroundColor = '#fff';
    this.borderColor = '#000';
    this.color = '#000';
    this.size = 17;
    this.font = 'Arial';
    this.text = 'Button Text';
    this.setOnClick = false;
    this.visible = true;
    this.onClick = function () {
      return null;
    };
  }

  draw() {
    if (this.visible === true) {
      //drawing the background area
      this.ctx.fillStyle = this.backgroundColor;
      this.ctx.strokeStyle = this.borderColor;
      this.ctx.fillRect(this.x, this.y, this.width, this.height);

      //drawing the text
      this.ctx.fillStyle = this.color;
      this.ctx.textAlign = 'center';
      this.ctx.font = this.size + 'px ' + this.font;
      this.ctx.fillText(
        this.text,
        this.x + this.width / 2,
        this.y + this.height / 2 + 5
      );
    }
  }

  click = (x, y) => {
    if (
      x >= this.x &&
      x <= this.x + this.width &&
      y >= this.y &&
      y <= this.height + this.y
    ) {
      this.onClick();
    }
  };

  setBackgroundColor = (newColor) => {
    this.backgroundColor = newColor;
  };

  setTextColor = (newColor) => {
    this.color = newColor;
  };

  setText = (newText) => {
    this.text = newText;
  };

  setTextSize = (newSize) => {
    this.size = newSize;
  };
}

export default Button;
