import React, { Component } from 'react';
import classes from './ResetPassword.module.css';
import Header from '../../../components/UI/Header/Header';
import * as actions from '../../../store/actions/index';
import { connect } from 'react-redux';
import Spinner from '../../../components/UI/Spinner/Spinner';

class ResetPassword extends Component {


    state = {
        newPassword: '',
        confirmPassword: '',
        success: false
    };


    componentDidMount() {
        this.props.onResetPassword(window.location.pathname);
    }

    handleInputChange = (event) => {
        const { value, name } = event.target;
        this.setState({
            [name]: value
        });
    }

    submitHandler = (event) => {
        event.preventDefault();
        if (this.state.newPassword !== this.state.confirmPassword) {
            alert('Password do not match.');
        } else {
            this.props.onChangePassword(this.props.userData.data._id, this.props.userData.data.resetToken, this.state.newPassword);
            this.setState({ success: true });
        }
    }

    render() {
        let form = <div className={classes.loginBox}>
            <div className={classes.hello}>
                <h4 className={classes.headingText}>CHANGE PASSWORD{this.props.loading}</h4>
                <form onSubmit={this.submitHandler}>

                    <div className={classes.inputDiv}>
                        <p className={[classes.spanText, classes.spanSize].join(' ')}>NEW PASSWORD : </p>
                        <input className={classes.input} name="newPassword"
                            value={this.state.email}
                            onChange={this.handleInputChange}
                            required />
                    </div>

                    <div className={classes.inputDiv}>
                        <p className={[classes.spanText, classes.spanSize].join(' ')}>CONFIRM PASSWORD : </p>
                        <input className={classes.input} name="confirmPassword"
                            value={this.state.email}
                            onChange={this.handleInputChange}
                            required />
                    </div>

                    <div className={classes.buttonDiv}>
                        <button className={classes.button} >CHANGE PASSWORD</button>
                    </div>
                </form>
            </div>
        </div>
        if (this.state.success) {
            this.props.history.push('/');
        }
        if (this.props.loading) {
            form = <Spinner />
        }
        return (
            < div className={classes.container} >
                <div className={classes.header}>
                    <Header color="black" />
                </div>
                {form}
            </div >
        )
    }
}

const mapStateTpProps = state => {
    return {
        loading: state.auth.loading,
        userData: state.auth.userData
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onResetPassword: (url) => dispatch(actions.resetPasswordLink(url)),
        onChangePassword: (id, resetToken, newPassword) => dispatch(actions.changePasswordEmail(id, resetToken, newPassword))
    }
}

export default connect(mapStateTpProps, mapDispatchToProps)(ResetPassword);