
import React from 'react';
import Header from '../../components/UI/Header/Header';
import MazeContainer from '../../Apps/Maze/MazeContainer';
import StoryContainer from '../../Apps/Story/StoryContainer';
import { withRouter } from 'react-router-dom';

class Exercise extends React.Component {

    render() {
        var lab = null;

        if (localStorage.getItem("lab") === 'story') {
            lab = <StoryContainer />
        }
        if (localStorage.getItem("lab") === 'maze') {
            lab = <MazeContainer />
        }
        return (
            <React.Fragment>
                <Header color="#F2F2F2" Backtitle={localStorage.getItem("lab")} />
                {lab}
            </React.Fragment>
        );
    }
}

export default withRouter(Exercise);
