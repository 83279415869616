class Shape {
    constructor(ctx, name, type, x, y, width, height, radius, color){
        this.ctx = ctx;
        this.name = name;
        this.type = type;
        this.x = x;
        this.y = y;
        this.width = width;
        this.height = height;
        this.radius = radius;
        this.color = color;
    }

    draw(){
        if(this.type === 'rectangle'){
            this.ctx.fillStyle = this.color;
            this.ctx.fillRect(this.x, this.y, this.width, this.height);
        }

        if(this.type === 'circle'){
            this.ctx.beginPath();
            this.ctx.fillStyle = this.color;
            this.ctx.arc(this.x, this.y, this.radius, 0, 2 * Math.PI);
            this.ctx.fill();
        }
    }

}

export default Shape;