/* eslint no-eval: 0 */
/*eslint no-useless-concat: 0*/
/*eslint no-unused-vars: 0*/
/*eslint no-redeclare: 0*/
/*eslint array-callback-return: 0*/
import React from 'react';
import swal from 'sweetalert';
import _ from 'lodash';
import Cookie from 'js-cookie';

import './StoryContainer.css';
import { Components } from '../../components/Components';
import { Assets } from '../../components/Components';
import BlocklyContainer from '../BlocklyContainer';
import BlocklyJS from "blockly/javascript";
import RunButton from '../RunButton';
import Story from './Story';
import '../../blocks/BlockFunctions';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/index';
import { withRouter } from 'react-router-dom';
import { Title } from '../../components/Title/Title';
import Spinner from '../../components/UI/Spinner/Spinner';




class StoryContainer extends React.Component {
    _keydownhandler = [];
    constructor(props) {
        super(props);
        this.totalLevel = Object.keys(Components[this.props.match.params.exerciseName.replace(/ /g, "")].default);
        this.exerciseTitle = this.props.match.params.exerciseName;
        this.exerciseId = this.props.match.params.exerciseId;
        this.level = this.props.match.params.level;
        this.BlocklyContainer = BlocklyContainer;
        this.sprite = {};
        this.storyCanvas = React.createRef();
        this.requestId = [];
        this.clearClick = [];
        this.text_box_list = {};
        this.images_folder = "../../../images/StoryLab/";

    }

    state = {
        level: Components[this.props.match.params.exerciseName.replace(/ /g, "")].default[this.props.match.params.level],
        assets: Assets[this.props.match.params.exerciseName.replace(/ /g, "")].default,
        reload: false,
        background: "",
        levels: [],
    };



    componentDidMount() {
        localStorage.setItem("ExerciseName", this.props.match.params.exerciseName);
        localStorage.setItem("ExerciseLevel", this.props.match.params.level);
        const userId = Cookie.get('userId');
        this.props.resetSubmitExercise();
        this.props.fetchLevel(userId, this.props.match.params.exerciseId, this.props.match.params.level);
        document.getElementById(this.props.match.params.level).style.backgroundColor = '#FDCD4C';
        document.getElementById(this.props.match.params.level).style.border = '1px solid #FDCD4C';
        const canvas = this.storyCanvas.current;
        canvas.addEventListener('mousemove', function (evt) {
            var rect = canvas.getBoundingClientRect();
            var mousePos = {
                x: evt.clientX - rect.left,
                y: evt.clientY - rect.top
            };
            document.getElementById('message').innerHTML = 'X:' + parseInt(mousePos.x) + ' Y: ' + parseInt(mousePos.y);

        }, false);
        const ctx = canvas.getContext('2d');
        var story = new Story(ctx, '', 0, 0, 100, 100, this.state.level);
        var intervalId = setInterval(this.gameLoop.bind(this, ctx), 10);
        this.setState({ intervalId: intervalId, ctx: ctx, story: story, canvas: canvas });
    }

    componentDidUpdate(prevProps) {

        window.onpopstate = e => {
            localStorage.setItem("ExerciseLevel", 1);
            window.location.reload();
        }
        if (_.isEqual(prevProps.levels, this.props.levels) === false) {
            document.getElementById(this.props.match.params.level).style.backgroundColor = '#FDCD4C';
            document.getElementById(this.props.match.params.level).style.border = '1px solid #FDCD4C';

            this.setState({
                levels: this.props.levels
            })
        }
    }


    createSprite = (name, path, x, y, w = 80, h = 80, text = "") => {
        this.sprite[name] = new Story(this.state.ctx, path, x, y, w, h, this.state.level, text);
    }

    clearRequestList(requestId) {
        this.requestId.push(requestId);
    }


    changeSprite = (oldSpriteName, selectedSprite, x, y) => {
        if (Object.keys(this.sprite).length !== 0) {
            Object.keys(this.sprite).forEach(t => {
                if (oldSpriteName === t) {
                    this.sprite[t].path = selectedSprite;
                    if (!isNaN(x)) {
                        this.sprite[t].x = x;
                    }
                    if (!isNaN(y)) {
                        this.sprite[t].y = y;
                    }
                    // this.createSprite(newSpriteName, "astrostory_270.png", this.sprite[t].x, this.sprite[t].y);
                    // delete this.sprite[t];
                }
            })
        }
    }

    gameLoop = (ctx) => {
        if (this.state.background.length !== 0) {
            var background = new Image();
            background.src = "../../../images/" + this.state.background;
            this.state.ctx.drawImage(background, 0, 0, 360, 550);
        }
        eval(this.state.level.initial_code);
        if (Object.keys(this.sprite).length !== 0) {
            Object.keys(this.sprite).forEach(t => {
                this.sprite[t].draw();
                this.sprite[t].update();
            })
        }
        if (Object.keys(this.text_box_list).length !== 0) {
            Object.keys(this.text_box_list).forEach(t => {
                //console.log(this.text_box_list[t].x);

                var imageObj = new Image();
                imageObj.src = "../../../images/StoryLab/" + this.text_box_list[t].path;
                this.state.ctx.drawImage(imageObj, this.text_box_list[t].x, this.text_box_list[t].y);
                this.state.ctx.font = "5pt";
                let newSentence = this.text_box_list[t].textValue.split(" ");
                let finalSentence = "";
                for (var i = 0; i < newSentence.length; i++) {
                    if (i % 4 === 0) {
                        finalSentence += "\n";
                    }
                    finalSentence += newSentence[i] + " ";
                }
                var lines = finalSentence.split('\n');
                var y = this.text_box_list[t].y + imageObj.height / 8;
                for (var i = 0; i < lines.length; i++) {
                    if (this.text_box_list[t].path.includes("right"))
                        this.state.ctx.fillText(lines[i], this.text_box_list[t].x + 10, y);
                    else
                        this.state.ctx.fillText(lines[i], this.text_box_list[t].x + 30, y);

                    y += 15;
                }

            })
        }

    }


    reset = () => {
        window.removeEventListener('keydown', this._keydownhandler[0]);
        if (this._keydownhandler.length > 1) {
            window.removeEventListener("keydown", this._keydownhandler[1]);
        }
        if (this._keydownhandler.length > 2) {
            window.removeEventListener("keydown", this._keydownhandler[2]);
        }
        if (this._keydownhandler.length > 3) {
            window.removeEventListener("keydown", this._keydownhandler[3]);
        }

        this.requestId.forEach(requestId => {
            window.cancelAnimationFrame(requestId);
        })

        this._keydownhandler = [];
        this.sprite = {};
        this.text_box_list = {};
        Object.keys(this.text_box_list).forEach(s => {
            this.text_box_list[s].x = 0;
            this.text_box_list[s].y = 0;
            this.text_box_list[s].path = "";
            this.text_box_list[s].name = "";
            this.text_box_list[s].textValue = "";

        });
        Object.keys(this.sprite).forEach(s => {
            this.sprite[s].x = 0;
            this.sprite[s].y = 0;
            this.sprite[s].animation_l = {
                'north': 0,
                'east': 0,
                'west': 0,
                'south': 0,
                'g_scale_height': 0,
                'g_scale_width': 0,
                's_scale_height': 0,
                's_scale_width': 0
            };
            this.sprite[s].actions = [];
            this.sprite[s].target_x = 0;
            this.sprite[s].target_y = 0;
            this.sprite[s].width = 0;
            this.sprite[s].height = 0;
            this.sprite[s].dx = 0;
            this.sprite[s].dy = 0;
        });

    }

    submit = () => {
        var result = this.state.story.submit(this.sprite, this.state.background);
        const userId = Cookie.get('userId');
        if (result.length > 0) {
            swal({
                title: "Oops!",
                text: result[0].message,
                icon: "error",
                button: "Try Again!",
            });
        } else {
            swal({
                title: "Good Job!!",
                text: "Successfully completed this level",
                icon: "success",
                button: "Next Level",
            }).then(name => {
                this.props.submitExercise(userId, this.props.match.params.exerciseId, this.props.match.params.level);
            });

        }
    }

    onKeyPressed = (keyType, direction, gap, speed, sprite_name) => {
        this._keydownhandler.push(this.keyDownHandler(keyType, direction, gap, speed, sprite_name));
        window.addEventListener("keydown", this._keydownhandler[0]);
        if (this._keydownhandler.length > 1) {
            window.addEventListener("keydown", this._keydownhandler[1]);
        }
        if (this._keydownhandler.length > 2) {
            window.addEventListener("keydown", this._keydownhandler[2]);
        }
        if (this._keydownhandler.length > 3) {
            window.addEventListener("keydown", this._keydownhandler[3]);
        }
    }

    onClick = (sprite) => {
        this.clearClick.push(sprite);
    }


    onRunClickHandler = () => {
        let self = this;
        this.reset();
        var code = BlocklyJS.workspaceToCode(this.BlocklyContainer.primaryWorkspace);
        // console.log(code);
        try {
            eval(code);
        } catch (err) {
            console.log(err)
        }


    }

    createTextBox = (path, name, x, y, textValue) => {
        this.text_box_list[name] = {
            path: path,
            x: x,
            y: y,
            textValue: textValue
        }
    }



    onLevelChanged = (index) => {
        localStorage.setItem("ExerciseLevel", index);
        window.location = '/exercise/' + this.props.match.params.exerciseName + '/' + this.props.match.params.exerciseId + '/' + index;
    }


    keyDownHandler = (keyType, direction, gap, speed, sprite_name) => {
        var self = this;
        return (e) => {
            // e.preventDefault();
            if (keyType === "right") {
                if (e.key === "Right" || e.key === "ArrowRight") {
                    eval("self.sprite." + sprite_name + ".degree=" + Number(direction));
                    eval("self.sprite." + sprite_name + ".move_forward();");
                }
            }

            if (keyType === "left") {
                if (e.key === "Left" || e.key === "ArrowLeft") {
                    eval("self.sprite." + sprite_name + ".degree=" + Number(direction));
                    eval("self.sprite." + sprite_name + ".move_forward();");
                }
            }

            if (keyType === "up") {
                if (e.key === "Up" || e.key === "ArrowUp") {
                    eval("self.sprite." + sprite_name + ".degree=" + Number(direction));
                    eval("self.sprite." + sprite_name + ".move_forward();");
                }
            }

            if (keyType === "down") {
                if (e.key === "Down" || e.key === "ArrowDown") {
                    eval("self.sprite." + sprite_name + ".degree=" + Number(direction));
                    eval("self.sprite." + sprite_name + ".move_forward();");
                }
            }
        }



    }



    render() {

        // if (this.props.loading) {
        //     return <Spinner />
        // }

        if (this.props.exerciseSuccess) {
            if (parseInt(this.level) < this.totalLevel.length) {
                this.props.history.push('/exercise/' + this.props.match.params.exerciseName + '/' + this.props.match.params.exerciseId + '/' + (parseInt(this.level) + 1).toString());
                window.location.reload();
            } else {
                window.location = "/maze";
                // window.location.reload();
            }
        }

        let totalLevel = this.totalLevel.map((index) => {
            if (this.props.levels === '') {
                return <div key={index} className="levelCircle" id={index} onClick={() => this.onLevelChanged(index)}></div>
            } else {
                if (this.props.levels[index - 1]) {
                    if (this.props.levels[index - 1].status === "1") {
                        return <div key={index} className="levelCircle done" id={index} onClick={() => this.onLevelChanged(index)}></div>
                    }
                }
                return <div key={index} className="levelCircle" id={index} onClick={() => this.onLevelChanged(index)}></div>
            }

        })


        return (
            <div className="container">
                <Title title={"ITK - " + this.props.match.params.exerciseName.replace(/([A-Z])/g, ' $1').trim() + " Level " + this.props.match.params.level} />
                <div className='levelDiv'>
                    {totalLevel}
                </div>
                <h3 style={{ color: '#06A199', marginTop: "-20px" }}>{this.props.match.params.exerciseName.replace(/([A-Z])/g, ' $1').trim()}</h3>
                <div className="grid-maze">
                    <div className="preview">
                        <canvas ref={this.storyCanvas} width={360} height={550} style={{ 'border': "1px solid black" }} />
                        <br />
                        <div className="btnSection" style={{ width: '360px' }}>
                            <div>
                                <RunButton runClickHandler={this.onRunClickHandler.bind(this)} />
                                <button onClick={this.reset} className="btn">Reset</button>
                            </div>
                            <div>
                                <button onClick={this.submit} className="btn" style={{ backgroundColor: 'red' }}>Submit</button>
                            </div>
                        </div>
                        <p id="message" style={{ marginTop: '20px' }}>{this.message}</p>
                    </div>
                    <BlocklyContainer levels={this.state.levels} initialXml='' blocks={this.state.level.blocks} level={this.props.match.params.level} exerciseId={this.props.match.params.exerciseId} />
                </div>

            </div >
        )
    }
}

const mapDispatchToProps = dispatch => {
    return {
        submitExercise: (userId, exerciseId, level) => dispatch(actions.submitExercise(userId, exerciseId, level)),
        fetchLevel: (userId, exerciseId, level) => dispatch(actions.fetchLevel(userId, exerciseId, level)),
        resetSubmitExercise: () => dispatch(actions.resetSubmitExercise())

    }
}

const mapStateToProps = (state) => {
    return {
        levels: state.userExercise.levels,
        exerciseSuccess: state.userExercise.exerciseSuccess,
        loading: state.userExercise.loading
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(StoryContainer));