import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../helpers/utility';

const initialState = {
    exerciseId: null,
    levels: '',
    error: null,
    exerciseSuccess: false,
    loading: false
}

const fetchSuccess = (state, action) => {
    return updateObject(state, {
        error: null,
        levels: action.levelInfo.levels,
        loading: false
    })
}

const fetchFailed = (state, action) => {
    return updateObject(state, {
        error: action.error,
        loading: false
    })
}

const submitExerciseSuccess = (state, action) => {
    return updateObject(state, {
        exerciseSuccess: action.complete,
        loading: false
    })
}

const resetSubmitExercise = (state, action) => {
    return updateObject(state, {
        exerciseSuccess: false,
        loading: false
    })
}

const fetchStart = (state, action) => {
    return updateObject(state, {
        loading: true
    })
}


const submitExerciseStart = (state, action) => {
    return updateObject(state, {
        loading: true
    })
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_LEVEL_SUCCESS: return fetchSuccess(state, action);
        case actionTypes.FETCH_LEVEL_START: return fetchStart(state, action);
        case actionTypes.FETCH_LEVEL_FAILED: return fetchFailed(state, action);
        case actionTypes.SUBMIT_EXERCISE_SUCCESS: return submitExerciseSuccess(state, action);
        case actionTypes.SUBMIT_EXERCISE_START: return submitExerciseStart(state, action);
        case actionTypes.SUBMIT_EXERCISE_RESET: return resetSubmitExercise(state, action);
        default: return state;
    }
}

export default reducer;