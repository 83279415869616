/*eslint array-callback-return: 0*/
import React from "react";
import BlocklyComponent, {
  Block,
  Category
} from "../components/Blockly";
import equal from 'fast-deep-equal';

import '../blocks/MazeCreator/BlockDefinations';
import '../blocks/MazeCreator/BlockFunctions';
import "../blocks/BlockDefinations";
import "../blocks/BlockFunctions";
import "../blocks/GameLab/BlockDefinitions";
import "../blocks/GameLab/BlocklyFunctions";



class BlocklyContainer extends React.Component {

  state = {
    level: '',
    xml: ''
  }


  getXml() {
    if (this.props.levels) {
      if (this.props.levels.length > 0) {
        const level = this.props.levels.filter(l => l.level === this.props.level);
        return level[0].xml;
      }
    } else if (this.props.projectId) {

    }
    return '';
  }

  componentDidUpdate(prevProps) {
    if (this.props.level) {
      if (equal(prevProps.levels, this.props.levels) === false) {
        if (this.props.levels) {
          if (this.props.levels.length > 0) {
            const level = this.props.levels.filter(l => l.level === this.props.level);
            if (level[0].xml.length > 0) {
              this.setState({
                xml: level[0].xml
              })
            } else {
              this.setState({
                xml: this.props.initialXml
              })
            }
          }
        }

      }
    } else if (this.props.projectId) {
      if (this.props.initialXml !== '') {
        if (equal(prevProps.initialXml, this.props.initialXml) === false) {
          this.setState({
            xml: this.props.initialXml
          })
        }
      }
    }

  }

  render() {

    var blocks = [];

    if (!Array.isArray(this.props.blocks)) {
      var i = 0;
      Object.keys(this.props.blocks).forEach((category) => {
        i++;
        var j = 0;
        if (category === 'Variables') {
          blocks.push(
            <Category key={i} name={category} custom={(category === 'Variables' ? 'VARIABLE' : '')}>
              {this.props.blocks[category].map((block) => {
                j++;

                return (<Block key={j} type={block}></Block>);
              })}
            </Category>

          )
        } else {
          blocks.push(
            <Category key={i} name={category} custom={(category === 'Functions' ? 'PROCEDURE' : '')}>
              {this.props.blocks[category].map((block) => {
                j++;

                return (<Block key={j} type={block}></Block>);
              })}
            </Category>

          )
        }

      });

    } else {
      var l = 0;
      this.props.blocks.map((block) => {
        l++;
        blocks.push(
          <Block key={l} type={block}></Block>
        )
      })

    }

    var blocklyComponent = ''
    if (this.props.projectId) {
      blocklyComponent = (
        <BlocklyComponent
          ref={(e) => { this.simpleWorkspace = e }}
          readOnly={false}
          media={"media/"}
          move={{
            scrollbars: true,
            drag: true,
            wheel: true,
          }}
          initialXml={this.state.xml}
          projectId={this.props.projectId}
          data={this.props.data}
          blockSave={this.props.blockSave}>
          {blocks}
        </BlocklyComponent>

      )
    } else {
      // console.log(this.state.xml);
      blocklyComponent = (
        <BlocklyComponent
          ref={(e) => { this.simpleWorkspace = e }}
          readOnly={false}
          move={{
            scrollbars: true,
            drag: true,
            wheel: true,
          }}
          initialXml={this.state.xml}
          exerciseId={this.props.exerciseId}
          level={this.props.level}>
          {blocks}
        </BlocklyComponent>

      )
    }
    var style = {
      'width': "100%"
    }

    if(this.props.marginTop){
      style.marginTop = this.props.marginTop;
    }

    return (
      <div className="App1" style = {style}>
        {blocklyComponent}
      </div>
    );
  }
}



export default BlocklyContainer;
