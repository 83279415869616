/* eslint no-eval: 0 */
/*eslint no-useless-concat: 0*/
/*eslint no-unused-vars: 0*/
/*eslint no-redeclare: 0*/
/*eslint no-self-assign: 0*/
/*eslint array-callback-return: 0*/
class Story {
    constructor(ctx, path, x = 0, y = 0, w = 100, h = 100, level, text = "", color = "#fff", textColor = "#000", textSize = '10') {
        this.level = level;
        this.ctx = ctx;
        this.path = path;
        this.background = "";
        this.images_folder = "../../../images/StoryLab/";
        this.original_width = 100;
        this.original_height = 100;
        this.height = parseInt(h);
        this.width = parseInt(w);
        this.x = parseInt(x);
        this.y = parseInt(y);
        this.start_x = parseInt(x);
        this.start_y = parseInt(y);
        this.animation_list = {
            'north': 0,
            'east': 0,
            'west': 0,
            'south': 0,
            'g_scale_height': 0,
            'g_scale_width': 0,
            's_scale_height': 0,
            's_scale_width': 0
        };
        this.dx = 5;
        this.dy = 5;
        this.actions = [];
        this.degree = 0;
        this.current_face = 0;
        this.target_x = 0;
        this.target_y = 0
        this.c = 0;
        this.speed = 2;
        this.speed_down = 2;
        this.speed_up = 2;
        this.speed_left_right = 2;
        this.speed_up_down = 2;
        this.surface = '';
        this.show_x = parseInt(x);
        this.show_y = parseInt(y);
        this.textValue = text;
        this.color = color;
        this.textColor = textColor;
        this.textSize = textSize;
    }

    draw = () => {
        if (!this.path.includes('speech_bubble') && this.path !== "") {
            var spritez = new Image();
            spritez.src = this.images_folder + this.path;
            this.height = spritez.height;
            this.width = spritez.width;
            this.ctx.drawImage(spritez, this.x, this.y, spritez.width, spritez.height);
        }
        else if (this.path === "") {
            const path = new Path2D()
            path.rect(this.x, this.y, this.width, this.height)
            path.closePath()
            this.ctx.fillStyle = this.color;
            this.ctx.fill(path)
            this.ctx.lineWidth = 1
            this.ctx.strokeStyle = "black"
            this.ctx.fillStyle = this.textColor;
            this.ctx.font = this.textSize + "px Verdana";;
            this.ctx.fillText(this.textValue, this.x + (this.width / 2) - 10, this.y + (this.height / 2));
            this.ctx.stroke(path)
        }
        else {
            var imageObj = new Image();
            imageObj.src = this.images_folder + this.path;
            this.ctx.drawImage(imageObj, this.x, this.y, this.width, this.height);
            this.ctx.font = "5pt";
            this.ctx.fillText('YYYYYYYY', this.x + 10, this.y);
            let newSentence = this.textValue.split(" ");
            let finalSentence = "";
            for (var i = 0; i < newSentence.length; i++) {
                if (i % 4 === 0) {
                    finalSentence += "\n";
                }
                finalSentence += newSentence[i] + " ";
            }
            var lines = finalSentence.split('\n');
            var y = this.y + 5;
            for (var i = 0; i < lines.length; i++) {
                if (this.path.includes("right"))
                    this.ctx.fillText(lines[i], this.x + 10, y);
                else
                    this.ctx.fillText(lines[i], this.x + 30, y);

                y += 15;
            }
        }
    }

    submit = (sprite, background) => {
        var errors = [];
        if (this.level.validations) {
            if (this.level.validations.length > 0) {
                this.level.validations.forEach((validation) => {
                    if (validation === 'error_background') {
                        if (!this.level.test_cases.background.includes(background)) {
                            errors.push({
                                type: true,
                                message: 'Please select correct background of story'
                            })
                        }
                    }

                    if (validation === 'error_sprite_events') {
                        var count = 0;
                        if (Object.keys(sprite).length !== 0) {
                            Object.keys(sprite).forEach(t => {
                                this.level.test_cases.events.forEach(data => {
                                    if (sprite[t].path.includes(Object.keys(data))) {
                                        // console.log(sprite[t].path.includes(Object.keys(data)), sprite[t].path, sprite[t], sprite[t].animation_list, count, data[Object.keys(data)]);
                                        if (sprite[t].animation_list[data[Object.keys(data)]]) {
                                            // console.log(count);
                                            count += 1;
                                        }
                                    }
                                })
                            })
                            if (count < this.level.test_cases.events.length) {
                                // console.log(count, 'count');
                                errors.push({
                                    type: true,
                                    message: 'You have not used all events.'
                                })
                            } else {
                                // console.log(count);

                            }
                        }
                    }



                    if (validation === 'error_sprite_dest') {
                        var curr_sprite;
                        if (Object.keys(sprite).length !== 0) {
                            Object.keys(sprite).forEach(t => {
                                if (sprite[t].animation_list.move_forward) {
                                    curr_sprite = t;
                                };
                            })
                        }
                        if (curr_sprite) {
                            var x = eval('sprite.' + curr_sprite + '.x');
                            var y = eval('sprite.' + curr_sprite + '.y');
                            if (!(x + this.width >= this.level.test_cases.dest_x && x <= this.level.test_cases.dest_y + 100) || !(y + this.height >= this.level.test_cases.dest_y && y <= this.level.test_cases.dest_y + 100)) {
                                errors.push({
                                    type: true,
                                    message: 'You have not reached to destination'
                                })
                            }
                        } else {
                            errors.push({
                                type: true,
                                message: 'You have not reached to destination'
                            })
                        }
                    }

                    if (validation === 'error_sprite_xy') {
                        var source_sprite;
                        if (Object.keys(sprite).length !== 0) {
                            Object.keys(sprite).forEach(t => {
                                if (sprite[t].path.includes(this.level.test_cases.sprite)) {
                                    source_sprite = t;
                                };
                            })
                        }
                        if (source_sprite) {
                            x = eval('sprite.' + source_sprite + '.start_x');
                            y = eval('sprite.' + source_sprite + '.start_y');
                            if (!(x >= this.level.test_cases.x && x <= this.level.test_cases.x + 65) || !(y >= this.level.test_cases.y && y <= this.level.test_cases.y + 40)) {
                                errors.push({
                                    type: true,
                                    message: 'You have created sprite on wrong xy position'
                                })
                            }
                        } else {
                            errors.push({
                                type: true,
                                message: 'You have created sprite on wrong xy position'
                            })
                        }

                    }

                    if (validation === 'error_sprite_events_general') {
                        var count = 0;
                        if (Object.keys(sprite).length !== 0) {
                            Object.keys(sprite).forEach(t => {
                                this.level.test_cases.events_general.forEach(data => {
                                    console.log(sprite[t].animation_list[data], count);
                                    if (sprite[t].animation_list[data]) {
                                        count += 1;
                                    }
                                })
                            })
                            if (count !== this.level.test_cases.events_general.length) {
                                errors.push({
                                    type: true,
                                    message: 'You have not used all events.'
                                })
                            } else {
                                // console.log(count);

                            }
                        }
                    }

                });
            }
        }
        return errors;
    };


    update = () => {


        if (this.animation_list.jitter === true) {
            this.jitter();
        }

        if (this.animation_list.hide === true) {
            this.hide();
        }

        if (this.animation_list.move_random === true) {
            this.move_random();
        }

        if (this.animation_list.hasOwnProperty('growing') && this.animation_list.g_scale_height > 0 && this.animation_list.g_scale_width > 0) {
            this.growing(this.animation_list.growing, false);
        }

        if (this.animation_list.shrinking === true) {
            if (this.width > this.animation_list.s_scale_width) {
                this.width -= 1;
            }

            if (this.height > this.animation_list.s_scale_height) {
                this.height -= 1;
            }

            if (this.animation_list.s_scale_height === this.height && this.animation_list.s_scale_width === this.width) {
                this.animation_list.shrinking = false;
            }
        }

        if (this.animation_list.move_left_right === true) {
            this.move_left_right(this.speed_left_right);
        }

        if (this.animation_list.if_right_edge === true) {
            this.if_right_edge();
        }

        if (this.animation_list.if_right_edge === true) {
            this.if_on_edge(this.surface);
        }

        if (this.animation_list.if_left_edge === true) {
            this.if_left_edge();
        }

        if (this.animation_list.move_up_down === true) {
            this.move_up_down(this.speed_up_down);
        }
        if (this.animation_list.if_edge_bounce === true) {
            this.if_edge_bounce();
        }

        if (this.animation_list.move_east === true) {
            this.move_east(this.animation_list.move_east);
        }



        if (this.animation_list.move_west === true) {
            this.move_west(this.animation_list.move_west);
        }

        if (this.animation_list.move_north === true) {
            this.move_north(this.animation_list.move_north);
        }

        if (this.animation_list.move_south === true) {
            this.move_south(this.animation_list.move_south);
        }

        if (this.animation_list.move_down === true) {
            this.move_down(this.animation_list.move_down);
        }

        //Edge Moving
        if (this.animation_list.move_east_edge === true) {
            this.move_east_edge(this.animation_list.move_east_edge);
        }

        if (this.animation_list.move_west_edge === true) {
            this.move_west_edge(this.animation_list.move_west_edge);
        }

        if (this.animation_list.move_north_edge === true) {
            this.move_north_edge(this.speed_up);
        }

        if (this.animation_list.move_south_edge === true) {
            this.move_south_edge(this.speed_down);
        }

        if (this.animation_list.isTouch === true) {
            this.isTouching(this.animation_list.isTouching);
        }

        if (this.animation_list.hasOwnProperty('move_towards')) {
            this.move_towards(this.animation_list.move_towards);
        }

        if (this.animation_list.hasOwnProperty('south') && this.animation_list.south !== 0) {
            this.move('south');
        }

        if (this.animation_list.hasOwnProperty('north') && this.animation_list.north !== 0) {
            this.move('north');
        }

        if (this.animation_list.hasOwnProperty('west') && this.animation_list.west !== 0) {
            this.move('west');
        }

        if (this.animation_list.hasOwnProperty('east') && this.animation_list.east !== 0) {
            this.move('east');
        }


        if (this.animation_list.change_angle != null) {
            this.path = "images/" + this.animation_list.change_angle + ".png"
            this.degree = this.animation_list.change_angle;
        }

        if (this.animation_list.move_forward === true) {
            if (this.actions.length !== 0) {
                let action = this.actions[0];
                if (action.func === 'move') {
                    let sprite = new Image();
                    sprite.src = this.path;
                    //this.ctx.drawImage(sprite, action.frame_x, action.frame_y, this.width, this.height);
                    if (action.x !== action.target_x) {
                        action.x = action.x + action.frames_x;
                        this.x = action.x;
                        // console.log(this.x, action.target_x)
                    }

                    if (action.y !== action.target_y) {
                        action.y = action.y + action.frames_y;
                        this.y = action.y;
                    }

                    if (parseFloat(action.x.toFixed(2)) === parseFloat(action.target_x) && parseFloat(action.y.toFixed(2)) === parseFloat(action.target_y)) {
                        this.actions.shift();
                    }
                }

            }
        };
    }


    /* events */


    onClick = (x, y) => {
        console.log(x > this.x, x < this.x + parseInt(this.width), y > this.y, y < this.y + parseInt(this.height), y, this.y);
        var x = Math.abs(x);
        var y = Math.abs(y);
        //console.log(x, y, this.height, this.y, this.x);
        if (x > this.x && x < this.x + parseInt(this.width) && y > this.y && y < this.y + parseInt(this.height)) {
            //console.log('yes');
            return true;
        }
        else {
            return false;
        }
    };

    change_width = (width) => {
        this.width = width;
    };

    change_height = (height) => {
        this.height = height;
    };

    change_x = (x) => {
        this.x += x;
    };

    change_y = (y) => {
        this.y += y;
    };

    setSize = (p) => {
        this.width = p;
        this.height = p;
    }

    set_speed = (speed) => {
        this.dx = speed;
        this.dy = speed;
    };

    jump_location = (x, y) => {
        this.x = x;
        this.y = y;
    }
    get_x = () => {
        return this.x;
    };

    get_y = () => {
        return this.y;
    };

    get_width = () => {
        return this.width;
    };

    get_height = () => {
        return this.height;
    };


    /* movement in directions */

    move_to = (x, y) => {
        this.animation_list.move_to = [x, y];
    }

    move_south = () => {
        this.y += this.speed;
        this.animation_list.move_south = true;
    };

    move_down = () => {
        if (this.y + this.height < 500) {
            this.y += this.speed;
            this.animation_list.move_down = true;
        }
    };

    move_north = () => {
        this.y -= this.dy;
        this.animation_list.move_north = true;
    };

    move_east = () => {
        this.x += this.dx;
        this.animation_list.move_east = true;
    };

    move_west = () => {
        this.x -= this.dx;
        this.animation_list.move_west = true;
    };

    move_south_edge = (speed) => {
        this.speed_down = speed;
        if (this.y + this.height < 530) {
            this.y += this.speed_down;
            // reach = this.y + this.width;
            this.animation_list.move_south_edge = true;
        }
    };

    move_north_edge = (speed) => {
        this.speed_up = speed;
        if (this.y > 10) {
            this.y -= this.speed_up;
            this.animation_list.move_north_edge = true;
        }
    };

    move_east_edge = () => {
        if (this.x < 420) {
            this.x += this.speed / 10;
            this.animation_list.move_east_edge = true;
        }
        else {
            this.change_angle(270);
            this.move_north_edge();
        }
    };

    move_west_edge = () => {
        if (this.x > 10) {
            this.x -= this.speed / 10;
            this.animation_list.move_west_edge = true;
        }
    };

    change_angle = (angle) => {

        this.animation_list.change_angle = angle;
    }
    set_target = (pixels, direction) => {
        if (direction === 'north') {
            this.animation_list.north += pixels;
        }

        if (direction === 'south') {
            this.animation_list.south += pixels;
        }

        if (direction === 'west') {
            this.animation_list.west += pixels;
        }

        if (direction === 'east') {
            this.animation_list.east += pixels;
        }

    }

    move = (direction) => {
        if (direction === 'north') {
            this.y -= 1;
            this.animation_list.north -= 1;
        }

        if (direction === 'south') {
            this.y += 1;
            this.animation_list.south -= 1;
        }

        if (direction === 'west') {
            this.x -= 1;
            this.animation_list.west -= 1;
        }

        if (direction === 'east') {
            this.x += 1;
            this.animation_list.east -= 1;
        }

    }


    /* behaviors */
    jitter = () => {
        this.x += Math.random() < 0.5 ? -1 : 1;
        this.animation_list.jitter = true;
    };


    growing = (scale, set = true) => {
        if (set === true) {
            let scaled = this.scaleBetween(scale, 1, 3, 1, 100);
            this.animation_list.g_scale_width = this.width * scaled;
            this.animation_list.g_scale_height = this.height * scaled;
        } else {
            this.animation_list.g_scale_width -= 1;
            this.animation_list.g_scale_height -= 1;
        }

        this.width += 1;
        this.height += 1;
        this.animation_list.growing = scale;
    };

    scaleBetween = (unscaledNum, minAllowed, maxAllowed, min, max) => {
        return (maxAllowed - minAllowed) * (unscaledNum - min) / (max - min) + minAllowed;
    }

    shrinking = (scale = 2) => {

        this.animation_list.s_scale_width = this.original_width / scale;
        this.animation_list.s_scale_height = this.original_height / scale;

        this.animation_list.shrinking = true;
    };

    move_up_down = (speed) => {
        this.speed_up_down = speed;
        this.y -= this.speed_up_down;
        if (this.y + this.height > 536 || this.y < 0) {
            this.speed_up_down = -this.speed_up_down;
        }
        this.animation_list.move_up_down = true;
    }

    move_left_right = (speed) => {
        this.speed_left_right = speed;
        this.x -= this.speed_left_right;
        if (this.x + this.width > 399 || this.x < 0) {
            this.speed_left_right = -this.speed_left_right;
        }
        this.animation_list.move_left_right = true;
    }

    if_on_edge = (surface) => {
        this.surface = surface;
        this.animation_list.if_right_edge = true;
        if (surface === 'right') {
            if (this.x + parseInt(this.width) >= 360) {
                return true;
            }
            return false;
        }
        if (surface === 'left') {
            if (this.x < 10) {
                return true;
            }
            return false;
        }
        if (surface === 'top') {
            if (this.y < 10) {
                return true;
            }
            return false;
        }

        if (surface === 'bottom') {
            if (this.y + parseInt(this.height) >= 500) {
                return true;
            }
            return false;
        }
    }

    if_right_edge = () => {
        this.animation_list.if_right_edge = true;
        if (this.x + this.width >= 360) {
            return true;
        }
        return false;
    }

    if_left_edge = () => {
        this.animation_list.if_left_edge = true;
        if (this.x + this.width <= 0) {
            return true;
        }
        return false;
    }

    if_edge_bounce = () => {
        if (this.x + this.width > 399 || this.x < 0) {
            this.dx = -this.dx;
        }

        if (this.y + this.height > 399 || this.y < 0) {
            this.dy = -this.dy;
        }

        this.animation_list.if_edge_bounce = true;
    };

    /* stop animation */

    stop_animation = (behavior) => {
        this.animation_list[behavior] = false;
    };

    stop_all_animations = () => {
        this.animation_list = [];
    }

    // /* events */
    // onClick = (x, y) => {
    //     if (x > this.x && x < this.x + this.width && y > this.y && y < this.y + this.height) {
    //         return true;
    //     }
    //     else {
    //         return false;
    //     }
    // };

    //collide
    isTouching = (others) => {
        this.animation_list.isTouch = true;
        this.animation_list.isTouching = others;
        if (others.x >= 1000 || others.y >= 1000) {
            return false;
        }
        // console.log(parseInt(this.x), this.width, others.x, others.x + others.width, parseInt(this.y) + parseInt(this.height), others.y, others.y + others.height, others.height, others.width, this.path, others.path);
        if (this.x + parseInt(this.width) >= parseInt(others.x) && this.x <= parseInt(others.x) + parseInt(others.width) && this.y + parseInt(this.height) >= parseInt(others.y) && this.y <= parseInt(others.y) + parseInt(others.height)) {
            return true;
        } else {
            return false;
        }
    }

    move_forward = () => {
        // console.log(this.degree);
        var target_x = parseInt(this.x) + 50 * Math.cos((this.degree * Math.PI) / 180);
        var target_y = parseInt(this.y) + 50 * Math.sin((this.degree * Math.PI) / 180);
        target_x = Number(Math.round(target_x + "e2") + "e-2");
        target_y = Number(Math.round(target_y + "e2") + "e-2");
        if (isNaN(target_x)) {
            target_x = 0;
        };
        if (isNaN(target_y)) {
            target_y = 0;
        }
        this.actions.push({
            func: "move",
            distance: 30,
            x: parseInt(this.x),
            y: parseInt(this.y),
            target_x: target_x,
            target_y: target_y,
            frames_x: (target_x - this.x) / 10,
            frames_y: (target_y - this.y) / 10
        });
        // console.log(this.actions);
        // this.x = target_x;
        // this.y = target_y;
        this.target_x = target_x;
        this.animation_list.move_forward = true;
    }

    move_towards = (others) => {
        this.animation_list.move_towards = others;
        if (others.x > this.x) {
            this.x += 1;
        }

        if (others.x < this.x) {
            this.x -= 1;
        }

        if (others.y > this.y) {
            this.y += 1;
        }

        if (others.y < this.y) {
            this.y -= 1;
        }
    }

    hide = () => {
        // this.animation_list.hide = true;
        this.width = 0;
        this.height = 0;
        this.show_x = this.x;
        this.show_y = this.y;
        this.x = 1000;
        this.y = 1000;
    }

    show = () => {
        // this.animation_list.hide = true;
        this.width = this.width;
        this.height = this.height;
        this.x = this.show_x;
        this.y = this.show_y;
    }

    move_random = (speed) => {
        this.x -= this.dx;
        this.y -= this.dy;
        if (this.x + this.width > 399 || this.x < 0) {
            this.dx = -this.dx;
        }

        if (this.y + this.height > 399 || this.y < 0) {
            this.dy = -this.dy;
        }
        this.animation_list.move_random = true;
    }


}

export default Story;