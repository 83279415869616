import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../helpers/utility';

const initialState = {
    projects: [],
    error: null,
    loading: false,
    deleteLoading: false,
    deleteError: false,
    userProject: [],
    userProjectLoad: false,
    userProjectError: false,
    xml: ''
}

const fetchStart = (state, action) => {
    return updateObject(state, { error: null, loading: true });
}

const fetchSuccess = (state, action) => {
    return updateObject(state, {
        projects: action.projects,
        loading: false,
        error: null
    })
}

const fetchFailed = (state, action) => {
    return updateObject(state, {
        loading: false,
        error: action.error
    });
}

const deleteStart = (state, action) => {
    return updateObject(state, {
        deleteLoading: true,
        deleteError: null
    })
}

const deleteSuccess = (state, action) => {

    return {
        ...state,
        projects: state.projects.filter(item => item._id !== action.id)
    }
}

const deleteFailed = (state, action) => {
    return updateObject(state, {
        deleteLoading: false,
        deleteError: action.error
    })
}

const editProjectSuccess = (state, action) => {
    const projectId = action.project._id;
    const mprojects = state.projects.map(project => project._id === projectId ? { ...project, name: action.project.name } : project);
    return {
        ...state,
        projects: mprojects
    }
}

const addProjectSuccess = (state, action) => {
    return {
        ...state,
        projects: [
            ...state.projects,
            action.project
        ]
    }
}

const userFetchStart = (state, action) => {
    return {
        ...state,
        userProjectLoad: true
    }
}

const userFetchSuccess = (state, action) => {
    return {
        ...state,
        userProject: action.project
    }
}

const userFetchFailed = (state, action) => {
    return {
        ...state,
        userProjectError: action.error
    }
}

const fetchXML = (state, action) => {
    return {
        ...state,
        xml: action.xml
    }
}

const updateStatusSuccess = (state, action) => {
    console.log(state.userProject);
    return {
        ...state,
        userProject: {
            ...state.userProject,
            status: action.status
        }
    }

}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_PROJECTS_START: return fetchStart(state, action);
        case actionTypes.FETCH_PROJECTS_SUCCESS: return fetchSuccess(state, action);
        case actionTypes.FETCH_PROJECTS_FAILED: return fetchFailed(state, action);
        case actionTypes.DELETE_PROJECT_START: return deleteStart(state, action);
        case actionTypes.DELETE_PROJECT_SUCCESS: return deleteSuccess(state, action);
        case actionTypes.DELETE_PROJECT_FAILED: return deleteFailed(state, action);
        case actionTypes.ADD_PROJECT_SUCCESS: return addProjectSuccess(state, action);
        case actionTypes.PROJECT_FETCH_START: return userFetchStart(state, action);
        case actionTypes.PROJECT_FETCH_SUCCESS: return userFetchSuccess(state, action);
        case actionTypes.PROJECT_FETCH_FAILED: return userFetchFailed(state, action);
        case actionTypes.PROJECT_XML_SUCCESS: return fetchXML(state, action);
        case actionTypes.EDIT_PROJECT_SUCCESS: return editProjectSuccess(state, action);
        case actionTypes.UPDATE_STATUS_SUCCESS: return updateStatusSuccess(state, action);
        default: return state;
    }
}

export default reducer;