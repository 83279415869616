/*eslint no-useless-concat: 0*/
/*eslint no-unused-vars: 0*/
import * as Blockly from "blockly/core";
import "blockly/javascript";


Blockly.JavaScript["move_forward"] = function (block) {
  if (block.getRootBlock() !== null) {
    // if (block.getRootBlock().type === 'when_run' || block.getRootBlock().type === 'procedure') {

    return "self.state.maze.move();\n";
    // }
  }

  return "";
};



Blockly.JavaScript["turn_right"] = function (block) {

  if (block.getRootBlock() !== null) {
    // if (block.getRootBlock().type === 'when_run') {
    return "self.state.maze.turn_right();\n";
    // }
  }

  return '';
};

Blockly.JavaScript["turn_left"] = function (block) {
  if (block.getRootBlock() !== null) {
    // if (block.getRootBlock().type === 'when_run') {
    return "self.state.maze.turn_left();\n";
    // }
  }

  return "";
};

Blockly.JavaScript['when_run'] = function (block) {
  var statements_all_maze_statements = Blockly.JavaScript.statementToCode(block, 'all_maze_statements');
  // TODO: Assemble JavaScript into code variable.
  var code = statements_all_maze_statements;
  return code;
};

Blockly.JavaScript['check_question_block'] = function (block) {
  var value_name = Blockly.JavaScript.valueToCode(block, 'NAME', Blockly.JavaScript.ORDER_NONE);
  // TODO: Assemble JavaScript into code variable.
  var code = ' self.state.maze.check_question("' + value_name + '")';
  // TODO: Change ORDER_NONE to the correct strength.
  return [code, Blockly.JavaScript.ORDER_NONE];
};

Blockly.JavaScript['add_to_basket'] = function (block) {
  var value_number = Blockly.JavaScript.valueToCode(block, 'number', Blockly.JavaScript.ORDER_NONE);
  var value_collectibles = Blockly.JavaScript.valueToCode(block, 'collectibles', Blockly.JavaScript.ORDER_NONE);
  // TODO: Assemble JavaScript into code variable.
  var code = 'self.state.maze.add_to_basket(' + value_number + ',"' + value_collectibles + '");\n';
  return code;
};

Blockly.JavaScript['collect'] = function (block) {
  var value_name = Blockly.JavaScript.valueToCode(block, 'NAME', Blockly.JavaScript.ORDER_NONE);
  // TODO: Assemble JavaScript into code variable.
  var code = 'self.state.maze.collect("' + value_name + '"); \n';
  return code;
};

Blockly.JavaScript['deposit'] = function (block) {
  var value_name = Blockly.JavaScript.valueToCode(block, 'NAME', Blockly.JavaScript.ORDER_NONE);
  // TODO: Assemble JavaScript into code variable.
  // var code = 'self.state.maze.deposit("' + value_name + '"); \n';
  var code = 'self.state.maze.xs("' + value_name + '"); \n';
  return code;
};

Blockly.JavaScript['orange'] = function (block) {
  // TODO: Assemble JavaScript into code variable.
  var code = '';
  if (block.getParent()) {
    if (block.getParent().type === 'controls_if') {
      code = 'self.state.maze.check_if_collect("orange")';
    } else {
      code = "orange";
    }
  }
  // TODO: Change ORDER_NONE to the correct strength.
  return [code, Blockly.JavaScript.ORDER_NONE];
};

Blockly.JavaScript['apple'] = function (block) {
  // TODO: Assemble JavaScript into code variable.
  var code = '';
  if (block.getParent()) {
    if (block.getParent().type === 'controls_if') {
      code = 'self.state.maze.check_if_collect("apple")';
    } else {
      code = "apple";
    }
  }

  // TODO: Change ORDER_NONE to the correct strength.
  return [code, Blockly.JavaScript.ORDER_NONE];
};

Blockly.JavaScript['pink_flower'] = function (block) {
  // TODO: Assemble JavaScript into code variable.
  var code = 'pink_flower';
  // TODO: Change ORDER_NONE to the correct strength.
  return [code, Blockly.JavaScript.ORDER_NONE];
};

Blockly.JavaScript['bee'] = function (block) {
  // TODO: Assemble JavaScript into code variable.
  var code = 'bee';
  // TODO: Change ORDER_NONE to the correct strength.
  return [code, Blockly.JavaScript.ORDER_NONE];
};

Blockly.JavaScript['mz_orange_question_input'] = function (block) {
  // TODO: Assemble JavaScript into code variable.
  var code = 'maze_cond_orange_question';
  // TODO: Change ORDER_NONE to the correct strength.
  return [code, Blockly.JavaScript.ORDER_NONE];
};

Blockly.JavaScript['mz_yellow_question_input'] = function (block) {
  // TODO: Assemble JavaScript into code variable.
  var code = 'maze_cond_yellow_question';
  // TODO: Change ORDER_NONE to the correct strength.
  return [code, Blockly.JavaScript.ORDER_NONE];
};


Blockly.JavaScript['rabbit'] = function (block) {
  // TODO: Assemble JavaScript into code variable.
  var code = 'rabbit';
  // TODO: Change ORDER_NONE to the correct strength.
  return [code, Blockly.JavaScript.ORDER_NONE];
};



Blockly.JavaScript['mz_yellow_question_input'] = function (block) {
  // TODO: Assemble JavaScript into code variable.
  var code = 'yellow_question';
  // TODO: Change ORDER_NONE to the correct strength.
  return [code, Blockly.JavaScript.ORDER_NONE];
};

Blockly.JavaScript['mz_orange_question_input'] = function (block) {
  // TODO: Assemble JavaScript into code variable.
  var code = 'orange_question';
  // TODO: Change ORDER_NONE to the correct strength.
  return [code, Blockly.JavaScript.ORDER_NONE];
};

Blockly.JavaScript['yellow_flower'] = function (block) {
  // TODO: Assemble JavaScript into code variable.
  var code = 'yellow_flower';
  // TODO: Change ORDER_NONE to the correct strength.
  return [code, Blockly.JavaScript.ORDER_NONE];
};

Blockly.JavaScript['carrot'] = function (block) {
  // TODO: Assemble JavaScript into code variable.
  var code = 'carrot';
  // TODO: Change ORDER_NONE to the correct strength.
  return [code, Blockly.JavaScript.ORDER_NONE];
};

Blockly.JavaScript['beetroot'] = function (block) {
  // TODO: Assemble JavaScript into code variable.
  var code = 'beetroot';
  // TODO: Change ORDER_NONE to the correct strength.
  return [code, Blockly.JavaScript.ORDER_NONE];
};

Blockly.JavaScript['honey'] = function (block) {
  // TODO: Assemble JavaScript into code variable.
  var code = 'honey';
  // TODO: Change ORDER_NONE to the correct strength.
  return [code, Blockly.JavaScript.ORDER_NONE];
};
//------------------------------------------------------------------------//
//STORY LAB BLOCKS
//-----------------------------------------------------------------------//
Blockly.JavaScript['create_sprite_lab1'] = function (block) {
  var dropdown_image = block.getFieldValue('image');
  var text_sprite_name = block.getFieldValue('sprite_name');

  var value_x_location = Blockly.JavaScript.valueToCode(block, 'X location', Blockly.JavaScript.ORDER_NONE);
  var value_y_location = Blockly.JavaScript.valueToCode(block, 'Y location', Blockly.JavaScript.ORDER_NONE);
  // TODO: Assemble JavaScript into code variable.
  var code = 'this.createSprite("' + text_sprite_name.replace(/\s/g, "") + '","' + dropdown_image + '","' + parseInt(value_x_location) + '","' + parseInt(value_y_location) + '","' + 100 + '","' + 100 + '","' + 'this.state.level' + '");\n';
  return code;

};

Blockly.JavaScript['create_sprite_lab2'] = function (block) {
  var dropdown_image = block.getFieldValue('image');
  var text_sprite_name = block.getFieldValue('sprite_name');

  var value_x_location = Blockly.JavaScript.valueToCode(block, 'X location', Blockly.JavaScript.ORDER_NONE);
  var value_y_location = Blockly.JavaScript.valueToCode(block, 'Y location', Blockly.JavaScript.ORDER_NONE);
  // TODO: Assemble JavaScript into code variable.
  var code = 'this.createSprite("' + text_sprite_name.replace(/\s/g, "") + '","' + dropdown_image + '","' + parseInt(value_x_location) + '","' + parseInt(value_y_location) + '","' + 100 + '","' + 100 + '","' + 'this.state.level' + '");\n';
  return code;

};

Blockly.JavaScript['create_sprite_lab3'] = function (block) {
  var dropdown_image = block.getFieldValue('image');
  var text_sprite_name = block.getFieldValue('sprite_name');

  var value_x_location = Blockly.JavaScript.valueToCode(block, 'X location', Blockly.JavaScript.ORDER_NONE);
  var value_y_location = Blockly.JavaScript.valueToCode(block, 'Y location', Blockly.JavaScript.ORDER_NONE);
  // TODO: Assemble JavaScript into code variable.
  var code = 'this.createSprite("' + text_sprite_name.replace(/\s/g, "") + '","' + dropdown_image + '","' + parseInt(value_x_location) + '","' + parseInt(value_y_location) + '","' + 100 + '","' + 100 + '","' + 'this.state.level' + '");\n';
  return code;

};

Blockly.JavaScript['create_sprite_lab4'] = function (block) {
  var dropdown_image = block.getFieldValue('image');
  var text_sprite_name = block.getFieldValue('sprite_name');

  var value_x_location = Blockly.JavaScript.valueToCode(block, 'X location', Blockly.JavaScript.ORDER_NONE);
  var value_y_location = Blockly.JavaScript.valueToCode(block, 'Y location', Blockly.JavaScript.ORDER_NONE);
  // TODO: Assemble JavaScript into code variable.
  var code = 'this.createSprite("' + text_sprite_name.replace(/\s/g, "") + '","' + dropdown_image + '","' + parseInt(value_x_location) + '","' + parseInt(value_y_location) + '","' + 100 + '","' + 100 + '","' + 'this.state.level' + '");\n';
  return code;

};




Blockly.JavaScript['change_sprite_lab1'] = function (block) {
  var text_sprite_name = block.getFieldValue('sprite_name');
  var dropdown_image = block.getFieldValue('image');

  var value_x_location = Blockly.JavaScript.valueToCode(block, 'X location', Blockly.JavaScript.ORDER_NONE);
  var value_y_location = Blockly.JavaScript.valueToCode(block, 'Y location', Blockly.JavaScript.ORDER_NONE);

  // TODO: Assemble JavaScript into code variable.
  var code = 'this.changeSprite("' + text_sprite_name.replace(/\s/g, "") + '","' + dropdown_image + '","' + parseInt(value_x_location) + '","' + parseInt(value_y_location) + '");\n';

  return code;

};


Blockly.JavaScript['change_sprite_lab2'] = function (block) {
  var text_sprite_name = block.getFieldValue('sprite_name');
  var dropdown_image = block.getFieldValue('image');

  var value_x_location = Blockly.JavaScript.valueToCode(block, 'X location', Blockly.JavaScript.ORDER_NONE);
  var value_y_location = Blockly.JavaScript.valueToCode(block, 'Y location', Blockly.JavaScript.ORDER_NONE);

  // TODO: Assemble JavaScript into code variable.
  var code = 'this.changeSprite("' + text_sprite_name.replace(/\s/g, "") + '","' + dropdown_image + '","' + parseInt(value_x_location) + '","' + parseInt(value_y_location) + '");\n';

  return code;

};


Blockly.JavaScript['change_sprite_lab3'] = function (block) {
  var text_sprite_name = block.getFieldValue('sprite_name');
  var dropdown_image = block.getFieldValue('image');

  var value_x_location = Blockly.JavaScript.valueToCode(block, 'X location', Blockly.JavaScript.ORDER_NONE);
  var value_y_location = Blockly.JavaScript.valueToCode(block, 'Y location', Blockly.JavaScript.ORDER_NONE);

  // TODO: Assemble JavaScript into code variable.
  var code = 'this.changeSprite("' + text_sprite_name.replace(/\s/g, "") + '","' + dropdown_image + '","' + parseInt(value_x_location) + '","' + parseInt(value_y_location) + '");\n';

  return code;

};



Blockly.JavaScript['change_sprite_lab4'] = function (block) {
  var text_sprite_name = block.getFieldValue('sprite_name');
  var dropdown_image = block.getFieldValue('image');

  var value_x_location = Blockly.JavaScript.valueToCode(block, 'X location', Blockly.JavaScript.ORDER_NONE);
  var value_y_location = Blockly.JavaScript.valueToCode(block, 'Y location', Blockly.JavaScript.ORDER_NONE);

  // TODO: Assemble JavaScript into code variable.
  var code = 'this.changeSprite("' + text_sprite_name.replace(/\s/g, "") + '","' + dropdown_image + '","' + parseInt(value_x_location) + '","' + parseInt(value_y_location) + '");\n';

  return code;

};




Blockly.JavaScript['set_background'] = function (block) {

  var dropdown_set_background = block.getFieldValue('set_background');

  // TODO: Assemble JavaScript into code variable.

  var code = 'this.setState({background:' + '"' + dropdown_set_background + '"}); \n';

  return code;

};






Blockly.JavaScript['move_around'] = function (block) {

  var dropdown_direction_1 = block.getFieldValue('direction 1');

  var dropdown_direction_2 = block.getFieldValue('direction 2');

  var number_speed_input = block.getFieldValue('speed input');

  // TODO: Assemble JavaScript into code variable.

  var code = 'move_up_down();';

  // TODO: Change ORDER_NONE to the correct strength.

  return [code, Blockly.JavaScript.ORDER_NONE];

};



Blockly.JavaScript['set_text_color'] = function (block) {

  var text_text_variable_name = block.getFieldValue('text variable name');

  var value_name = Blockly.JavaScript.valueToCode(block, 'NAME', Blockly.JavaScript.ORDER_ATOMIC);

  // TODO: Assemble JavaScript into code variable.

  var code = '...;\n';

  return code;

};



Blockly.JavaScript['move_randomly_with_speed'] = function (block) {

  var number_name = block.getFieldValue('NAME');

  // TODO: Assemble JavaScript into code variable.
  var code = 'move_random(' + number_name + ');';
  // TODO: Change ORDER_NONE to the correct strength.

  return [code, Blockly.JavaScript.ORDER_NONE];

};



Blockly.JavaScript['grow_sprite'] = function (block) {

  var value_name = Blockly.JavaScript.valueToCode(block, 'NAME', Blockly.JavaScript.ORDER_NONE);

  // TODO: Assemble JavaScript into code variable.

  var code = 'growing(' + Number(value_name) + ');';

  // TODO: Change ORDER_NONE to the correct strength.

  return [code, Blockly.JavaScript.ORDER_NONE];

};


Blockly.JavaScript['if_right_edge'] = function (block) {


  // TODO: Assemble JavaScript into code variable.

  var code = 'if_right_edge();';

  // TODO: Change ORDER_NONE to the correct strength.

  return [code, Blockly.JavaScript.ORDER_NONE];

};

Blockly.JavaScript['if_left_edge'] = function (block) {


  // TODO: Assemble JavaScript into code variable.

  var code = 'if_left_edge();';

  // TODO: Change ORDER_NONE to the correct strength.

  return [code, Blockly.JavaScript.ORDER_NONE];

};



Blockly.JavaScript['shrink_sprite'] = function (block) {

  var value_name = Blockly.JavaScript.valueToCode(block, 'NAME', Blockly.JavaScript.ORDER_NONE);

  // TODO: Assemble JavaScript into code variable.

  var code = 'shrinking(' + Number(value_name) + ');';

  // TODO: Change ORDER_NONE to the correct strength.

  return [code, Blockly.JavaScript.ORDER_NONE];

};



Blockly.JavaScript['when_run'] = function (block) {

  var statements_all_maze_statements = Blockly.JavaScript.statementToCode(block, 'all_maze_statements');

  // TODO: Assemble JavaScript into code variable.

  var code = statements_all_maze_statements;

  return code;

};



Blockly.JavaScript['when_button_pressed'] = function (block) {

  var dropdown_button_option = block.getFieldValue('button_direction');
  var statements_name = Blockly.JavaScript.statementToCode(block, 'NAME');
  var params = statements_name.split("!");
  var direction = params[1];
  var gap_jump = params[2];
  var speed = params[3];
  var sprite_name = params[0].split(".")[4];

  // TODO: Assemble JavaScript into code variable.
  var code = 'this.onKeyPressed(' + '"' + dropdown_button_option + '"' + ',' + '"' + direction + '"' + ',' + '"' + gap_jump + '"' + ',' + '"' + speed + '"' + ',' + '"' + sprite_name + '"' + ');\n';

  return code;

};



Blockly.JavaScript['sprite_begins'] = function (block) {

  var text_sprite_name = block.getFieldValue('sprite_name');

  var value_begins = Blockly.JavaScript.valueToCode(block, 'begins', Blockly.JavaScript.ORDER_NONE);

  // TODO: Assemble JavaScript into code variable.

  var code = 'if(this.sprite.' + text_sprite_name.replace(/\s/g, "") + '){this.sprite.' + text_sprite_name.replace(/\s/g, "") + '.' + value_begins + ';} \n';

  return code;

};



Blockly.JavaScript['when_sprite_touches'] = function (block) {

  var text_name = block.getFieldValue('sprite 1');

  var text_other = block.getFieldValue('sprite 2');

  var statements_name = Blockly.JavaScript.statementToCode(block, 'NAME');

  var random = Math.floor((Math.random() * 100) + 1);

  // TODO: Assemble JavaScript into code variable.

  var code = 'const ' + text_name + text_other + random + '=()=>{requestAnimationFrame(' + text_name + text_other + random + ');if(typeof this.sprite.' + text_name + ' !== "undefined" && typeof this.sprite.' + text_other + ' !== "undefined"){if(this.sprite.' + text_name.replace(/\s/g, "") + '.isTouching(this.sprite.' + text_other.replace(/\s/g, "") + ')){' + statements_name + '}}};' + text_name + text_other + random + '();\n';
  code += 'this.clearRequestList(' + text_name + text_other + random + ');\n';
  return code;

};



Blockly.JavaScript['when_sprite_touches_surface'] = function (block) {

  var text_sprite_1 = block.getFieldValue('sprite_name');

  var dropdown_surface_name = block.getFieldValue('surface');

  var statements_name = Blockly.JavaScript.statementToCode(block, 'NAME');
  // TODO: Assemble JavaScript into code variable.
  var code = 'const ' + text_sprite_1 + dropdown_surface_name + '=()=>{requestAnimationFrame(' + text_sprite_1 + dropdown_surface_name + ');if(typeof this.sprite.' + text_sprite_1 + ' !== "undefined"){if(this.sprite.' + text_sprite_1.replace(/\s/g, "") + '.if_on_edge(' + '"' + dropdown_surface_name + '"' + ')){' + statements_name + '}}};' + text_sprite_1 + dropdown_surface_name + '();\n';

  return code;

};



Blockly.JavaScript['set_text'] = function (block) {

  var text_text_variable = block.getFieldValue('text variable');

  var text_text_input = block.getFieldValue('text input');

  var value_x_location = Blockly.JavaScript.valueToCode(block, 'x location', Blockly.JavaScript.ORDER_ATOMIC);

  var value_y_location = Blockly.JavaScript.valueToCode(block, 'y location', Blockly.JavaScript.ORDER_ATOMIC);

  // TODO: Assemble JavaScript into code variable.

  var code = '...;\n';

  return code;

};



Blockly.JavaScript['hide_sprite'] = function (block) {

  var text_sprite_name = block.getFieldValue('sprite_name');

  // TODO: Assemble JavaScript into code variable.

  var code = 'this.sprite.' + text_sprite_name + '.hide();\n';

  return code;

};


Blockly.JavaScript['show_sprite'] = function (block) {

  var text_sprite_name = block.getFieldValue('sprite_name');

  // TODO: Assemble JavaScript into code variable.

  var code = 'this.sprite.' + text_sprite_name + '.show();\n';

  return code;

};



Blockly.JavaScript['numerical_input_value'] = function (block) {

  var number_numerical_input_value = block.getFieldValue('numerical_input_value');

  // TODO: Assemble JavaScript into code variable.

  var code = number_numerical_input_value;

  // TODO: Change ORDER_NONE to the correct strength.

  return [code, Blockly.JavaScript.ORDER_NONE];

};

Blockly.JavaScript['default_x'] = function (block) {

  var number_numerical_input_value = block.getFieldValue('default_x');

  // TODO: Assemble JavaScript into code variable.

  var code = number_numerical_input_value;

  // TODO: Change ORDER_NONE to the correct strength.

  return [code, Blockly.JavaScript.ORDER_NONE];

};

Blockly.JavaScript['default_y'] = function (block) {

  var number_numerical_input_value = block.getFieldValue('default_y');

  // TODO: Assemble JavaScript into code variable.

  var code = number_numerical_input_value;

  // TODO: Change ORDER_NONE to the correct strength.

  return [code, Blockly.JavaScript.ORDER_NONE];

};


Blockly.JavaScript['random_x'] = function (block) {

  var code = Math.floor((Math.random() * 300) + 1);


  // TODO: Change ORDER_NONE to the correct strength.

  return [code, Blockly.JavaScript.ORDER_NONE];

};


Blockly.JavaScript['random_y'] = function (block) {

  // TODO: Assemble JavaScript into code variable.

  var code = Math.floor((Math.random() * 100) + 1);

  // TODO: Change ORDER_NONE to the correct strength.

  return [code, Blockly.JavaScript.ORDER_NONE];

};



Blockly.JavaScript['sprite_stops'] = function (block) {

  var text_sprite_name = block.getFieldValue('sprite name');

  var value_stops_movements = Blockly.JavaScript.valueToCode(block, 'stops movements', Blockly.JavaScript.ORDER_ATOMIC);

  // TODO: Assemble JavaScript into code variable.

  var code = '...;\n';

  return code;

};



Blockly.JavaScript['move_left'] = function (block) {

  var dropdown_direction = block.getFieldValue('direction');

  // var value_left_distance = Blockly.JavaScript.valueToCode(block, 'left distance', Blockly.JavaScript.ORDER_NONE);

  var value_speed = Blockly.JavaScript.valueToCode(block, 'speed', Blockly.JavaScript.ORDER_NONE);

  // TODO: Assemble JavaScript into code variable.
  var code = ""
  // var code = 'this.sprite.' + text_sprite_name.replace(/\s/g, "") + ';sprite.' + text_sprite_name.replace(/\s/g, "") + '.degree=' + dropdown_direction + ';' + 'sprite.' + text_sprite_name.replace(/\s/g, "") + '.move_forward();'
  if (block.getParent() && block.getParent().getParent()) {
    // console.log(block.getParent().getParent().type, "h")
    if (block.getParent().getParent().type === 'when_button_pressed') {
      code = "!" + dropdown_direction + "!" + 0 + "!" + value_speed + "!";
    } else {
      code = undefined;
    }
  }


  // TODO: Change ORDER_NONE to the correct strength.

  return [code, Blockly.JavaScript.ORDER_NONE];

};



Blockly.JavaScript['every_movement'] = function (block) {

  // TODO: Assemble JavaScript into code variable.

  var code = '...';

  // TODO: Change ORDER_NONE to the correct strength.

  return [code, Blockly.JavaScript.ORDER_NONE];

};



Blockly.JavaScript['stops_every_movement'] = function (block) {

  var text_sprite_name = block.getFieldValue('sprite name');

  // TODO: Assemble JavaScript into code variable.

  var code = 'this.tsprite.' + text_sprite_name + '.' + 'stop_all_animations()' + ';\n';


  return code;

};



Blockly.JavaScript['when_clicked'] = function (block) {

  var dropdown_clicked_entity = block.getFieldValue('clicked entity');

  var text_entity_name = block.getFieldValue('entity name');

  var statements_name = Blockly.JavaScript.statementToCode(block, 'NAME');

  // TODO: Assemble JavaScript into code variable.
  var code = "if(self.sprite." + text_entity_name + "){this.state.canvas.addEventListener('click', (e) => { var x = e.clientX-this.state.canvas.offsetLeft, y = e.clientY-this.state.canvas.offsetTop; if (self.sprite." + text_entity_name + ".onClick(x, y)) { " + statements_name + "; }; })};\n";
  return code;

};



Blockly.JavaScript['text_color'] = function (block) {

  var colour_name = block.getFieldValue('NAME');

  // TODO: Assemble JavaScript into code variable.

  var code = '...';

  // TODO: Change ORDER_NONE to the correct strength.

  return [code, Blockly.JavaScript.ORDER_NONE];

};



Blockly.JavaScript['create_text_input'] = function (block) {

  var text_variable = block.getFieldValue('text_variable');

  var speech_bubble = block.getFieldValue('speech_bubble');

  var text_value = block.getFieldValue('text_value');

  var value_x_location = Blockly.JavaScript.valueToCode(block, 'x location', Blockly.JavaScript.ORDER_NONE);

  var value_y_location = Blockly.JavaScript.valueToCode(block, 'y location', Blockly.JavaScript.ORDER_NONE);

  // TODO: Assemble JavaScript into code variable.

  var code = 'this.createSprite("' + text_variable + '","' + speech_bubble + '",' + parseInt(value_x_location) + ',' + parseInt(value_y_location) + ',200,100,"' + text_value + '");\n';
  // var code = "";

  return code;

};



Blockly.JavaScript['set_text_size'] = function (block) {

  var text_text_variable = block.getFieldValue('text variable');

  var value_name = Blockly.JavaScript.valueToCode(block, 'NAME', Blockly.JavaScript.ORDER_ATOMIC);

  // TODO: Assemble JavaScript into code variable.

  var code = '...;\n';

  return code;

};



Blockly.JavaScript['create_button'] = function (block) {

  var text_button_name = block.getFieldValue('button name');

  var number_width = block.getFieldValue('width');

  var number_height = block.getFieldValue('height');

  var value_x_position = Blockly.JavaScript.valueToCode(block, 'X position', Blockly.JavaScript.ORDER_ATOMIC);

  var value_y_position = Blockly.JavaScript.valueToCode(block, 'Y position', Blockly.JavaScript.ORDER_ATOMIC);

  // TODO: Assemble JavaScript into code variable.

  var code = 'this.createSprite("' + text_button_name + '","' + '' + '",' + parseInt(value_x_position) + ',' + parseInt(value_y_position) + ',' + parseInt(number_width) + ',' + parseInt(number_height) + ',"' + "" + '");\n';


  return code;

};



Blockly.JavaScript['set_button_colour'] = function (block) {

  var text_button_name = block.getFieldValue('button name');

  var value_name = Blockly.JavaScript.valueToCode(block, 'NAME', Blockly.JavaScript.ORDER_NONE);

  // TODO: Assemble JavaScript into code variable.

  var code = 'self.sprite.' + text_button_name + '.color=' + '"' + value_name + '";\n';

  return code;

};

Blockly.JavaScript['color_select'] = function (block) {
  var colour_color = block.getFieldValue('color');
  // TODO: Assemble JavaScript into code variable.
  var code = colour_color;
  // TODO: Change ORDER_NONE to the correct strength.
  return [code, Blockly.JavaScript.ORDER_NONE];
};


Blockly.JavaScript['set_button_text'] = function (block) {

  var text_button_name = block.getFieldValue('button name');

  var text_text_input = block.getFieldValue('text input');

  // TODO: Assemble JavaScript into code variable.

  var code = 'self.sprite.' + text_button_name + '.textValue=' + '"' + text_text_input + '";\n';

  return code;

};



Blockly.JavaScript['set_button_text_colour'] = function (block) {

  var text_button_name = block.getFieldValue('button name');

  var value_name = Blockly.JavaScript.valueToCode(block, 'NAME', Blockly.JavaScript.ORDER_NONE);

  // TODO: Assemble JavaScript into code variable.

  var code = 'self.sprite.' + text_button_name + '.textColor=' + '"' + value_name + '";\n';

  return code;

};



Blockly.JavaScript['set_button_text_size'] = function (block) {

  var text_button_name = block.getFieldValue('button name');

  var value_name = Blockly.JavaScript.valueToCode(block, 'NAME', Blockly.JavaScript.ORDER_ATOMIC);

  // TODO: Assemble JavaScript into code variable.

  var code = 'self.sprite.' + text_button_name + '.textSize=' + '"' + value_name + '";\n';

  return code;

};



Blockly.JavaScript['text_value'] = function (block) {

  var text_name = block.getFieldValue('NAME');

  // TODO: Assemble JavaScript into code variable.

  var code = '...';

  // TODO: Change ORDER_NONE to the correct strength.

  return [code, Blockly.JavaScript.ORDER_NONE];

};



Blockly.JavaScript['create_screen'] = function (block) {

  var text_screen_name = block.getFieldValue('screen name');

  // TODO: Assemble JavaScript into code variable.

  var code = '...;\n';

  return code;

};



Blockly.JavaScript['change_screens'] = function (block) {

  var text_screen_name_1 = block.getFieldValue('screen name 1');

  var text_screen_name_2 = block.getFieldValue('screen name 2');

  // TODO: Assemble JavaScript into code variable.

  var code = '...;\n';

  return code;

};

Blockly.JavaScript['move_left_right'] = function (block) {

  var value_name = Blockly.JavaScript.valueToCode(block, 'NAME', Blockly.JavaScript.ORDER_NONE);

  // TODO: Assemble JavaScript into code variable.

  var code = 'move_left_right(' + Number(value_name) + ');';

  // TODO: Change ORDER_NONE to the correct strength.

  return [code, Blockly.JavaScript.ORDER_NONE];

};

Blockly.JavaScript['move_up_down'] = function (block) {

  var value_name = Blockly.JavaScript.valueToCode(block, 'NAME', Blockly.JavaScript.ORDER_NONE);

  // TODO: Assemble JavaScript into code variable.

  var code = 'move_up_down(' + Number(value_name) + ');';

  // TODO: Change ORDER_NONE to the correct strength.

  return [code, Blockly.JavaScript.ORDER_NONE];

};

Blockly.JavaScript['move_down'] = function (block) {

  var value_name = Blockly.JavaScript.valueToCode(block, 'NAME', Blockly.JavaScript.ORDER_NONE);

  // TODO: Assemble JavaScript into code variable.

  var code = 'move_south_edge(' + Number(value_name) + ');';

  // TODO: Change ORDER_NONE to the correct strength.

  return [code, Blockly.JavaScript.ORDER_NONE];

};

Blockly.JavaScript['move_up'] = function (block) {

  var value_name = Blockly.JavaScript.valueToCode(block, 'NAME', Blockly.JavaScript.ORDER_NONE);

  // TODO: Assemble JavaScript into code variable.

  var code = 'move_north_edge(' + Number(value_name) + ');';

  // TODO: Change ORDER_NONE to the correct strength.

  return [code, Blockly.JavaScript.ORDER_NONE];

};



Blockly.JavaScript['sl_create_text'] = function (block) {

  var value_name = Blockly.JavaScript.valueToCode(block, 'NAME', Blockly.JavaScript.ORDER_NONE);

  // TODO: Assemble JavaScript into code variable.

  var code = 'move_north_edge(' + Number(value_name) + ');';

  // TODO: Change ORDER_NONE to the correct strength.

  return [code, Blockly.JavaScript.ORDER_NONE];

};

