import React from 'react';

import ExerciseCard from './ExerciseCard/ExerciseCard';

const ExerciseList = (props) => {

    
    return props.exercises.map(exercise => (

        <ExerciseCard progress = {exercise.progress} key={exercise._id} name={exercise.name} icon={exercise.icon} clicked={() => props.clicked(0, exercise.name, exercise._id)} userExercise={exercise.userExercise} />


    ));

}

export default ExerciseList;