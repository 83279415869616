/* eslint no-eval: 0 */

import React from 'react';
import swal from 'sweetalert';
import _ from 'lodash';
import Cookie from 'js-cookie';

import './MazeContainer.css';
import { Components } from '../../components/Components';
import { Assets } from '../../components/Components';
import BlocklyContainer from '../BlocklyContainer';
import BlocklyJS from 'blockly/javascript';
import RunButton from '../RunButton';
import Maze from './Maze';
import '../../blocks/BlockFunctions';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/index';
import { withRouter } from 'react-router-dom';
import { Title } from '../../components/Title/Title';

class MazeContainer extends React.Component {
  constructor(props) {
    super(props);
    this.totalLevel = Object.keys(
      Components[this.props.match.params.exerciseName.replace(/ /g, '')].default
    );
    this.exerciseTitle = this.props.match.params.exerciseName;
    this.exerciseId = this.props.match.params.exerciseId;
    this.level = this.props.match.params.level;
    this.BlocklyContainer = BlocklyContainer;
    this.mazeCanvas = React.createRef();
  }

  state = {
    level:
      Components[this.props.match.params.exerciseName.replace(/ /g, '')]
        .default[this.props.match.params.level],
    assets:
      Assets[this.props.match.params.exerciseName.replace(/ /g, '')].default,
    reload: false,
    levels: [],
  };

  componentDidMount() {
    const userId = Cookie.get('userId');

    this.props.resetSubmitExercise();
    this.props.fetchLevel(
      userId,
      this.props.match.params.exerciseId,
      this.props.match.params.level
    );
    document.getElementById(
      this.props.match.params.level
    ).style.backgroundColor = '#FDCD4C';
    document.getElementById(this.props.match.params.level).style.border =
      '1px solid #FDCD4C';
    var background = new Image();
    background.src = require('../../../public/images/' +
      this.state.level.background);
    var character_info = this.get_charcter_info(
      this.state.level.matrix,
      this.state.assets
    );
    const canvas = this.mazeCanvas.current;
    const ctx = canvas.getContext('2d');
    var maze = new Maze(
      ctx,
      this.state.level.matrix,
      this.state.assets,
      character_info,
      this.state.level.random,
      this.state.level
    );

    var intervalId = setInterval(this.gameLoop, 10);
    // store intervalId in the state so it can be accessed later:
    this.setState({
      intervalId: intervalId,
      ctx: ctx,
      background: background,
      maze: maze,
    });
  }

  componentDidUpdate(prevProps) {
    if (_.isEqual(prevProps.levels, this.props.levels) === false) {
      document.getElementById(
        this.props.match.params.level
      ).style.backgroundColor = '#FDCD4C';
      document.getElementById(this.props.match.params.level).style.border =
        '1px solid #FDCD4C';

      this.setState({
        levels: this.props.levels,
      });
    }
    this.props.resetSubmitExercise();
  }

  gameLoop = () => {
    this.state.ctx.drawImage(this.state.background, 0, 0, 400, 400);
    this.state.maze.draw();
    this.state.maze.update();
  };

  reset = () => {
    this.state.maze.reset();
  };

  submit = () => {
    var result = this.state.maze.submit();
    const userId = Cookie.get('userId');
    if (result.length > 0) {
      swal({
        title: 'Oops!',
        text: result[0].message,
        icon: 'error',
        button: 'Try Again!',
      });
    } else {
      swal({
        title: 'Good Job!!',
        text: 'Successfully completed this level',
        icon: 'success',
        button: 'Next Level',
      }).then((name) => {
        this.props.submitExercise(
          userId,
          this.props.match.params.exerciseId,
          this.props.match.params.level
        );
      });
    }
  };

  onRunClickHandler = function () {
    var self = this;
    this.state.maze.generate_random_number();

    var code = BlocklyJS.workspaceToCode(
      this.BlocklyContainer.primaryWorkspace
    );

    this.state.maze.reset();
    self.state.maze.run = true;
    try {
      eval(code);
    } catch (e) {
      console.log(e);
    }
  };

  onLevelChanged = (index) => {
    this.props.history.push(
      '/exercise/' +
        this.props.match.params.exerciseName +
        '/' +
        this.props.match.params.exerciseId +
        '/' +
        index
    );
    window.location.reload();
  };

  get_charcter_info = (level, assets) => {
    var character_info = {};

    //where is the character - getting the character x and y
    for (var i = 0; i < level.length; i++) {
      for (var j = 0; j < level[0].length; j++) {
        if (assets[level[i][j]] !== undefined) {
          if (assets[level[i][j]].type === 'character') {
            //which number is the character
            character_info['asset_number'] = level[i][j];
            //x and y position of the character
            character_info['x'] = j * 50;
            character_info['y'] = i * 50;
            character_info['angle'] = assets[level[i][j]].angle;
          }
        }
      }
    }
    return character_info;
  };

  render() {
    if (this.props.exerciseSuccess) {
      if (parseInt(this.level) < this.totalLevel.length) {
        this.props.history.push(
          '/exercise/' +
            this.props.match.params.exerciseName +
            '/' +
            this.props.match.params.exerciseId +
            '/' +
            (parseInt(this.level) + 1).toString()
        );
        window.location.reload();
      } else {
        window.location = '/maze';
      }
    }

    let totalLevel = this.totalLevel.map((index) => {
      if (this.props.levels === '') {
        return (
          <div
            key={index}
            className='levelCircle'
            id={index}
            onClick={() => this.onLevelChanged(index)}
          ></div>
        );
      } else {
        if (this.props.levels[index - 1]) {
          if (this.props.levels[index - 1].status === '1') {
            return (
              <div
                key={index}
                className='levelCircle done'
                id={index}
                onClick={() => this.onLevelChanged(index)}
              ></div>
            );
          }
        }
        return (
          <div
            key={index}
            className='levelCircle'
            id={index}
            onClick={() => this.onLevelChanged(index)}
          ></div>
        );
      }
    });

    return (
      <React.Fragment>
        <Title
          title={
            'ITK - ' +
            this.props.match.params.exerciseName
              .replace(/([A-Z])/g, ' $1')
              .trim() +
            ' Level ' +
            this.props.match.params.level
          }
        />

        <div className='container'>
          <div className='levelDiv'>{totalLevel}</div>
          <h3 style={{ color: '#06A199', marginTop: '-20px' }}>
            {this.props.match.params.exerciseName
              .replace(/([A-Z])/g, ' $1')
              .trim()}{' '}
            {this.state.level.debug === true ? 'Debug' : ''}
          </h3>
          <div className='grid-maze'>
            <div className='preview'>
              <canvas
                ref={this.mazeCanvas}
                width={400}
                height={400}
                style={{ border: '1px solid black' }}
              />
              <br />
              <div className='btnSection'>
                <div>
                  <RunButton
                    runClickHandler={this.onRunClickHandler.bind(this)}
                  />
                  <button onClick={this.reset} className='btn'>
                    Reset
                  </button>
                </div>
                <div>
                  <button
                    onClick={this.submit}
                    className='btn'
                    style={{ backgroundColor: 'red' }}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
            <BlocklyContainer
              levels={this.state.levels}
              initialXml={this.state.level.initialXml}
              blocks={this.state.level.blocks}
              level={this.props.match.params.level}
              exerciseId={this.props.match.params.exerciseId}
            />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    submitExercise: (userId, exerciseId, level) =>
      dispatch(actions.submitExercise(userId, exerciseId, level)),
    fetchLevel: (userId, exerciseId, level) =>
      dispatch(actions.fetchLevel(userId, exerciseId, level)),
    resetSubmitExercise: () => dispatch(actions.resetSubmitExercise()),
  };
};

const mapStateToProps = (state) => {
  return {
    levels: state.userExercise.levels,
    exerciseSuccess: state.userExercise.exerciseSuccess,
    loading: state.userExercise.loading,
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(MazeContainer));
