import React from 'react';
import classes from './ExerciseCard.module.css';

const ExerciseCard = (props) => {
    var progress = (props.progress) ? props.progress : 0;
    return (
        <div className={classes.polaroid} onClick={props.clicked}>
            <img src={require('../../../assets/' + props.icon)} alt="" className={classes.img} />
            <div className={classes.titleDiv}>
                <p>{props.name}</p>
            </div>
            <div className={classes.progressBarContainer} style={{ textAlign: 'center' }}>
                <span>%</span>
                <section className={classes.progressBar}>
                    {progress <= 0 ? <div id={props.exerciseId} style={{ width: progress + '%', paddingLeft: '17px' }} className={classes.progress}></div> :
                        <div id={props.exerciseId} style={{ width: progress + '%' }} className={classes.progress}></div>
                    }
                    <div className={classes.circle}>{parseInt(progress)}</div>
                </section>
            </div>
        </div>
    )
}

export default ExerciseCard;