var assets = {
    1: {
        "background": [['Background', 'unicorn_story_background1.png']],
        "assets": [[{ "src": "/images/StoryLab/fairy_90.png", "width": 35, "height": 35, "alt": "*" }, "fairy_90.png"], [{ "src": "/images/StoryLab/fairy_270.png", "width": 35, "height": 35, "alt": "*" }, "fairy_270.png"], [{ "src": "/images/StoryLab/sadunicorn.png", "width": 35, "height": 35, "alt": "*" }, "sadunicorn.png"], [{ "src": "/images/StoryLab/redstar.png", "width": 35, "height": 35, "alt": "*" }, "redstar.png"], [{ "src": "/images/StoryLab/bluestar.png", "width": 35, "height": 35, "alt": "*" }, "bluestar.png"], [{ "src": "/images/StoryLab/cauldron.png", "width": 35, "height": 35, "alt": "*" }, "cauldron.png"]]
    },
    2: {
        "background": [['Background', 'unicorn_story_background2.png']],
        "assets": [[{ "src": "/images/StoryLab/fairy_90.png", "width": 35, "height": 35, "alt": "*" }, "fairy_90.png"], [{ "src": "/images/StoryLab/fairy_270.png", "width": 35, "height": 35, "alt": "*" }, "fairy_270.png"], [{ "src": "/images/StoryLab/sphinx.png", "width": 35, "height": 35, "alt": "*" }, "sphinx.png"], [{ "src": "/images/StoryLab/pumpkin_story.png", "width": 35, "height": 35, "alt": "*" }, "pumpkin_story.png"]]
    },
    3: {
        "background": [['Background', 'unicorn_story_background3.png']],
        "assets": [[{ "src": "/images/StoryLab/fairy_90.png", "width": 35, "height": 35, "alt": "*" }, "fairy_90.png"], [{ "src": "/images/StoryLab/fairy_270.png", "width": 35, "height": 35, "alt": "*" }, "fairy_270.png"], [{ "src": "/images/StoryLab/red_mushroom1.png", "width": 35, "height": 35, "alt": "*" }, "red_mushroom1.png"], [{ "src": "/images/StoryLab/red_mushroom2.png", "width": 35, "height": 35, "alt": "*" }, "red_mushroom2.png"], [{ "src": "/images/StoryLab/red_mushroom3.png", "width": 35, "height": 35, "alt": "*" }, "red_mushroom3.png"], [{ "src": "/images/StoryLab/rabbit.png", "width": 35, "height": 35, "alt": "*" }, "rabbit.png"]]
    },
    4: {
        "background": [['Background', 'unicorn_story_background4.png']],
        "assets": [[{ "src": "/images/StoryLab/fairy_90.png", "width": 35, "height": 35, "alt": "*" }, "fairy_90.png"], [{ "src": "/images/StoryLab/fairy_270.png", "width": 35, "height": 35, "alt": "*" }, "fairy_270.png"], [{ "src": "/images/StoryLab/sadunicorn.png", "width": 35, "height": 35, "alt": "*" }, "sadunicorn.png"], [{ "src": "/images/StoryLab/colorunicorn.png", "width": 35, "height": 35, "alt": "*" }, "colorunicorn.png"], [{ "src": "/images/StoryLab/fish.png", "width": 35, "height": 35, "alt": "*" }, "fish.png"]]
    }
}

export default assets;