import React, { Component } from 'react';
import classes from './ForgotPassword.module.css';
import Header from '../../../components/UI/Header/Header';
import * as actions from '../../../store/actions/index';
import { connect } from 'react-redux';

class ForgotPassword extends Component {


    state = {
        email: '',
        visible: false
    };



    handleInputChange = (event) => {
        const { value, name } = event.target;
        this.setState({
            [name]: value
        });
    }

    submitHandler = (event) => {
        event.preventDefault();
        this.props.onChangePassword(this.state.email);
        this.setState({ visible: true });
    }

    render() {
        var render;
        if (this.state.visible) {
            render = <div className={classes.loginBoxSuccess} style={{ textAlign: 'center' }}>
                <h5>Please check your email to reset password</h5>
            </div>
        } else {
            render = <div className={classes.loginBox}>
                <div className={classes.hello}>
                    <h4 className={classes.headingText}>RESET PASSWORD{this.props.loading}</h4>
                    <form onSubmit={this.submitHandler}>
                        <div className={classes.inputDiv}>
                            <p style={{ marginLeft: '50px', marginRight: '50px', fontSize: '12px', fontWeight: 'bold' }}>Enter your email id in the box below
                and access your mail to reset your password.</p>
                            <p className={[classes.spanText, classes.spanSize].join(' ')}>EMAIL : </p>
                            <input className={classes.input} name="email"
                                value={this.state.email}
                                onChange={this.handleInputChange}
                                required />
                        </div>

                        <div className={classes.buttonDiv}>
                            <button className={classes.button} >RESET</button>
                        </div>
                    </form>
                </div>
            </div>
        }
        return (
            < div className={classes.container} >
                <div className={classes.header}>
                    <Header color="black" />
                </div>
                {render}
            </div >
        )
    }
}

const mapStateTpProps = state => {
    return {
        loading: state.auth.loading,
        success: state.auth.success !== null
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onChangePassword: (email) => dispatch(actions.resetPassword(email))
    }
}

export default connect(mapStateTpProps, mapDispatchToProps)(ForgotPassword);