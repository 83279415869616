var levels = {
    1: {
        "blocks": {
            'World': ['set_background'],
            'Sprites': ['create_sprite_lab4', 'change_sprite_lab4', 'hide_sprite', 'show_sprite'],
            'Sprite Movements': ['sprite_begins', 'sprite_stops', 'move_randomly_with_speed', 'move_left_right', 'move_up_down', 'move_down', 'grow_sprite', 'shrink_sprite', 'move_left', 'stops_every_movement'],
            'Events': ['when_run', 'when_clicked', 'when_button_pressed',
                'when_sprite_touches', 'when_sprite_touches_surface'],
            'Text': ['create_text_input', 'set_text', 'set_text_color', 'set_text_size', 'text_value'],
            'Buttons': ['create_button', 'set_button_colour', 'set_button_text', 'set_button_text_colour', 'set_button_text_size'],
            'Math': ['math_number', 'default_x', 'default_y','color_select']

        },
        "initial_background": "schoolstory_top_background.png",
        "initial_code": 'this.state.ctx.beginPath();this.state.ctx.rect(0, 250, 150, 160);this.state.ctx.stroke();this.state.ctx.fillStyle = "#000000";',
        "validations": ["error_sprite_dest", "error_sprite_speed", "error_sprite_events", "error_screen_change"],
        "test_cases": {
            "x": 0,
            "y": 250,
            "sprite": ["schoolboy", "schoolgirl"],
            "dest_x": 300,
            "dest_y": 300,
            "width": 100,
            "height": 100,
            "events": [{ "boygenius": "isTouching" }, { "girlgenius": "isTouching" }, { "boygenius": "moveleftright" }, { "girlgenius": "moveleftright" }, { "background": "changed" }],
            "background": ['schoolstory_top_background.png', 'schoolstory_right_background.png',
                'schoolstory_left_background.png',
                'schoolstory_centre_background.png',
                'schoolstory_bottom_background.png']
        },
    },

}
export default levels;