class Timer {
  constructor(name, interval) {
    this.interval = interval;
    this.name = name;
    this.delay = 0;
    this.started = false;
  }

  start = () => {
    this.started = true;
  };

  pause = () => {
    this.started = false;
  };

  get_interval = () => {
    return this.interval;
  };
}

export default Timer;
